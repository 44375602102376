import Home from './Home'
import { useHistory } from 'react-router-dom'
import { generateLinkForObject } from '../app/links'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useTreeSettings } from '../app'

const HomeSwitch = () => {
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { homeInstanceId, homeInstanceModel } = useTreeSettings()

  useEffect(() => {
    if (homeInstanceId && homeInstanceModel) {
      const url = generateLinkForObject(
        treeSlug,
        homeInstanceModel,
        homeInstanceId
      )
      history.push(url)
    }
  }, [homeInstanceModel, homeInstanceId, history, treeSlug])

  if (homeInstanceId && homeInstanceModel) {
    return null
  } else {
    return (
      <>
        <Home />
      </>
    )
  }
}

export default HomeSwitch
