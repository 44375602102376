import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, styled } from '@mui/material'

import { LoadingIndicator } from 'src/modules/ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import IndividualCard from './IndividualCard'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(-1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

const BaseIndividualsList = ({
  dispatchFetchIndividuals,
  next,
  handleFetchMore,
  individuals,
  results,
  onIndividualActionClick,
}) => {
  return (
    <Box>
      {dispatchFetchIndividuals.status === 'loading' &&
      individuals.page === 0 &&
      results.length === 0 ? (
        <LoadingIndicator />
      ) : results.length === 0 &&
        dispatchFetchIndividuals.status !== 'loading' ? (
        <EmptyFeed type={INSTANCE_TYPE_INDIVIDUAL} />
      ) : (
        <StyledInfiniteScroll
          dataLength={results.length}
          next={handleFetchMore}
          hasMore={next}
        >
          {results.map(individual => (
            <IndividualCard
              individual={individual}
              onIndividualActionClick={onIndividualActionClick}
            />
          ))}
        </StyledInfiniteScroll>
      )}
      {dispatchFetchIndividuals.status === 'loading' &&
        individuals.page > 0 && <LoadingIndicator />}
    </Box>
  )
}

export default BaseIndividualsList
