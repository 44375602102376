import { useSelector } from 'react-redux'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { invert } from 'lodash'
import { BLOG_TREE } from '../common/constants'

export const PUBLIC_ROOT = 'public'
export const PUBLIC_SHARE = 'share'
export const PUBLIC_BLOG = 'blog'

//keys for the tabs
export const NAV_KEY_WELCOME = 'welcome'
export const NAV_KEY_FEED = 'feed'
export const NAV_KEY_TREE = 'tree'
export const NAV_KEY_FACTS = 'facts'
export const NAV_KEY_RESEARCH = 'research'
export const NAV_KEY_DOCUMENT = 'document'
export const NAV_KEY_MAP = 'map'
export const NAV_KEY_ARTEFACT = 'artefact'
export const NAV_KEY_EVENT = 'event'
export const NAV_KEY_LOCATION = 'location'
export const NAV_KEY_PEOPLE = 'people'
export const NAV_KEY_MEDIA = 'media'
export const NAV_KEY_POSTS = 'posts'
export const NAV_KEY_DRAFT_POSTS = 'unpublished'
export const NAV_KEY_BLOG_SETTINGS = 'settings'
export const NAV_KEY_BLOG_ROLL = 'blogroll'

export const INSTANCE_TYPE_WELCOME = 'welcome'
export const INSTANCE_TYPE_BLOG_POST = 'blogpost'
export const INSTANCE_TYPE_ARTICLE = 'article'
export const INSTANCE_TYPE_DOCUMENT = 'document'
export const INSTANCE_TYPE_ARTEFACT = 'artefact'
export const INSTANCE_TYPE_EVENT = 'event'
export const INSTANCE_TYPE_FAMILY = 'family'
export const INSTANCE_TYPE_INDIVIDUAL = 'individual'
export const INSTANCE_TYPE_INFORMATION_REQUEST = 'informationrequest'
export const INSTANCE_TYPE_LOCATION = 'location'
export const INSTANCE_TYPE_MEDIA = 'media'
export const INSTANCE_TYPE_PHOTO_ALBUM = 'album'
export const INSTANCE_TYPE_TREE = 'tree'
export const INSTANCE_TYPE_BLOG = 'blog'
// Special case. Served as a type in feeds, but does not have URLs and cannot be
// linked to:
export const INSTANCE_TYPE_MEDIA_GROUP = 'mediagroup'
// sources do not have URLs and cannot be linked to:
export const INSTANCE_TYPE_SOURCE = 'source'
export const INSTANCE_TYPE_FACT = 'instance_type_fact'
export const INSTANCE_TYPE_MAP = 'map'

export const PATH_SEGMENT_WRITE_ARTICLE = 'write-article'
export const PATH_SEGMENT_WELCOME = 'welcome'
export const PATH_SEGMENT_BLOG_POSTS = 'blogposts'
export const PATH_SEGMENT_ARTICLE = 'articles'
export const PATH_SEGMENT_FACTS = 'facts'
export const PATH_SEGMENT_DOCUMENT = 'documents'
export const PATH_SEGMENT_ARTEFACT = 'artefacts'
export const PATH_SEGMENT_EVENT = 'occasions'
export const PATH_SEGMENT_EVENT_FOR_SUBJECT = 'eventsSubject'
export const PATH_SEGMENT_FAMILY = 'families'
export const PATH_SEGMENT_INDIVIDUAL = 'individuals'
export const PATH_SEGMENT_INFORMATION_REQUEST = 'ask-family-requests'
export const PATH_SEGMENT_LOCATION = 'places'
export const PATH_SEGMENT_PEOPLE = 'people'
export const PATH_SEGMENT_FANS = 'fans'
export const PATH_SEGMENT_MEDIA = 'media'
export const PATH_SEGMENT_PHOTO_ALBUM = 'albums'
export const PATH_SEGMENT_HOME = 'home'
export const PATH_SEGMENT_BLOG = 'blog'
export const PATH_SEGMENT_RESEARCH = 'research'
export const PATH_SEGMENT_INDIVIDUAL_PROFILE = 'individual_profile'
export const PATH_SEGMENT_FAMILY_PROFILE = 'family_profile'
export const PATH_SEGMENT_MAP = 'map'

export const PATH_SEGMENT_FOR_INSTANCE_TYPE = {
  [INSTANCE_TYPE_WELCOME]: PATH_SEGMENT_WELCOME,
  [INSTANCE_TYPE_ARTICLE]: PATH_SEGMENT_ARTICLE,
  [INSTANCE_TYPE_DOCUMENT]: PATH_SEGMENT_DOCUMENT,
  [INSTANCE_TYPE_ARTEFACT]: PATH_SEGMENT_ARTEFACT,
  [INSTANCE_TYPE_EVENT]: PATH_SEGMENT_EVENT,
  [INSTANCE_TYPE_FAMILY]: PATH_SEGMENT_FAMILY,
  [INSTANCE_TYPE_INDIVIDUAL]: PATH_SEGMENT_INDIVIDUAL,
  [INSTANCE_TYPE_INFORMATION_REQUEST]: PATH_SEGMENT_INFORMATION_REQUEST,
  [INSTANCE_TYPE_LOCATION]: PATH_SEGMENT_LOCATION,
  [INSTANCE_TYPE_MEDIA]: PATH_SEGMENT_MEDIA,
  [INSTANCE_TYPE_MEDIA_GROUP]: PATH_SEGMENT_MEDIA,
  [INSTANCE_TYPE_PHOTO_ALBUM]: PATH_SEGMENT_PHOTO_ALBUM,
  [INSTANCE_TYPE_BLOG_POST]: PATH_SEGMENT_BLOG_POSTS,
  [INSTANCE_TYPE_SOURCE]: null,
  [INSTANCE_TYPE_MAP]: PATH_SEGMENT_MAP,
}

export const MEDIA_TAB = 'media'
export const ALBUMS_TAB = 'media/albums'
export const RESEARCH_TAB = 'research'

export const INSTANCE_TYPE_FOR_PATH_SEGMENT = invert(
  PATH_SEGMENT_FOR_INSTANCE_TYPE
)
// Special case: "Research" tabs map to articles
INSTANCE_TYPE_FOR_PATH_SEGMENT[RESEARCH_TAB] = INSTANCE_TYPE_ARTICLE

// The API URLs do not have the same substitutions as public facing URLs
export const API_PATH_SEGMENT_FOR_INSTANCE_TYPE = {
  ...PATH_SEGMENT_FOR_INSTANCE_TYPE,
  [INSTANCE_TYPE_EVENT]: 'events',
  [INSTANCE_TYPE_INFORMATION_REQUEST]: 'information-requests',
  [INSTANCE_TYPE_LOCATION]: 'locations',
  [INSTANCE_TYPE_ARTICLE]: 'content',
  [INSTANCE_TYPE_DOCUMENT]: 'content',
  [INSTANCE_TYPE_PHOTO_ALBUM]: 'content',
  [INSTANCE_TYPE_MAP]: 'content',
}

export const isBlogPost = (url = '') => {
  if (url.includes('/' + PUBLIC_BLOG + '/')) {
    return true
  }
  return false
}

export const isSinglePageUrl = (url = '') => {
  if (url.includes('/' + PUBLIC_SHARE)) {
    return true
  }
  return false
}

export const generateTreeRoute = path => {
  // Defines general form for routes in a particular tree
  return `/:slug/${path}`
}

export const generateTreeOverrideQueryString = (treeSlug, treeType) => {
  // creates a query string that adds params that are used in write article to change the look of the page
  return `?treeOverride=${treeSlug}&treeTypeOverride=${treeType}`
}

export const generateTreeLink = (treeSlug, path, queryString = '') => {
  // Defines the general form for links to a particular tree
  return `/${treeSlug}/${path}${queryString}`
}

export const generateBlogLink = (treeSlug, path) => {
  // Defines the general form for links to a particular public blog
  return `/${PUBLIC_BLOG}/${treeSlug}`
}

export const generateBlogPreviewLink = (
  treeSlug,
  alsoPublishToTreeSlug,
  articleId,
  originalTreeSlug
) => {
  return `/${PUBLIC_BLOG}/${
    alsoPublishToTreeSlug || treeSlug
  }/${PATH_SEGMENT_BLOG_POSTS}/${articleId}/?blogPreview=true&treeOverride=${originalTreeSlug}`
}

export const generatePublicBlogLink = treeSlug => {
  return `${getSiteUrl()}${generateBlogLink(treeSlug)}`
}
export const generateBlogLinkFromPreview = (treeSlug, url) => {
  const regexPattern = /\/blogposts\/(\w+)\//
  const match = url.match(regexPattern)
  const contentId = match[1]

  return `${getSiteUrl()}${generateBlogLink(treeSlug)}/blogposts/${contentId}`
}

export const generateLink = (instanceType, target) => {
  return generateLinkForObject(getTreeSlugFromStore(), instanceType, target)
}

export const generateObjectPathSegment = (instanceType, target) => {
  let pathSegment = PATH_SEGMENT_FOR_INSTANCE_TYPE[instanceType]
  if (pathSegment === undefined) {
    console.error(
      `links.js.generateObjectPathSegment(): Invalid content type: ${instanceType} and ${target}`
    )
    pathSegment = 'invalid_content_type'
  }

  if (pathSegment === null) {
    //no link
    return ''
  }
  return `${pathSegment}/${target}`
}

export const generateLinkForPublicRoot = treeSlug => {
  return `${getSiteUrl()}/${PUBLIC_ROOT}${treeSlug}`
}

export const generateLinkForPublicShareRoot = treeSlug => {
  return `${getSiteUrl()}/${PUBLIC_SHARE}${treeSlug}`
}

export const generateShareLinkForObjectWithSiteUrl = (
  treeSlug,
  instanceType,
  id
) => {
  return `${getSiteUrl()}/${PUBLIC_SHARE}${generateLinkForObject(
    treeSlug,
    instanceType,
    id
  )}`
}

const embedHtml = url => {
  return `<iframe height="800px" width="800px" allowfullscreen src="${url}"></iframe>`
}

export const generateEmbedLinkForPublicRoot = treeSlug => {
  const url = `${getSiteUrl()}/${PUBLIC_ROOT}${treeSlug}?embed=true`
  return embedHtml(url)
}

export const generateEmbedLinkForPublicShareRoot = treeSlug => {
  const url = `${getSiteUrl()}/${PUBLIC_SHARE}${treeSlug}?embed=true`
  return embedHtml(url)
}

export const generatLinkForObjectWithSubPath = (
  treeSlug,
  instanceType,
  target,
  subPath
) => {
  // links to a sub path e.g http://localhost:3000/woodchester/places/z4lxpgr1vxrp/places or http://localhost:3000/woodchester/places/z4lxpgr1vxrp/occasions
  return generateLinkForObject(treeSlug, instanceType, target) + '/' + subPath
}

export const generateLinkForObject = (treeSlug, instanceType, target) => {
  // Defines the links for various types of content in the current tree
  if (treeSlug) {
    return generateTreeLink(
      treeSlug,
      generateObjectPathSegment(instanceType, target)
    )
  } else {
    return `/${generateObjectPathSegment(instanceType, target)}`
  }
}

export const generatePublicLinkForObject = (treeSlug, instanceType, target) => {
  return `/${PUBLIC_ROOT}${generateLinkForObject(
    treeSlug,
    instanceType,
    target
  )}`
}

export const generateBlogLinkForObject = (
  treeSlug,
  instanceType,
  target,
  contentSlug
) => {
  return `/${PUBLIC_BLOG}${generateLinkForObject(
    treeSlug,
    INSTANCE_TYPE_BLOG_POST,
    contentSlug || target
  )}`
}

export const generateBlogHomeLink = treeSlug => {
  if (!treeSlug) {
    return '/blog'
  }
  return generateTreeLink(treeSlug, `${PATH_SEGMENT_BLOG}`)
}

export const generateTreeHomeLink = (treeSlug, treeType) => {
  if (!treeSlug) {
    return '/home/tree'
  }
  let extraPath = '/tree'
  if (treeType === BLOG_TREE) {
    extraPath = '/blog'
  }

  return generateTreeLink(treeSlug, `${PATH_SEGMENT_HOME}${extraPath}`)
}

export const generateHomeLink = (treeSlug, treeType) => {
  if (!treeSlug) {
    return '/home/'
  }

  return generateTreeLink(treeSlug, `${PATH_SEGMENT_HOME}`)
}

export const generatePublicTreeRoot = treeSlug => {
  return `/${PUBLIC_ROOT}/${treeSlug}`
}

export const generatePublicTreeHome = treeSlug => {
  return `${generatePublicTreeRoot(treeSlug)}/${PATH_SEGMENT_HOME}`
}

export const generateLinkToCurrentTree = path => {
  return generateTreeLink(getTreeSlugFromStore(), path)
}

export const useTreeLink = path => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  return generateTreeLink(treeSlug, path)
}

export const useObjectLink = (type, id) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  return generateLinkForObject(treeSlug, type, id)
}

export const generatePublicLinkWithSiteUrl = path => {
  return `${getSiteUrl()}${path}`
}

const getSiteUrl = () => {
  return window.location.origin
}

export const generatePublicLinkForObjectWithSiteUrl = (
  treeSlug,
  instanceType,
  id
) => {
  return `${getSiteUrl()}${generatePublicLinkForObject(
    treeSlug,
    instanceType,
    id
  )}`
}
