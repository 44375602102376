import * as React from 'react'
import { styled } from '@mui/system'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import SiteWideIcon from '../../ui/SiteWideIcon'
import {
  generatLinkForObjectWithSubPath,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_PEOPLE,
  PATH_SEGMENT_RESEARCH,
} from '../links'
import { template } from 'lodash'
import { useHistory } from 'react-router-dom'
import { selectLinkedPageItem } from '../../page/pageSlice'
import { useSelector } from 'react-redux'
import { useLinkedPagePageUrlSegments } from '../hooks'

const openTab = ({
  linkedPageType,
  linkedPageItem,
  treeSlug,
  history,
  pathSegment,
}) => {
  const link =
    generatLinkForObjectWithSubPath(
      treeSlug,
      linkedPageType,
      linkedPageItem.id,
      pathSegment
    ) + '/'

  history.push(link)
}

const linkedPageActionListItems = [
  {
    title: 'Add a Description',
    text: template(
      'Create a description article for the home page of <strong><%= name %></strong>'
    ),
    action: ({ linkedPageItem, pathSegment, treeSlug, history }) => {
      history.push(`/${treeSlug}/${pathSegment}/${linkedPageItem.id}/`)
    },
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_ARTICLE} />,
    tab: '',
  },
  {
    title: 'Add Articles',
    text: template(
      'Easily create rich articles with images and text about <strong><%= name %></strong>'
    ),
    action: params =>
      openTab({ ...params, pathSegment: PATH_SEGMENT_RESEARCH }),
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_ARTICLE} />,
    tab: PATH_SEGMENT_RESEARCH,
  },
  {
    title: 'Add Media',
    text: template(
      'Upload photos and other media that show <strong><%= name %></strong>'
    ),
    action: params => openTab({ ...params, pathSegment: PATH_SEGMENT_MEDIA }),
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_MEDIA} />,
    tab: PATH_SEGMENT_MEDIA,
  },
  {
    title: 'Add More Places',
    text: template(
      'Add more places and sub places to <strong><%= name %></strong>'
    ),
    action: params =>
      openTab({ ...params, pathSegment: PATH_SEGMENT_LOCATION }),
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_LOCATION} />,
    tab: PATH_SEGMENT_LOCATION,
  },
  {
    title: 'Add Occasions',
    text: template(
      'Add occasions that happen in or are relevant to <strong><%= name %></strong>'
    ),
    action: params => openTab({ ...params, pathSegment: PATH_SEGMENT_EVENT }),
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_EVENT} />,
    tab: PATH_SEGMENT_EVENT,
  },
  {
    title: 'Add People',
    text: template(
      'Create individuals that are important or relevant to <strong><%= name %></strong>'
    ),
    action: params => openTab({ ...params, pathSegment: PATH_SEGMENT_PEOPLE }),
    icon: <SiteWideIcon instanceType={INSTANCE_TYPE_INDIVIDUAL} />,
    tab: PATH_SEGMENT_PEOPLE,
  },
]

const ListItemTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  justifyContent: 'flex-start',
})

const ListBox = styled('div')({
  cursor: 'pointer',
  padding: '0.5rem 0.25rem', // Optional: Add padding for better appearance
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Optional: Add a hover effect
  },
})

const renderText = ({ text, name }) => {
  if (typeof text === 'function') {
    return text({ name })
  } else {
    return text || ''
  }
}

const LinkedPageActionList = ({
  linkedPageItem,
  linkedPageType,
  treeSlug,
  history,
  pathSegment,
  currentTab,
}) => {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {linkedPageActionListItems
        .filter(item => item.tab !== currentTab)
        .map((item, index) => (
          <>
            <ListBox
              key={index}
              onClick={() =>
                item.action({
                  pathSegment,
                  linkedPageType,
                  linkedPageItem,
                  treeSlug,
                  history,
                })
              }
            >
              <ListItemTitle>
                <div>{item.icon}</div>
                <Typography variant="subtitle2">{item.title}</Typography>
              </ListItemTitle>
              <Typography
                sx={{ display: 'inline', verticalAlign: 'middle' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: renderText({
                      text: item.text,
                      name: linkedPageItem.title,
                    }),
                  }}
                />
              </Typography>
            </ListBox>
            <Divider
              component="li"
              sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}
            />
          </>
        ))}
    </List>
  )
}

const LinkedPageHelp = () => {
  const { slug, pageType, pathSegment, currentTab } =
    useLinkedPagePageUrlSegments()
  const history = useHistory()
  const linkedPageItem = useSelector(selectLinkedPageItem)

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body1">
        Hi I'm <strong>Gene Poole</strong>, your friendly assistant!
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Here are some suggestions on what you can do next :
      </Typography>
      <LinkedPageActionList
        treeSlug={slug}
        history={history}
        linkedPageType={pageType}
        linkedPageItem={linkedPageItem}
        pathSegment={pathSegment}
        currentTab={currentTab}
      />
    </>
  )
}

export default LinkedPageHelp
