import { createContext } from 'react'

/*
 *
 * General Context for the page you are looking at in the logged in app.  Trying to avoid prop drilling thru linked pages etc
 *
 * used to store the pageType, linkedPageItem, Individual etc
 * .
 * */
export const PageContext = createContext()
