import React from 'react'
import HomeMapList from './HomeMapList'

const Maps = () => {
  return (
    <>
      <HomeMapList state="PUBLISHED" />
    </>
  )
}

export default Maps
