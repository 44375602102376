import React from 'react'

import { Box, styled } from '@mui/material'

import BaseContentList from './BaseContentList'

import { ViewConfigSelectorContainer } from './ViewConfigSelectorContainer'

import { CreateAlbumButton } from '../ui/actionButtons'
import Refresh from '../ui/Refresh'
import CreateAlbumDialog from '../photo/CreateAlbum'
import { usePageContentList } from './PageContentList'

const PageAlbumList = ({
  target,
  state,
  type,
  presetTargets,
  onFinishedUploadingPhotos,
}) => {
  const {
    sort,
    hierarchical,
    ancestralOnly,
    content,
    handleFetchMore,
    handleSetViewConfig,
    loading,
    next,
    results,
    dispatchFetchPageContent,
    isDraggable,
    fetchContent,
  } = usePageContentList({ target, state, type })

  const actions = results?.length > 0 && (
    <Actions
      onFinishedUploadingPhotos={onFinishedUploadingPhotos}
      onSetViewConfig={handleSetViewConfig}
      sort={sort}
      hierarchical={hierarchical}
      ancestralOnly={ancestralOnly}
      type={type}
      presetTargets={presetTargets}
      target={target}
      fetchContent={fetchContent}
    />
  )

  return (
    <BaseContentList
      actions={actions}
      {...{
        content,
        dispatchFetchContent: dispatchFetchPageContent,
        handleFetchMore,
        isDraggable,
        loading,
        next,
        results,
        type,
      }}
    />
  )
}

const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

export const Actions = ({
  onSetViewConfig,
  onFinishedUploadingPhotos,
  sort,
  hierarchical,
  ancestralOnly,
  type,
  target,
  fetchContent,
  presetTargets,
}) => {
  return (
    <ActionButtons>
      <div>
        <ViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={onSetViewConfig}
          target={target}
        />
      </div>
      <Box>
        <CreateAlbumDialog
          presetTargets={presetTargets}
          onFinishedUploading={onFinishedUploadingPhotos}
          trigger={props => <CreateAlbumButton {...props} />}
        />
        <Refresh onClick={() => fetchContent()} />
      </Box>
    </ActionButtons>
  )
}

export default PageAlbumList
