import React from 'react'

import { useLinkedPageTargets } from 'src/modules/page/hooks'
import { INSTANCE_TYPE_MAP } from '../../app/links'
import PublicPageContentList from './PublicPageContentList'

const PublicMaps = () => {
  const { target } = useLinkedPageTargets()
  return (
    <PublicPageContentList
      target={target}
      state="PUBLISHED"
      pageType={INSTANCE_TYPE_MAP}
    />
  )
}

export default PublicMaps
