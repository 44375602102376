import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { styled, Box, Divider } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { Link, Typography, IconButton } from 'src/modules/ui'
import ContentTextBlock from './ContentTextBlock'
import {
  INSTANCE_TYPE_MAP,
  generateLinkForObject,
  generatePublicLinkForObject,
} from 'src/modules/app/links'
import { PublicContext } from 'src/modules/public/contexts'

import {
  subtitleStyles,
  titleStyles,
  blockquoteStyles,
  bodyTextStyles,
} from './Article'

import { selectCurrentTree } from '../auth/authSlice'
import { formatDate } from '../../utils'
import { useHistory } from 'react-router-dom'
import { ARTICLE_STATE_PUBLISHED } from '../writeArticle/WriteArticle'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const defaultImage = '/map-block-preview-generic.jpg'

//original height constant was 485 so 363 roughly 75%.
// changed to 291 so 60% of original height of 485
const CARD_HEIGHT = 291

export const CardRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: CARD_HEIGHT,
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  width: '100%',
  '& p, blockquote, ol, ul': bodyTextStyles,
  '& h1, h2, h3, blockquote, span': {
    fontFamily: 'IBM Plex Sans',
  },
  '& h4': titleStyles,
  '& h5': subtitleStyles,
  '& blockquote': blockquoteStyles,
}))

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  width: '25%',
  height: CARD_HEIGHT,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}))

export const ContentContainer = styled('div')({
  justifyContent: 'flex-start',
  display: 'flex',
  flexGrow: 1,
  width: '50%',
})

export const InnerContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const FooterContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
})

export const FooterItemContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
})

export const MapCard = ({
  disableUp,
  disableDown,
  handleMoveContentDown,
  handleMoveContentUp,
  isDraggable = false,
  PinPostDialog,
  content: {
    authorIndividual,
    author,
    comments,
    contentBlocks = [],
    id,
    modified,
    created,
    publishedAt,
    previewThumbnail,
    state,
    title,
    titleDraft,
    type,
    treeSlug,
  },
}) => {
  //console.debug(`MapCard: called with title: ${title}`)
  const publicContext = useContext(PublicContext)
  const currentTree = useSelector(selectCurrentTree)
  const history = useHistory()

  //treeslug is not in the mapblock api response
  if (!treeSlug) {
    if (currentTree) {
      treeSlug = currentTree.slug
    }
    if (publicContext) {
      treeSlug = publicContext.treeSlug
    }
  }

  //console.debug(`MapCard: currentTree:`, currentTree)

  let url
  let linkText = state === 'DRAFT' ? '[Edit]' : '[Read More]'

  if (!type) {
    type = INSTANCE_TYPE_MAP
  } else {
    if (type !== INSTANCE_TYPE_MAP) {
      console.error(`MapCard not expecting type: '${type}'`)
    }
  }

  url = generateLinkForObject(treeSlug, type, id)
  if (publicContext) {
    url = generatePublicLinkForObject(treeSlug, type, id)
  }

  const textContent = contentBlocks.filter(block => block.type === 'TEXT')

  const handleNavigate = () => {
    history.push(url)
  }

  return (
    <>
      <CardRoot onClick={handleNavigate}>
        <StyledImage src={previewThumbnail || defaultImage} />
        <ContentContainer sx={{ ml: 2 }}>
          <InnerContainer>
            <CardContent
              {...{
                disableDown,
                disableUp,
                handleMoveContentDown,
                handleMoveContentUp,
                id,
                isDraggable,
                textContent,
                title,
                titleDraft,
                url,
                linkText,
                PinPostDialog,
                state,
              }}
            />
          </InnerContainer>
        </ContentContainer>
      </CardRoot>
      <Divider sx={{ mb: 4, mt: 4 }} />
    </>
  )
}

export const DateTime = ({ dateTime }) => (
  <Typography variant="subtitle4">
    {'\u2022'}
    &nbsp; Last updated {formatDate(dateTime)}
  </Typography>
)

export const CardContent = ({
  disableDown,
  disableUp,
  handleMoveContentDown,
  handleMoveContentUp,
  id,
  isDraggable,
  textContent,
  title,
  titleDraft,
  url,
  linkText,
  PinPostDialog,
  state,
}) => (
  <div>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        sx={{
          mb: 1,
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          onClick={e => e.preventDefault()}
          to={url}
          component={Link}
          color="primary"
          underline="hover"
          variant="h4"
        >
          {state === ARTICLE_STATE_PUBLISHED ? title : titleDraft ?? title}
        </Typography>
      </Box>
      {PinPostDialog && state === 'PUBLISHED' && (
        <div onClick={e => e.stopPropagation()}>
          <PinPostDialog contentId={id} />
        </div>
      )}
      {isDraggable && (
        <Box sx={{ display: 'flex' }}>
          <IconButton
            disabled={disableDown}
            permissionAction={ACTION_ALL_ACCESS}
            onClick={event => handleMoveContentDown({ id, event })}
            sx={{ mr: 2 }}
            white
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            disabled={disableUp}
            onClick={event => handleMoveContentUp({ id, event })}
            white
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      )}
    </Box>
    <ContentTextBlock textContentBlocks={textContent} parent="ArticleCard" />
    <Typography
      onClick={e => e.preventDefault()}
      component={Link}
      variant="subtitle3"
      underline="hover"
    >
      {linkText}
    </Typography>
  </div>
)

MapCard.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
}

const ArticleCardContainer = MapCard

export default ArticleCardContainer
