import { Chip } from '@mui/material'
import { styled } from '@mui/system'
/* little square boreded chip */
export const OblongChip = styled(Chip)(({ theme }) => ({
  borderRadius: 0,
  fontWeight: 'bold',
  fontSize: '0.675rem', // Small font size
  height: '1rem',
  border: '1px solid',
  borderColor: theme.palette.secondaryGrey.main,
  textTransform: 'uppercase',
}))
