import React, { Ref, useCallback, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TxRectMode } from 'mapbox-gl-draw-rotate-scale-rect-mode'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import {
  TxRectModeDemo,
  txRectModeDemoDrawStyle,
} from '../photo/TxRectModeDemo'
import { LngLat, Map } from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { Feature, Geometry, GeoJsonProperties } from 'geojson'
import { ExtendedMapboxDraw } from './ExtendedMapboxDraw'
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined'
import MapIcon from '@mui/icons-material/Map'
import PlaceIcon from '@mui/icons-material/Place'

import ReactDOMServer from 'react-dom/server'
import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

import {
  EditableMapImageComponent,
  EditableMapImageHookResponse,
} from './EditableMapImage'
import { MapImageHookResponse } from './MapImage'
import {
  TXRECT_MODE_OPTS,
  FourCoordinates,
  featureIsImageBorderRectangle,
} from './MapImageHelpers'
import AddMarkerMode from './AddMarkerMode'

export enum MapboxDrawActions {
  AddImageButtonClicked = 'AddImageButtonClicked',
  DeleteButtonClicked = 'DeleteButtonClicked',
  AddMarkerButtonClicked = 'AddMarkerButtonClicked',
  FeatureAdded = 'FeatureAdded',
}

interface SelectionChangeEvent {
  features: Array<Feature>
}

type HandleShapeSelectedFn = (
  event: SelectionChangeEvent,
  weareMapFeatureIdx: number | null,
  weareMapFeature: WeAreMapFeature | null
) => void

interface UseMapEditResponse {
  setupDraw: CallableFunction
  selectMediaRef: any

  //openSelectMediaDialogFnRef is created in MapEdit and passed back to Map
  // so it can be passed into EditableMapImageComponent so it can be set to
  // the onOpenSelectMediaDialog() function and called from MapEdit's toolbox button
  openSelectMediaDialogFnRef: Ref<any>

  handleFeatureSelected: CallableFunction //
}

interface SetupDrawResponse {
  mapboxDraw: MapboxDraw
}
export interface MapLayerImageInfo {
  id: string
  fileName: string
  width: number
  height: number
  coordinates: FourCoordinates
}

export class WeAreMap {
  id!: string
  title?: string
  mapLinks?: WeAreMapFeature[]
  mapLayerImages?: { [key: string]: MapLayerImageInfo }

  constructor(currentMapData?: any) {
    if (currentMapData) {
      this.id = currentMapData.id
      this.title = currentMapData.title
      this.mapLinks = currentMapData.mapLinks
      if ('mapLayerImages' in currentMapData) {
        this.mapLayerImages = currentMapData.mapLayerImages
      }
    } else {
      this.mapLinks = []
    }
  }

  addMapImage = (mapLayerImageInfo: MapLayerImageInfo) => {
    console.debug(
      `MapEdit.WeAreMap.addMapImage(): called with mapLayerImageInfo`,
      mapLayerImageInfo
    )
    if (!this.mapLayerImages) {
      this.mapLayerImages = {}
    }
    this.mapLayerImages[mapLayerImageInfo.id] = mapLayerImageInfo

    console.debug(
      `MapEdit.WeAreMap.addMapImage(): mapLayerImageInfo added, this:`,
      this
    )
  }
}

export interface WeAreMapFeature {
  id: string
  title: string
  instanceType: string
  icon?: any
  target: {
    id?: string
    freeText?: string
    lati?: number
    long?: number
    feature?: Feature<Geometry, GeoJsonProperties>
    photo: {
      id: string
      fileMedium?: string
      fileThumbnail: string
    }
    gedcomLines?: any
  }
}

interface MapEditComponentProps {
  mapinst: Map
  currentMap: WeAreMap
  setCurrentMap: CallableFunction
  weAreMapFeatures: Array<WeAreMapFeature>
  currentFeature: WeAreMapFeature
  handleShapeSelected: HandleShapeSelectedFn
  mapImageHookResponse: MapImageHookResponse
  handleDrawAction: CallableFunction
  mapTitle: string
  setMapTitle: CallableFunction
  setHideUi: CallableFunction
}
export const MapEditComponent = ({
  mapinst,
  currentMap,
  setCurrentMap,
  weAreMapFeatures,
  currentFeature,
  handleShapeSelected,
  mapImageHookResponse,
  handleDrawAction,
  setHideUi,
}: MapEditComponentProps) => {
  const debug = false
  const mapboxDrawRef = useRef<MapboxDraw>()
  const useMapEditResponseRef = useRef<UseMapEditResponse>()
  const useEditableMapImageResponseRef = useRef<EditableMapImageHookResponse>()
  const [mapboxDrawState, setMapboxDrawState] = useState()
  const setupDrawCalled = useRef<boolean>(false)

  if (debug) {
    console.debug(`MapEditComponent(): rendering, mapinst: ${mapinst}`)
    console.debug(
      `MapEditComponent(): rendering, weAreMapFeatures:`,
      weAreMapFeatures
    )
    console.debug(`MapEditComponent(): calling useMapEdit()...`)
  }
  const useMapEditResponse: UseMapEditResponse = useMapEdit(
    weAreMapFeatures,
    handleShapeSelected, // inline function in JSX in Map
    mapImageHookResponse, // passed around so EditableMapImage can call mapImageHookResponse.addImageToGlobe
    useEditableMapImageResponseRef,
    handleDrawAction
  )
  useMapEditResponseRef.current = useMapEditResponse

  if (debug)
    console.debug(
      `MapEditComponent(): useMapEdit() returned useMapEditResponse:`,
      useMapEditResponse
    )

  if (mapinst) {
    if (!mapboxDrawRef.current && useMapEditResponseRef.current) {
      const callSetupDraw = () => {
        if (!useMapEditResponseRef.current) {
          console.error(
            `MapEditComponent.callSetupDraw(): called when useMapEditResponseRef.current is null`
          )
          return
        }
        if (setupDrawCalled.current) {
          if (debug)
            console.debug(
              `MapEditComponent.callSetupDraw(): setupRun already set, doing nothing`
            )
          return
        }
        setupDrawCalled.current = true
        if (debug)
          console.debug(
            `MapEditComponent.callSetupDraw(): calling setupDraw()...`
          )
        const setupDrawResponse = useMapEditResponseRef.current.setupDraw(
          mapinst,
          currentMap
        )
        if (debug) {
          console.debug(`MapEditComponent.callSetupDraw(): setupDraw() done.`)
          console.debug(
            `MapEditComponent.callSetupDraw(): setupDraw() returned setupDrawResponse:`,
            setupDrawResponse
          )
        }
        mapboxDrawRef.current = setupDrawResponse.mapboxDraw
        setMapboxDrawState(setupDrawResponse.mapboxDraw)
      }

      if (mapinst.isStyleLoaded()) {
        if (debug)
          console.debug(
            `MapEditComponent(): style already loaded, calling callSetupDraw() now...`
          )
        callSetupDraw()
        if (debug)
          console.debug(
            `MapEditComponent(): style was already loaded, callSetupDraw() returned ok.`
          )
        //not awaiting as FCs cannot return a promise
      } else {
        console.debug(
          `MapEditComponent(): style not yet loaded, adding callSetupDraw() as a style.load handler...`
        )
        mapinst.on('style.load', callSetupDraw)
      }
    }
  } else {
    console.debug(`MapEditComponent(): called with null mapinst`)
  }

  if (useMapEditResponse.handleFeatureSelected) {
    console.debug(
      `MapEditComponent(): rendering, calling useMapEditResponse.handleFeatureSelected()`,
      currentFeature
    )
    useMapEditResponse.handleFeatureSelected(currentFeature)
  }

  console.debug(
    `MapEditComponent(): rendering, returning JSX - mapinst: '${mapinst}', mapboxDrawState: '${mapboxDrawState}'`
  )

  //attempt to unload MapboxDraw when edit mode is disabled
  useEffect(() => {
    return () => {
      console.debug(`MapEditComponent(): dismounting...`)
      // refs will be cleared
      if (mapboxDrawRef.current) {
        console.debug(
          `MapEditComponent(): dismounting, removing mapboxDraw controls from map in case map is moving to non-edit mode...`
        )
        mapinst.removeControl(mapboxDrawRef.current)
        // try {
        //   mapinst.removeLayer('mapbox-gl-draw-cold')
        // } catch (err) {
        //   //don't care
        // }
        // try {
        //   mapinst.removeLayer('mapbox-gl-draw-hot')
        // } catch (err) {
        //   //don't care
        // }
      }
    }
  }, [mapinst])

  // uses mapboxDrawState to ensure the below re-renders when mapboxDrawState is updated async
  return (
    <>
      {mapinst && mapboxDrawState && (
        <EditableMapImageComponent
          mapinst={mapinst}
          currentMap={currentMap}
          setCurrentMap={setCurrentMap}
          mapboxDraw={mapboxDrawState}
          mapImageHookResponse={mapImageHookResponse}
          openSelectMediaDialogFnRef={
            useMapEditResponse.openSelectMediaDialogFnRef
          }
          useEditableMapImageResponseRef={useEditableMapImageResponseRef}
          setHideUi={setHideUi}
        />
      )}
    </>
  )
}

const useMapEdit = (
  weAreMapFeatures: WeAreMapFeature[],
  handleShapeSelected: HandleShapeSelectedFn, //callback called when a shape is selected
  mapImageHook: MapImageHookResponse,
  editableMapImageHookResponseRef: any, //Ref<EditableMapImageHookResponse>
  handleDrawAction?: CallableFunction // defined in Map
): UseMapEditResponse => {
  const debug = false
  //console.debug(`useMapEdit(): rendering...`)
  const mapEditPreviousResponseRef = useRef<UseMapEditResponse>()
  const weareMapFeaturesRef = useRef<WeAreMapFeature[]>()

  const drawRef = useRef<MapboxDraw>()
  const selectMediaRef = useRef<any>({}) //the SelectMedia component sets its handleShowModal() fn in this Ref
  const openSelectMediaDialogFnRef = useRef<CallableFunction>()

  // console.debug(
  //   `useMapEdit: useEditableMapImageResponse set`,
  //   useEditableMapImageResponse
  // )
  // console.debug(
  //   `useMapEdit(): rendering, called with weAreMapFeatures:`,
  //   weAreMapFeatures
  // )

  weareMapFeaturesRef.current = weAreMapFeatures

  const findFeatureInWeareMapFeaturesById = useCallback(
    (id: string): [number | null, WeAreMapFeature | null] => {
      if (!weareMapFeaturesRef.current) {
        if (debug)
          console.debug(
            `MapEdit.findFeatureInWeareMapFeaturesById(): weareMapFeaturesRef.current is falsy, returning null`
          )
        return [null, null]
      }

      if (debug)
        console.debug(
          `MapEdit.findFeatureInWeareMapFeaturesById(): weareMapFeaturesRef.current:`,
          weareMapFeaturesRef.current
        )

      const idx = weareMapFeaturesRef.current.findIndex(
        f => f.target?.feature?.id === id
      )

      if (debug)
        console.debug(
          `MapEdit.findFeatureInWeareMapFeaturesById(): weareMapFeaturesRef.current.findIndex() returned idx for id '${id}':`,
          idx
        )

      if (idx < 0) {
        // console.debug(
        //   `MapEdit.findFeatureInWeareMapFeaturesById(): did not find feature with id '${id}' in weareMapFeatures:`,
        //   weAreMapFeatures
        // )
        return [null, null]
      }

      const res = weareMapFeaturesRef.current[idx]

      return [idx, res]
    },
    [debug]
  )

  useEffect(() => {
    // this is how WeareMapFatures deleted from the left list have their MapboxDraw features removed
    if (debug)
      console.debug(
        `useMapEdit.useEffect([weAreMapFeatures]): features list updated to:`,
        weAreMapFeatures
      )
    weareMapFeaturesRef.current = weAreMapFeatures

    if (drawRef.current) {
      drawRef.current.getAll().features.forEach(feature => {
        if (debug)
          console.debug(
            `useMapEdit.useEffect([weAreMapFeatures]):  draw contains feature:`,
            feature
          )
        if (feature.id) {
          if (!featureIsImageBorderRectangle(feature)) {
            const featureIdStr = `${feature.id}`

            const [idx, wamf] = findFeatureInWeareMapFeaturesById(featureIdStr)
            if (!wamf) {
              if (debug)
                console.debug(
                  `useMapEdit.useEffect([weAreMapFeatures]):  feature with id '${featureIdStr}' not in weAreMapFeatures, removing it from MapboxDraw...`,
                  feature
                )
              drawRef.current?.delete(featureIdStr)
            } else {
              if (debug)
                console.debug(
                  `useMapEdit.useEffect([weAreMapFeatures]):  findFeatureInWeareMapFeaturesById(${featureIdStr}) returned wamf at index ${idx}:`,
                  wamf
                )
            }
          }
        }
      })
    }
  }, [weAreMapFeatures, findFeatureInWeareMapFeaturesById, debug])

  if (mapEditPreviousResponseRef.current) {
    return mapEditPreviousResponseRef.current
  }

  const findFeatureInWeareMapFeatures = (
    feature: Feature
  ): [number | null, WeAreMapFeature | null] => {
    if (!weareMapFeaturesRef.current) {
      console.debug(
        `MapEdit.findFeatureInWeareMapFeatures(): weAreMapFeatures is falsy, returning null`
      )
      return [null, null]
    }

    if (feature) {
      if (feature.id) {
        const res = findFeatureInWeareMapFeaturesById(`${feature.id}`)

        if (!res || (res[0] === null && res[1] === null)) {
          console.debug(
            `MapEdit.findFeatureInWeareMapFeatures(): did not find WeAreMapFeature: in weareMapFeatures:`,
            feature,
            weAreMapFeatures
          )
        }
        return res
      } else {
        // console.debug(
        //   `MapEdit.findFeatureInWeareMapFeatures(): called with WeAreMapFeature with no id`,
        //   feature
        // )
      }
    } else {
      // console.debug(
      //   `MapEdit.findFeatureInWeareMapFeatures(): called with null WeAreMapFeature`
      // )
    }
    return [null, null]
  }

  const handleFeatureSelected = (weareMapFeature: WeAreMapFeature) => {
    // this breaks txrect's selection when the user clicks one poly
    // then clicks a different poly
    // if (drawRef.current) {
    //   drawRef.current.changeMode('simple_select', {
    //     featureIds: [weareMapFeature?.id],
    //   })
    // }
  }

  // //passed to editableMapImageHook.addEditableImageToGlobe() and called by Mapbox event
  // const onImageSelected = (selected: boolean, imageId: string) => {
  //   console.debug(
  //     `useMapEdit.onImageSelected(): called with selected '${selected}' and imageId: ${imageId}`
  //   )
  //   selectedImageIdRef.current = selected ? imageId : undefined
  //   setSelectedImageId(selected ? imageId : undefined)
  // }

  // 'draw.create' event callback
  const onNewShapeDrawn = (geoJson: any) => {
    // console.debug(
    //   `useMapEdit.createShapeInfoOnCreate(): called with geoJson`,
    //   geoJson
    // )

    const handleFeature = (feature: Feature) => {
      if (
        feature.id &&
        (typeof feature.id === 'number' || !feature.id.includes('SORTABLE-ID'))
      ) {
        const existingId = `${feature.id}`
        try {
          drawRef.current?.delete(existingId)
        } catch (err) {
          console.error(
            `MapEdit.onNewShapeDrawn().handleFeature(): caught error trying to delete shape feature id '${existingId}' from MapboxDraw: `,
            err
          )
        }
        const newId = `SORTABLE-ID-${existingId}`
        // console.debug(
        //   `handleFeature(): deleted draw feature with id '${existingId}':`,
        //   feature
        // )
        feature.id = newId
        //console.debug(`handleFeature(): adding draw feature:`, feature)
        drawRef.current?.add(feature)
      }

      if (!feature.properties) {
        feature.properties = {}
      }
      if (debug)
        console.debug(
          `useMapEdit.createShapeInfoOnCreate(): updated feature to set properties.shapeInfoId`,
          feature
        )
      //replace the feature in MapboxDraw with the updated one
      //with the id set
      drawRef.current?.add(feature)

      if (handleDrawAction) {
        // console.debug(
        //   `useMapEdit.createShapeInfoOnCreate(): calling handleDrawAction()...`
        // )

        handleDrawAction(MapboxDrawActions.FeatureAdded, feature)
      } else {
        // console.debug(
        //   `useMapEdit.createShapeInfoOnCreate(): handleDrawAction() not set.`
        // )
      }
    }

    if (handleDrawAction) {
      if (geoJson) {
        if (geoJson.features && geoJson.features.length > 0) {
          geoJson.features.forEach((feature: Feature) => {
            handleFeature(feature)
          })
        } else {
          // console.debug(
          //   `useMapEdit.createShapeInfoOnCreate(): did not find any features in geoJson input param`,
          //   geoJson
          // )
        }
      } else {
        // console.debug(
        //   `useMapEdit.createShapeInfoOnCreate(): geojson input param not provided or empty.`
        // )
      }
    } else {
      // console.debug(
      //   `useMapEdit.createShapeInfoOnCreate(): handleDrawAction() not set.`
      // )
    }
  }

  const shapeDeleted = (mapinst: Map, features: Array<Feature>) => {
    // console.debug(`MapEdit.shapeDeleted(): called with features:`, features)
    if (features) {
      features.forEach(feature => {
        if (editableMapImageHookResponseRef.current) {
          editableMapImageHookResponseRef.current.onShapeDeleted(
            mapinst,
            drawRef.current,
            feature
          )
        }
      })
    }
    // console.debug(`useMapEdit(): done.`)
  }

  // called from the 'add image' toolbar button
  const openSelectMediaDialog = () => {
    // console.debug(
    //   `useMapEdit().openSelectMediaDialog(): openSelectMediaDialogFnRef.current:`,
    //   openSelectMediaDialogFnRef.current
    // )
    if (openSelectMediaDialogFnRef.current) {
      openSelectMediaDialogFnRef.current()
    } else {
      console.error(
        `useMapEdit().openSelectMediaDialog(): openSelectMediaDialogFnRef.current not set`
      )
    }
  }

  const setupDraw = (
    mapinst: Map,
    currentMap: WeAreMap
  ): SetupDrawResponse | undefined => {
    // console.debug(`MapEdit.setupDraw(): called`)
    if (!mapinst) {
      return
    }
    if (drawRef.current) {
      // console.debug(
      //   `MapEdit.setupDraw(): drawRef.current already set, doing nothing`
      // )
    }

    // https://blog.logrocket.com/react-children-prop-typescript/
    const ButtonContainer = (props: PropsWithChildren<any>) => {
      return (
        <Box
          style={{
            width: '100%',
            height: '100%',
            verticalAlign: 'middle',
            color: 'black',
            //fontWeight: '900',
            stroke: 'black', //makes the lines thicker
            //strokeWidth: 1,
            //paddingTop: '2px',
            padding: '6px',
            paddingLeft: '6.5px', //the polygon has a tiny sliver of transparency on its right
          }}
        >
          {props.children}
        </Box>
      )
    }
    const getIconHtml = (el: any) => {
      return ReactDOMServer.renderToString(
        <ButtonContainer>{el}</ButtonContainer>
      )
    }
    const getElement = (jsx: any) => {
      return Object.assign(document.createElement('span'), {
        innerHTML: getIconHtml(jsx),
        //innerHTML: `${PentagonOutlinedIcon({})}`,
      })
    }

    const addPolygonIconElement = getElement(
      <PentagonOutlinedIcon style={{ width: '100%', height: '100%' }} />
    )

    const addImageIconElement = getElement(
      <MapIcon style={{ width: '100%', height: '100%' }} />
    )

    const addMarkerIconElement = getElement(
      <PlaceIcon style={{ width: '100%', height: '100%' }} />
    )

    // ExtendedMapboxDraw allows adding toolbox icon buttons
    // https://github.com/mapbox/mapbox-gl-draw/issues/874#issuecomment-2031731131
    const newDraw = new ExtendedMapboxDraw({
      props: {
        modes: {
          ...Object.assign(
            {
              //RotateMode: RotateMode,
              draw_rectangle: DrawRectangle, // mapbox-gl-draw-rectangle-mode
              tx_poly: TxRectMode,
              static: StaticMode,
              add_marker: AddMarkerMode,
            },
            MapboxDraw.modes
          ),
        },
        controls: {
          point: false, // don't like the blue dot that this places
          line_string: true,
          polygon: false, //disable because its button looks like a rectangle. Add it again below with MUI svg.
          trash: true,
          combine_features: false,
          uncombine_features: false,
        },
        userProperties: true, // pass user properties to mapbox-gl-draw internal features
        styles: txRectModeDemoDrawStyle,
      },
      // buttons property used by ExtendedMapboxDraw
      buttons: [
        {
          on: 'click',
          highlightButton: true,
          action: (e: Event) => {
            //this is executed when the user clicks on this toolbar button
            console.debug(
              `MapEdit.setupDraw.add_marker.action(): called with e`,
              e
            )
            // calls AddMarkerMode.onSetup(), passing the object as 'opts'
            drawRef.current?.changeMode(
              'add_marker',
              //this object is passed to mode's onSetup() as 'opts'
              {
                //map: mapinst,
                onToolbarButtonClickEvent: e,
                onMapClick: (location: LngLat) => {
                  //called on map click
                  // We don't want single points, sometimes also known as Markers, as MapboxDraw
                  // features because we're going to represent them using a Mapbox Marker and not
                  // a blue dot.
                  console.debug(
                    `MapEdit.setupDraw.add_marker.onAddMarker(): called with location`,
                    location
                  )

                  if (handleDrawAction) {
                    const feature = {
                      geometry: {
                        type: 'Point',
                        coordinates: location,
                      },
                    }
                    handleDrawAction(MapboxDrawActions.FeatureAdded, feature)
                  }
                },
              }
            )
            return drawRef.current
          },
          classes: ['mapbox-gl-draw_marker'],
          content: addMarkerIconElement,
          toolTip: 'Add marker',
          id: 'marker',
        },
        {
          on: 'click',
          highlightButton: true,
          action: () => drawRef.current?.changeMode('draw_polygon'),
          // content: Object.assign(document.createElement('i'), {
          //   innerHTML: '🇧🇷',
          // })
          //content: <PentagonOutlinedIcon />,
          // content: Object.assign(document.createElement('span'), {
          //   innerHTML: polygonIconHtml,
          //   //innerHTML: `${PentagonOutlinedIcon({})}`,
          // }),
          content: addPolygonIconElement,
          toolTip: 'Draw polygon',
          id: 'polygon',
        },
        {
          on: 'click',
          highlightButton: true,
          action: () => drawRef.current?.changeMode('draw_rectangle'),
          // content: Object.assign(document.createElement('i'), {
          //   innerHTML: '🇧🇷',
          // })
          //content: <PentagonOutlinedIcon />,
          classes: ['mapbox-gl-draw_polygon'], // uses the default 'polygon' icon which is a rectangle
          // content: Object.assign(document.createElement('i'), {
          //   innerHTML: html,
          //   //innerHTML: `${PentagonOutlinedIcon({})}`,
          // }),
          content: document.createElement('span'),
          toolTip: 'Draw rectangle',
          id: 'rectangle',
        },
        {
          on: 'click',
          action: () => {
            //this is executed when the user clicks on this toolbar button
            if (handleDrawAction) {
              handleDrawAction(MapboxDrawActions.AddImageButtonClicked)
            }
            openSelectMediaDialog()
          },
          // content: Object.assign(document.createElement('i'), {
          //   innerHTML: '🇧🇷',
          // })
          //content: <PentagonOutlinedIcon />,
          // content: Object.assign(document.createElement('span'), {
          //   innerHTML: imageIconHtml,
          //   //innerHTML: `${PentagonOutlinedIcon({})}`,
          // })
          content: addImageIconElement,
          id: 'image',
          toolTip: 'Position image on the globe',
        },
      ],
    })

    drawRef.current = newDraw

    // console.debug(`mapinst.getStyle().sources:`, mapinst.getStyle().sources)

    // without calling addControl(), attempting to use draw errors with 'Cannot read properties of undefined'
    mapinst.addControl(newDraw)

    // console.debug(`mapinst.getStyle().sources:`, mapinst.getStyle().sources)

    // console.debug(
    //   `useMapEdit.setupDraw(): adding weareMapFeatures as MapboxDraw features - weAreMapFeaturesRef.current:`,
    //   weareMapFeaturesRef.current
    // )
    if (
      weareMapFeaturesRef.current &&
      Array.isArray(weareMapFeaturesRef.current)
    ) {
      weareMapFeaturesRef.current.forEach(waf => {
        // console.debug(
        //   `useMapEdit.setupDraw(): looking at weareMapFeaturesRef.current item`,
        //   waf
        // )
        const gj = waf.target?.feature
        if (gj && gj.geometry) {
          if (debug)
            console.debug(
              `useMapEdit.setupDraw(): gj.geometry type is: ${typeof gj.geometry}`
            )
          if (debug) {
            console.debug(`useMapEdit.setupDraw(): looking at feature:`, gj)
            console.debug(
              `useMapEdit.setupDraw(): adding weareMapFeature to newDraw:`,
              gj
            )
          }

          newDraw.add(gj)

          if (debug)
            console.debug(
              `useMapEdit.setupDraw(): added weareMapFeature to newDraw:`,
              gj
            )
        }
      })
    }

    const USE_TXRECT = true
    if (USE_TXRECT) {
      const txRectModeDemo = new TxRectModeDemo(
        mapinst,
        newDraw,
        null,
        (state: any) => {
          console.debug(
            `MapEdit.setupDraw.onTxRectModeStopDragging(): dragging stopped, state:`,
            state
          )
        },
        TXRECT_MODE_OPTS
      )

      // call _onMapLoad() after binding itself as 'this'
      // could pass the onLoad event down into this fn, but it's not really used so...
      txRectModeDemo._onMapLoad.bind(txRectModeDemo)(null)
    }

    mapinst.on('draw.create', onNewShapeDrawn)

    mapinst.on('draw.update', updateEvent => {
      const features: Array<Feature> = updateEvent.features

      features.forEach(updatedFeature => {
        const id = updatedFeature.id
        if (id) {
          const [existingWeareMapFeatureIdx, existingWeareMapFeature] =
            findFeatureInWeareMapFeaturesById(`${id}`)
          if (existingWeareMapFeatureIdx !== null && existingWeareMapFeature) {
            if (debug) {
              console.debug(
                `on(draw.update): found existingWeareMapFeature:`,
                existingWeareMapFeature
              )
              console.debug(`on(draw.update): updating with:`, updatedFeature)
            }
            const icon = existingWeareMapFeature.icon
            //existingWeareMapFeature.target.feature = updatedFeature
            existingWeareMapFeature.icon = null
            const clone = structuredClone(existingWeareMapFeature)
            clone.icon = icon
            //const clone = { ...existingWeareMapFeature }
            clone.target.feature = updatedFeature
            if (weareMapFeaturesRef.current) {
              weareMapFeaturesRef.current[existingWeareMapFeatureIdx] = clone
            }
            if (debug)
              console.debug(
                `on(draw.update): updated weareMapFeaturesRef.current:`,
                weareMapFeaturesRef.current
              )
          } else {
            // this could be an image border rect
            // console.error(
            //   `on(draw.update): called with updatedFeature with id not in weareMapFeatures:`,
            //   updatedFeature
            // )
          }
        } else {
          console.error(
            `on(draw.update): called with updatedFeature with no id:`,
            updatedFeature
          )
        }
      })
    })

    mapinst.on('draw.selectionchange', function (e: SelectionChangeEvent) {
      if (debug)
        console.debug(
          `MapEdit.setupDraw.onDrawSelectionChange(): called with e`,
          e
        )
      if (handleShapeSelected) {
        if (debug)
          console.debug(
            `MapEdit.setupDraw.onDrawSelectionChange(): calling handleShapeSelected()...`
          )
        if (e.features && e.features.length === 1) {
          const feature = e.features[0]
          if (debug)
            console.debug(
              `MapEdit.setupDraw.onDrawSelectionChange(): calling findFeatureInWeareMapFeatures()...`,
              feature
            )
          const [weareMapFeatureIdx, weareMapFeature] =
            findFeatureInWeareMapFeatures(feature)
          if (debug)
            console.debug(
              `MapEdit.setupDraw.onDrawSelectionChange(): findFeatureInWeareMapFeatures() returned:`,
              weareMapFeature
            )
          handleShapeSelected(e, weareMapFeatureIdx, weareMapFeature)
        } else {
          handleShapeSelected(e, null, null)
        }
      } else {
        if (debug)
          console.debug(
            `MapEdit.setupDraw.onDrawSelectionChange(): not doing anything as handleShapeSelected() not set.`
          )
      }
    })

    // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#drawdelete
    mapinst.on('draw.delete', (e: any) => {
      shapeDeleted(mapinst, e.features)
    })

    if (debug)
      console.debug(`MapEdit.setupDraw(): done, returning newDraw:`, newDraw)

    return {
      mapboxDraw: newDraw,
    }
  } //end function setupDraw()

  const response = {
    setupDraw: setupDraw,
    selectMediaRef: selectMediaRef,
    openSelectMediaDialogFnRef: openSelectMediaDialogFnRef,
    handleFeatureSelected: handleFeatureSelected,
  }
  mapEditPreviousResponseRef.current = response
  if (debug) console.debug(`useMapEdit: done, returning...`, response)
  return response
} // end useMapEdit hook
