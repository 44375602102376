import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  VISIBILITY_PUBLIC,
  VISIBILITY_TREE,
} from '../visibility/visibilityUtils'

const CardContentWithRadio = ({
  value,
  selectedValue,
  handleChange,
  title,
  children,
}) => (
  <React.Fragment>
    <CardContent>
      <Typography variant="h5" component="div">
        {title}
      </Typography>
      {children}
    </CardContent>
    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
      <FormControlLabel
        value={value}
        control={
          <Radio checked={selectedValue === value} onChange={handleChange} />
        }
        label=""
      />
    </Box>
  </React.Fragment>
)

const TreeVisibilityFormControl = ({ onChange, defaultVisibility }) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultVisibility)

  const handleChange = event => {
    setSelectedValue(event.target.value)
    onChange(event.target.value)
  }

  const cardSx = isSelected => ({
    border: isSelected ? '2px solid #000' : '1px solid #ccc',
    boxShadow: isSelected ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
    flex: 1,
  })

  return (
    <Box
      sx={{ display: 'flex', gap: 2, justifyContent: 'center', minWidth: 275 }}
    >
      <Card variant="outlined" sx={cardSx(selectedValue === 'card2')}>
        <CardContentWithRadio
          title={'Private'}
          value={VISIBILITY_TREE}
          selectedValue={selectedValue}
          handleChange={handleChange}
        >
          <div>
            <Typography component={'span'} variant="body2">
              <ul>
                <li>
                  all content <b>private</b>
                </li>
                <li>
                  selected content can still be made public to share online
                </li>
                <li>all names and data for the living will remain hidden</li>
              </ul>
            </Typography>
          </div>
        </CardContentWithRadio>
      </Card>
      <Card variant="outlined" sx={cardSx(selectedValue === 'card1')}>
        <CardContentWithRadio
          title={'Public'}
          value={VISIBILITY_PUBLIC}
          selectedValue={selectedValue}
          handleChange={handleChange}
        >
          <div>
            <Typography component={'span'} variant="body2">
              <ul>
                <li>
                  all content <b>public</b>
                </li>
                <li>excluding names and data for the living</li>
                <li>allows content to be easily shared and discussed</li>
              </ul>
            </Typography>
          </div>
        </CardContentWithRadio>
      </Card>
    </Box>
  )
}

export default TreeVisibilityFormControl
