import React, { useMemo, useState } from 'react'
import { Box, styled } from '@mui/material'

import { PhotoListNavigatorDialog } from 'src/modules/photo/MediaNavigator'
import { Link, Typography } from 'src/modules/ui'

import ContentCardHeader from './ContentCardHeader'
import ContentPreview from './ContentPreview'
import ContentTextBlock from './ContentTextBlock'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_INFORMATION_REQUEST,
} from 'src/modules/app/links'
import { INSTANCE_TYPE_MEDIA_GROUP } from '../app/links'
import {
  subtitleStyles,
  titleStyles,
  blockquoteStyles,
  bodyTextStyles,
} from './Article'

const CardRoot = styled('div')({
  backgroundColor: 'white',
  overflow: 'hidden',
  '& .hoverLink:hover': {
    textDecoration: 'underline',
  },
  '& p, blockquote, ol, ul': bodyTextStyles,
  '& h1, h2, h3, span': {
    fontFamily: 'IBM Plex Sans',
  },
  '& h4': titleStyles,
  '& h5': subtitleStyles,
  '& blockquote': blockquoteStyles,
})

const ContentCard = ({
  authorIndividual,
  contentBlocks = [],
  id,
  url,
  dragHandleRef,
  links = [],
  modified,
  publishedAt,
  media,
  previewThumbnail,
  previewThumbnailHeight,
  previewThumbnailWidth,
  state,
  type,
  title,
  user,
  handleImgDelete,
  width,
  uploadedBy,
  author,
  disabled = false,
}) => {
  const [clickedMedia, setClickedMedia] = useState(null)

  let contentBlock = contentBlocks.slice(0, 1)
  if (
    [INSTANCE_TYPE_ARTICLE, INSTANCE_TYPE_INFORMATION_REQUEST].includes(type)
  ) {
    contentBlock = contentBlocks
      .filter(b => b.type === 'TEXT' && b.textContent)
      .slice(0, 1)
      .map(block => ({
        ...block,
        layout: 'TEXT_BLOCK',
      }))
  }

  const previewMedia = useMemo(() => {
    // try to use thumbnail, assume square if no dimensions for now
    if (previewThumbnail)
      return {
        url: previewThumbnail,
        height: previewThumbnailHeight || 100,
        width: previewThumbnailWidth || 100,
      }

    // if media passed in (i.e. if media group) use that media
    let contentMedia = media?.length ? media[0] : null
    if (!contentMedia) {
      // if not find first occurence of media in content blocks
      const contentBlock = contentBlocks.find(
        contentBlock => contentBlock.media && contentBlock.media.length
      )
      if (contentBlock) {
        contentMedia = contentBlock.media[0]
      }
    }

    if (contentMedia)
      return {
        url: contentMedia.fileMedium,
        height: contentMedia.mediumImageHeight,
        width: contentMedia.mediumImageWidth,
        type: contentMedia.type,
      }
  }, [
    media,
    contentBlocks,
    previewThumbnail,
    previewThumbnailHeight,
    previewThumbnailWidth,
  ])

  const contentInner = (
    <ContentPreview
      columnWidth={width}
      media={previewMedia}
      minHeight={type === INSTANCE_TYPE_ARTICLE ? 400 : 350}
    >
      <>
        <ContentCardHeader
          {...{
            authorIndividual,
            count: media.length || null,
            title,
            type,
            user,
            authorUser: uploadedBy || author,
          }}
        />
        {contentBlock.map((block, idx) => (
          <Block {...block} key={idx} />
        ))}
        {[INSTANCE_TYPE_ARTICLE, INSTANCE_TYPE_INFORMATION_REQUEST].includes(
          type
        ) && (
          <Typography variant="subtitle3" className="hoverLink">
            {state === 'DRAFT' ? '[Edit]' : '[Read More]'}
          </Typography>
        )}
      </>
    </ContentPreview>
  )

  return (
    <CardRoot>
      {type === INSTANCE_TYPE_MEDIA_GROUP ? (
        <>
          <Box
            onClick={() => setClickedMedia(media[0])}
            sx={{ cursor: 'pointer' }}
          >
            {contentInner}
          </Box>
          {!!clickedMedia && (
            <PhotoListNavigatorDialog
              media={media}
              initialPhoto={clickedMedia}
              onClose={() => setClickedMedia(null)}
              handleDelete={handleImgDelete}
            />
          )}
        </>
      ) : (
        <Link to={url} disabled={disabled} underline="none">
          {contentInner}
        </Link>
      )}
    </CardRoot>
  )
}

const Block = props => {
  const blockMap = {
    TEXT: ContentTextBlock,
  }
  const Block = blockMap[props.type]
  return (
    <>
      {Block ? (
        <Block
          {...props}
          textContentBlocks={[{ ...props }]}
          parent="ContentCard"
        />
      ) : null}
    </>
  )
}

export default ContentCard
