import {
  ACTION_ADD_TO_BLOG,
  ACTION_ALL_ACCESS,
  ACTION_CHANGE_TREE_VISIBILITY,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_EDIT_TREE_INDIVIDUALS,
  ACTION_INVITE_USERS,
  ACTION_LOGIN,
  ACTION_PUBLISH,
  ACTION_MAKE_AI_REQUEST,
  ACTION_MOVE_CONTENT,
} from '../app/appConstants'

export const PERMISSION_DENIED_TYPE_FEATURE = 'permission_denied_type_feature'
export const PERMISSION_DENIED_TYPE_ERROR = 'permission_denied_type_error' // this should not happen

export const MANAGE_USERS_ALL = 'MANAGE_USERS_ALL'
export const TREE_SETTINGS = 'TREE_SETTINGS'
export const VIEW_TREE = 'VIEW_TREE'
export const COMMENT = 'COMMENT'
export const ASK_THE_FAMILY = 'ASK_THE_FAMILY'
export const CREATE_CONTENT = 'CREATE_CONTENT'
export const EDIT_OWN_CONTENT = 'EDIT_OWN_CONTENT'
export const DELETE_OWN_CONTENT = 'DELETE_OWN_CONTENT'
export const MAKE_OWN_PRIVATE_TREE_CONTENT_PUBLIC =
  'MAKE_OWN_PRIVATE_TREE_CONTENT_PUBLIC'
export const CREATE_INDIVIDUALS = 'CREATE_INDIVIDUALS'
export const EDIT_OWN_INDIVIDUALS = 'EDIT_OWN_INDIVIDUALS'
export const DELETE_OWN_INDIVIDUALS = 'DELETE_OWN_INDIVIDUALS'
export const CREATE_FACTS_ABOUT_OWN_INDIVIDUALS =
  'CREATE_FACTS_ABOUT_OWN_INDIVIDUALS'
export const EDIT_OWN_FACTS = 'EDIT_OWN_FACTS'
export const DELETE_OWN_FACTS = 'DELETE_OWN_FACTS'
export const LEGACY_ADMIN = 'LEGACY_ADMIN'
export const MANAGE_USERS_BELOW_SELF = 'MANAGE_USERS_BELOW_SELF'
export const EDIT_ALL_INDIVIDUALS = 'EDIT_ALL_INDIVIDUALS'
export const DELETE_ALL_INDIVIDUALS = 'DELETE_ALL_INDIVIDUALS'
export const CREATE_FACTS = 'CREATE_FACTS'
export const EDIT_ALL_FACTS = 'EDIT_ALL_FACTS'
export const DELETE_ALL_FACTS = 'DELETE_ALL_FACTS'
export const VIEW_CHANGELOG = 'VIEW_CHANGELOG'
export const EDIT_ALL_CONTENT = 'EDIT_ALL_CONTENT'
export const DELETE_ALL_CONTENT = 'DELETE_ALL_CONTENT'
export const MAKE_ALL_PRIVATE_TREE_CONTENT_PUBLIC =
  'MAKE_ALL_PRIVATE_TREE_CONTENT_PUBLIC'
export const EXPORT_TREE = 'EXPORT_TREE'
export const EDIT_ALL_FAMILIES = 'EDIT_ALL_FAMILIES'

const PERMISSION_OK = { hasPermission: true }
const PERMISSION_ERROR = {
  hasPermission: false,
  permissionDeniedReason: 'Undefined or Error getting permission',
  permissionDeniedType: PERMISSION_DENIED_TYPE_ERROR,
}

export class PermissionStatusManager {
  constructor({
    userId,
    userPermissions,
    features,
    quotas,
    isBlogTree,
    instanceTypes,
    user,
  }) {
    this.userId = userId
    this.userPermissions = userPermissions
    this.features = features
    this.isBlogTree = isBlogTree
    this.user = user || {}
    this.quotas = quotas

    this.INSTANCE_TYPE_WELCOME = instanceTypes.INSTANCE_TYPE_WELCOME
    this.INSTANCE_TYPE_BLOG_POST = instanceTypes.INSTANCE_TYPE_BLOG_POST
    this.INSTANCE_TYPE_ARTICLE = instanceTypes.INSTANCE_TYPE_ARTICLE
    this.INSTANCE_TYPE_DOCUMENT = instanceTypes.INSTANCE_TYPE_DOCUMENT
    this.INSTANCE_TYPE_ARTEFACT = instanceTypes.INSTANCE_TYPE_ARTEFACT
    this.INSTANCE_TYPE_EVENT = instanceTypes.INSTANCE_TYPE_EVENT
    this.INSTANCE_TYPE_FAMILY = instanceTypes.INSTANCE_TYPE_FAMILY
    this.INSTANCE_TYPE_INDIVIDUAL = instanceTypes.INSTANCE_TYPE_INDIVIDUAL
    this.INSTANCE_TYPE_INFORMATION_REQUEST =
      instanceTypes.INSTANCE_TYPE_INFORMATION_REQUEST
    this.INSTANCE_TYPE_LOCATION = instanceTypes.INSTANCE_TYPE_LOCATION
    this.INSTANCE_TYPE_MEDIA = instanceTypes.INSTANCE_TYPE_MEDIA
    this.INSTANCE_TYPE_PHOTO_ALBUM = instanceTypes.INSTANCE_TYPE_PHOTO_ALBUM
    this.INSTANCE_TYPE_MAP = instanceTypes.INSTANCE_TYPE_MAP
    this.INSTANCE_TYPE_TREE = instanceTypes.INSTANCE_TYPE_TREE
    this.INSTANCE_TYPE_MEDIA_GROUP = instanceTypes.INSTANCE_TYPE_MEDIA_GROUP
    this.INSTANCE_TYPE_SOURCE = instanceTypes.INSTANCE_TYPE_SOURCE
    this.INSTANCE_TYPE_BLOG = instanceTypes.INSTANCE_TYPE_BLOG
    this.INSTANCE_TYPE_FACT = instanceTypes.INSTANCE_TYPE_FACT
  }

  userHasPermission(requiredPermission) {
    return this.userPermissions.find(
      permission => permission === requiredPermission
    ) === undefined
      ? false
      : true
  }

  checkPermissionStatus(
    permissionAction = 'Missing Action',
    permissionParams = { status: 'Empty Params' }
  ) {
    if (permissionParams.debug) {
      //console.log('DEBUG PERMISSIONS', permissionAction, permissionParams)
    }

    if (permissionAction === ACTION_LOGIN) {
      return PERMISSION_OK
    }

    if (permissionAction === ACTION_ALL_ACCESS) {
      return PERMISSION_OK
    }

    if (!this.userId) {
      return {
        hasPermission: false,
        permissionDeniedReason: 'User Id Missing',
        permissionDeniedType: PERMISSION_ERROR,
      }
    }

    if (this.user) {
      if (this.user.id) {
        if (this.user.id !== this.userId) {
          return {
            hasPermission: false,
            permissionDeniedReason: 'User Id Mismatch',
            permissionDeniedType: PERMISSION_DENIED_TYPE_ERROR,
          }
        }
      }
    }

    let permissionStatus

    switch (permissionAction) {
      case ACTION_MOVE_CONTENT:
        if (this.userHasPermission(EDIT_OWN_CONTENT)) {
          return {
            hasPermission: true,
          }
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit content rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case ACTION_MAKE_AI_REQUEST:
        if (this.userHasPermission(CREATE_CONTENT)) {
          const aiQuota = this.quotas?.find(q => q.key === 'CHATGPT_TOKENS')
          if (!aiQuota) {
            return {
              hasPermission: false,
              permissionDeniedReason: 'You do not have an AI Quota!',
            }
          }

          if (aiQuota?.usage > aiQuota?.limit) {
            return {
              hasPermission: false,
              permissionDeniedReason: `You have exceeded your AI quota! It will refresh on ${aiQuota?.currentPeriodEnd}`,
            }
          } else {
            return {
              hasPermission: true,
            }
          }
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have create content rights',
          }
        }

        /* eslint-disable-next-line no-unreachable */
        break
      case ACTION_INVITE_USERS:
        if (
          !this.isBlogTree &&
          (this.userHasPermission(MANAGE_USERS_ALL) ||
            this.userHasPermission(MANAGE_USERS_BELOW_SELF))
        ) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have manage user rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case ACTION_CHANGE_TREE_VISIBILITY:
        if (this.userHasPermission(MAKE_ALL_PRIVATE_TREE_CONTENT_PUBLIC)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not rights to make public',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break

      case ACTION_EDIT_TREE_INDIVIDUALS:
        if (
          this.userHasPermission(EDIT_ALL_INDIVIDUALS) ||
          this.userHasPermission(EDIT_OWN_INDIVIDUALS)
        ) {
          permissionStatus = PERMISSION_OK
        } else {
          permissionStatus = {
            hasPermission: false,
            permissionDeniedReason:
              'You do not have rights to edit individuals',
          }
        }

        return permissionStatus
        /* eslint-disable-next-line no-unreachable */
        break

      case ACTION_ADD_TO_BLOG:
        if (this.userHasPermission(EDIT_ALL_CONTENT)) {
          permissionStatus = PERMISSION_OK
        } else if (
          this.userHasPermission(EDIT_OWN_CONTENT) &&
          this.isPublicTree(permissionParams)
        ) {
          permissionStatus = PERMISSION_OK
        } else if (
          this.userHasPermission(EDIT_OWN_CONTENT) &&
          this.isAuthor(permissionParams)
        ) {
          permissionStatus = PERMISSION_OK
        } else {
          permissionStatus = {
            hasPermission: false,
            permissionDeniedReason: 'You do not have rights share',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break

      case ACTION_EDIT:
      case ACTION_PUBLISH:
        if (this.isBlogTree) {
          //if we are checking permissions on a blog tree assume its ok
          return PERMISSION_OK
        }

        permissionStatus = this.handleEdits(permissionAction, permissionParams)
        /* eslint-disable-next-line no-unreachable */
        break
      case ACTION_DELETE:
        if (this.isBlogTree) {
          //if we are checking permissions on a blog tree assume its ok
          return PERMISSION_OK
        }

        permissionStatus = this.handleDeletes(
          permissionAction,
          permissionParams
        )
        /* eslint-disable-next-line no-unreachable */
        break
      case ACTION_CREATE:
        if (permissionParams.instanceType === this.INSTANCE_TYPE_BLOG) {
          return PERMISSION_OK
        }

        if (permissionParams.instanceType === this.INSTANCE_TYPE_TREE) {
          if (this.features?.user?.createTree) {
            return PERMISSION_OK
          } else {
            permissionStatus = {
              hasPermission: false,
              permissionDeniedReason: 'Please subscribe to create new archives',
            }
          }
        } else {
          permissionStatus = this.handleCreates(
            permissionAction,
            permissionParams
          )
        }

        /* eslint-disable-next-line no-unreachable */
        break

      default:
        // console.log(
        //   PERMISSION_ERROR.permissionDeniedReason,
        //   permissionAction,
        //   permissionParams
        // )
        return PERMISSION_ERROR
    }

    if (
      this.isBlogTree ||
      permissionParams.instanceType === this.INSTANCE_TYPE_BLOG
    ) {
      //if we are checking permissions on a blog tree assume its ok
      return permissionStatus
    }

    let canEditTree = this.features?.tree?.editTree

    if (!canEditTree) {
      return {
        hasPermission: false,
        permissionDeniedType: PERMISSION_DENIED_TYPE_FEATURE,
        permissionDeniedReason:
          'This archive is in view only mode, please subscribe to activate edit mode. Click to learn more.',
      }
    } else {
      return permissionStatus
    }
  }

  handlePinArticle(permissionAction, permissionParams) {
    switch (permissionParams.instanceType) {
      case this.INSTANCE_TYPE_TREE:
        if (this.userHasPermission(TREE_SETTINGS)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason:
              'You do not have rights to pin articles trees',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_FAMILY:
        if (this.userHasPermission(EDIT_ALL_FAMILIES)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason:
              'You do not have rights to pin articles families',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_INDIVIDUAL:
      case this.INSTANCE_TYPE_ARTEFACT:
      case this.INSTANCE_TYPE_EVENT:
      case this.INSTANCE_TYPE_LOCATION:
        if (this.userHasPermission(EDIT_ALL_CONTENT)) {
          return PERMISSION_OK
        } else if (
          this.isAuthor(permissionParams) &&
          this.userHasPermission(EDIT_OWN_CONTENT)
        ) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break

      default:
        // console.log(
        //   PERMISSION_ERROR.permissionDeniedReason,
        //   permissionAction,
        //   permissionParams
        // )
        return PERMISSION_ERROR
    }
  }

  handleCreates(permissionAction, permissionParams) {
    switch (permissionParams.instanceType) {
      case this.INSTANCE_TYPE_INDIVIDUAL:
        if (this.userHasPermission(CREATE_INDIVIDUALS)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have the access rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_INFORMATION_REQUEST:
        if (this.userHasPermission(ASK_THE_FAMILY)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have the access rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break

      case this.INSTANCE_TYPE_FACT:
      case this.INSTANCE_TYPE_MEDIA:
      case this.INSTANCE_TYPE_PHOTO_ALBUM:
      case this.INSTANCE_TYPE_ARTICLE:
      case this.INSTANCE_TYPE_DOCUMENT:
      case this.INSTANCE_TYPE_ARTEFACT:
      case this.INSTANCE_TYPE_EVENT:
      case this.INSTANCE_TYPE_LOCATION:
      case this.INSTANCE_TYPE_MAP:
        if (this.userHasPermission(CREATE_CONTENT)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have the access rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      default:
        // console.log(
        //   PERMISSION_ERROR.permissionDeniedReason,
        //   permissionParams,
        //   permissionAction
        // )
        return PERMISSION_ERROR
    }
  }

  handleDeletes(permissionAction, permissionParams) {
    switch (permissionParams.instanceType) {
      case this.INSTANCE_TYPE_MEDIA:
      case this.INSTANCE_TYPE_PHOTO_ALBUM:
      case this.INSTANCE_TYPE_ARTICLE:
      case this.INSTANCE_TYPE_DOCUMENT:
      case this.INSTANCE_TYPE_ARTEFACT:
      case this.INSTANCE_TYPE_EVENT:
      case this.INSTANCE_TYPE_LOCATION:
        if (this.userHasPermission(DELETE_ALL_CONTENT)) {
          return PERMISSION_OK
        } else if (
          this.isAuthor(permissionParams) &&
          this.userHasPermission(DELETE_OWN_CONTENT)
        ) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      default:
        // console.log(
        //   PERMISSION_ERROR.permissionDeniedReason,
        //   permissionAction,
        //   permissionParams
        // )
        return PERMISSION_ERROR
    }
  }

  handleEdits(permissionAction, permissionParams) {
    switch (permissionParams.instanceType) {
      case this.INSTANCE_TYPE_BLOG:
        return PERMISSION_OK
        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_TREE:
        if (this.userHasPermission(TREE_SETTINGS)) {
          if (this.features?.user?.createTree) {
            return PERMISSION_OK
          } else {
            return {
              hasPermission: false,
              permissionDeniedType: PERMISSION_DENIED_TYPE_FEATURE,
              permissionDeniedReason: 'cannot create/edit trees',
            }
          }
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have rights to edit trees',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_INDIVIDUAL:
        if (this.userHasPermission(EDIT_ALL_INDIVIDUALS)) {
          return PERMISSION_OK
        } else if (
          this.isAuthor(permissionParams) &&
          this.userHasPermission(EDIT_OWN_INDIVIDUALS)
        ) {
          return PERMISSION_OK
        } else if (
          this.isIsIndividualOnTree(permissionParams) &&
          this.userHasPermission(EDIT_OWN_INDIVIDUALS)
        ) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit rights',
          }
        }

        /* eslint-disable-next-line no-unreachable */
        break
      case this.INSTANCE_TYPE_FAMILY:
        if (this.userHasPermission(EDIT_ALL_FAMILIES)) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit rights',
          }
        }
      case this.INSTANCE_TYPE_MEDIA:
      case this.INSTANCE_TYPE_PHOTO_ALBUM:
      case this.INSTANCE_TYPE_ARTICLE:
      case this.INSTANCE_TYPE_DOCUMENT:
      case this.INSTANCE_TYPE_ARTEFACT:
      case this.INSTANCE_TYPE_EVENT:
      case this.INSTANCE_TYPE_LOCATION:
        if (this.userHasPermission(EDIT_ALL_CONTENT)) {
          return PERMISSION_OK
        } else if (
          this.isAuthor(permissionParams) &&
          this.userHasPermission(EDIT_OWN_CONTENT)
        ) {
          return PERMISSION_OK
        } else {
          return {
            hasPermission: false,
            permissionDeniedReason: 'You do not have edit rights',
          }
        }
        /* eslint-disable-next-line no-unreachable */
        break
      default:
        // console.log(
        //   PERMISSION_ERROR.permissionDeniedReason,
        //   permissionAction,
        //   permissionParams
        // )
        return PERMISSION_ERROR
    }
  }

  isIsIndividualOnTree(permissionParams) {
    try {
      if (
        permissionParams.instance.id &&
        permissionParams.instance.id === this.user.individualOnTree.id
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  isAuthor(permissionParams, allowBlank) {
    const instance = permissionParams.instance
    if (!instance && allowBlank) {
      return true
    }

    if (!instance) {
      //console.log('instance object missing ', permissionParams)
      return false
    }

    const instanceType = permissionParams.instanceType
    if (!instanceType) {
      //console.log('instance type missing ', permissionParams)
      return false
    }

    switch (instanceType) {
      case this.INSTANCE_TYPE_ARTICLE:
      case this.INSTANCE_TYPE_PHOTO_ALBUM:
      case this.INSTANCE_TYPE_DOCUMENT:
      case this.INSTANCE_TYPE_ARTEFACT:
      case this.INSTANCE_TYPE_EVENT:
      case this.INSTANCE_TYPE_LOCATION:
        return (
          instance?.author?.id === this.userId ||
          instance?.authorId === this.userId
        )
      case this.INSTANCE_TYPE_INDIVIDUAL:
        //individuals have author set as just authorId so kep the payload small
        return instance?.authorId === this.userId
      case this.INSTANCE_TYPE_MEDIA:
        return instance?.uploadedBy?.id === this.userId

      default:
        return false
    }
  }

  isPublicTree(permissionParams) {
    return permissionParams?.tree?.allowPublicVisibility === true
  }
}
