import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingIndicator } from 'src/modules/ui'
import { selectUser, setTreeSlug } from '../auth/authSlice'
import { useAppendQueryParams } from './hooks'

// mounted by App.js if path does not match any of the others and does not start with /<slug
// if user has at least one tree will route to that tree
// otherwise will route to onboarding - which will call initialiseUser which will create the demo tree
export const RedirectToDefaultTree = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const appendQueryParams = useAppendQueryParams()

  useEffect(() => {
    if (user) {
      const defaultTree = user.lastViewedTree || user.trees[0]
      if (defaultTree) {
        const slug = defaultTree.slug
        dispatch(setTreeSlug(slug))
        history.push(`/${slug}/home`)
      } else {
        // user has no trees, routing to onboarding
        // onboarding will call initialiseUser which will create the demo tree
        history.push(appendQueryParams('/onboarding'))
      }
    }
  }, [history, user, dispatch, appendQueryParams])

  return <LoadingIndicator />
}
