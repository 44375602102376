import AddMediaToPageDialog from './AddMediaToPageDialog'
import React from 'react'
import { fetchLinkedPhotos } from './pageSlice'
import { useActionDispatcher } from '../app'
import { fetchHomePhotos } from '../home/homeSlice'

const AddMediaControl = ({ target, title }) => {
  const dispatchFetchLinkedPhotos = useActionDispatcher(fetchLinkedPhotos)
  const dispatchFetchHomePhotos = useActionDispatcher(fetchHomePhotos)
  const refreshMedia = () => {
    if (target) {
      dispatchFetchLinkedPhotos({ target, page: 0 })
    } else {
      dispatchFetchHomePhotos({ page: 0 })
    }
  }

  const targets = target ? [target] : []

  return (
    <>
      <AddMediaToPageDialog
        shouldTagImages={true}
        onFinishedUploading={refreshMedia}
        targets={targets}
        pageTitle={title}
      />
    </>
  )
}

export default AddMediaControl
