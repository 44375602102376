import { useRouteMatch } from 'react-router-dom'
import { INSTANCE_TYPE_FOR_PATH_SEGMENT } from '../../app/links'
import { PUBLIC_ROOT } from 'src/modules/app/links'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { calculateRelatedFamilies } from '../../page/hooks'
import { selectPublicIndividualById } from '../tree/treeSlice'

export const usePublicPageUrlSegments = () => {
  const match = useRouteMatch(
    `/${PUBLIC_ROOT}/:slug/:pathSegment/:linkedPageId`
  )

  const { slug, pathSegment, linkedPageId } = match?.params || {}

  const pageType = INSTANCE_TYPE_FOR_PATH_SEGMENT[pathSegment] || ''

  return { slug, pageType, linkedPageId, pathSegment }
}

export const usePublicPageUrlSegmentsWithTab = () => {
  const {
    params: { slug, pathSegment, linkedPageId, tab },
  } = useRouteMatch(`/${PUBLIC_ROOT}/:slug/:pathSegment/:linkedPageId/:tab`)

  const pageType = INSTANCE_TYPE_FOR_PATH_SEGMENT[pathSegment]
  const tabType = INSTANCE_TYPE_FOR_PATH_SEGMENT[tab]

  return {
    slug,
    pageType,
    linkedPageId,
    tabType,
    pathSegment,
  }
}

export const usePublicHomePageUrlSegments = () => {
  const {
    params: { slug, pathSegment },
  } = useRouteMatch(`/${PUBLIC_ROOT}/:slug/home/:pathSegment`)

  const pageType = INSTANCE_TYPE_FOR_PATH_SEGMENT[pathSegment]

  return { slug, pageType, pathSegment }
}

export const usePublicSelectRelatedFamilies = individual => {
  const [families, setFamilies] = useState(undefined)

  const { bioFather, bioMother, family, surname } = individual

  const fatherOfIndividual = useSelector(selectPublicIndividualById(bioFather))
  const motherOfIndividual = useSelector(selectPublicIndividualById(bioMother))

  useEffect(() => {
    if (families === undefined && individual) {
      setFamilies(
        calculateRelatedFamilies(
          individual,
          fatherOfIndividual,
          motherOfIndividual
        )
      )
    }
  }, [
    individual,
    families,
    family,
    fatherOfIndividual,
    motherOfIndividual,
    surname,
  ])

  return families
}
