import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import { Divider, Stack } from '@mui/material'

import TreeVisibilityControl from 'src/modules/visibility/TreeVisibilityControl'
import { Button, Container, Typography } from 'src/modules/ui'
import { createEmptyTree, updateTree } from './treesSlice'
import { EditTreeName } from './EditTreeName'
import { useActionDispatcher } from 'src/modules/app'
import { generateTreeLink } from 'src/modules/app/links'
import { useSetTreeAndNavigate } from 'src/modules/app/hooks'
import { selectDraftTrees, selectRootTrees } from 'src/modules/auth/authSlice'
import EditButton from '../ui/EditButton'
import config from '../../config'
import { selectUser } from '../auth/authSlice'
import ManageTreeMenu from './ManageTreeMenu'
import { BLOG_TREE, DEMO_TREE, USER_TREE } from '../common/constants'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import {
  ACTION_ALL_ACCESS,
  ACTION_CHANGE_TREE_VISIBILITY,
  ACTION_CREATE,
  ACTION_EDIT,
  FOCUS_ONE_PLACE_STUDY,
} from '../app/appConstants'
import { INSTANCE_TYPE_BLOG, INSTANCE_TYPE_TREE } from '../app/links'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import { OblongChip } from '../ui/OblongChip'
import { useTreeSettings } from '../app'
import { useIsAlphaFeatureUser } from '../auth/hooks'

const manageTreesConfig = {
  treeTypes: [DEMO_TREE, USER_TREE],
  title: 'Manage your archives',
  createPrompt: 'Create new archive',
  createOnePlaceStudyPrompt: 'Create new one-place study',
  deletePrompt: 'Delete Archive',
  treeSettingsPrompt: 'Archive Settings',
  succcessMessage: 'archive deleted',
  downloadMediaFromLatestGedcomPrompt: 'Import GEDCOM images',
  typeName: 'tree',
  showDelete: true,
  showCreateOnePlaceStudy: true,
  showCopy: true,
  showChangeHomePerson: true,
  showChangeMyTreeIndividual: true,
  showExport: true,
  showVisibility: true,
  treeType: USER_TREE,
  createPath: '/create-tree',
  showTreeSettings: true,
}

const manageBlogsConfig = {
  treeTypes: [BLOG_TREE],
  title: 'Manage your blogs',
  createPrompt: 'Create new blog',
  deletePrompt: 'Delete Blog',
  treeSettingsPrompt: 'Archive Settings',
  createOnePlaceStudyPrompt: 'Create new one-place study',
  succcessMessage: 'blog deleted',
  showDelete: true,
  showCreateOnePlaceStudy: false,
  typeName: 'blog',
  showCopy: false,
  showVisibility: false,
  showChangeHomePerson: false,
  showChangeMyTreeIndividual: false,
  showExport: false,
  treeType: BLOG_TREE,
  createPath: '/create-blog',
  showTreeSettings: false,
}

const manageConfigLookup = {
  [USER_TREE]: manageTreesConfig,
  [BLOG_TREE]: manageBlogsConfig,
}

const useStyles = makeStyles(theme => ({
  manageTree: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  treeAction: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  exploreTree: {
    width: theme.spacing(14),
    display: 'inline-block',
  },
  treeName: {
    maxWidth: '60%',
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  treeNameStack: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  useAllWidth: {
    flex: '1 1 auto',
    maxWidth: '60%',
    margin: theme.spacing(0, 1),
  },
  shrinkWidth: {
    flex: '0 0 auto',
  },
}))

const ManageTree = ({ tree, draftTree, isCurrentTree, manageConfig }) => {
  const classes = useStyles()
  const setTree = useSetTreeAndNavigate()
  const { getTreeFocusHumanReadable } = useTreeSettings()

  const [isEditing, setIsEditing] = useState(false)

  const history = useHistory()
  const dispatchUpdateTree = useActionDispatcher(updateTree)

  const navigateToRootTree = useCallback(() => {
    setTree(tree.slug, manageConfig.treeType)
  }, [setTree, tree.slug, manageConfig])

  const navigateToDraftTree = useCallback(() => {
    history.push(generateTreeLink(tree.slug, `resolve-tree/${draftTree.slug}`))
  }, [tree, draftTree, history])

  // TEMP DISABLED RGS 4th Jan 2023 as update a bit buggy atm!
  // const navigateToUpdateTree = useCallback(() => {
  //   history.push(generateTreeLink(tree.slug, 'update-tree'))
  // }, [tree, history])

  const handleTreeNameChanged = useCallback(
    async name => {
      if (name !== tree.name) {
        await dispatchUpdateTree(
          { treeSlug: tree.slug, name },
          { successNotification: 'Tree name updated' }
        )
      }
      setIsEditing(false)
    },
    [dispatchUpdateTree, tree]
  )

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [setIsEditing])

  return (
    <div className={classes.manageTree}>
      <div className={classes.treeName}>
        <Stack direction="row">
          {!isEditing && (
            <div className={classes.treeNameStack}>
              <EditButton
                permissionAction={ACTION_EDIT}
                permissionParams={{
                  instance: tree,
                  instanceType:
                    tree.treeType === BLOG_TREE
                      ? INSTANCE_TYPE_BLOG
                      : INSTANCE_TYPE_TREE,
                }}
                white
                onClick={handleEdit}
                fontSize="small"
              />
              <div className={classes.useAllWidth}>
                <Typography
                  variant={'h4'}
                  sx={{
                    display: 'block',
                    mr: 2,
                    width: '100%',
                    overflow: 'hidden',
                    fontWeight: isCurrentTree ? 'bold' : 'normal',
                  }}
                >
                  {tree.name} ({tree.userRoleName})
                </Typography>
              </div>
              {manageConfig.showVisibility && (
                <div className={classes.shrinkWidth}>
                  <TreeVisibilityControl
                    treeSlug={tree.slug}
                    iconSize="medium"
                    visibility={tree.allowPublicVisibility}
                    permissionAction={ACTION_CHANGE_TREE_VISIBILITY}
                    permissionParams={{
                      instance: tree,
                      instanceType: INSTANCE_TYPE_TREE,
                    }}
                  />
                </div>
              )}
              <div>
                <OblongChip label={getTreeFocusHumanReadable(tree)} />
              </div>
            </div>
          )}
          {isEditing && (
            <EditTreeName
              initialName={tree.name}
              onTreeNameChanged={handleTreeNameChanged}
              isExistingTree={true}
            />
          )}
        </Stack>
      </div>

      <Button
        permissionAction={ACTION_ALL_ACCESS}
        className={classes.exploreTree}
        onClick={navigateToRootTree}
      >
        Explore
      </Button>
      {tree.userIsAdmin && (
        <>
          {draftTree && (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              className={classes.treeAction}
              onClick={navigateToDraftTree}
            >
              Resolve updates
            </Button>
          )}
          {/* TEMP DISABLED RGS 4th Jan 2023 as update a bit buggy atm!
          {!draftTree && (*/}
          {/*  <Button*/}
          {/*    className={classes.treeAction}*/}
          {/*    onClick={navigateToUpdateTree}*/}
          {/*  >*/}
          {/*    Update from file*/}
          {/*  </Button>*/}
          {/*)}*/}

          <ManageTreeMenu
            manageConfig={manageConfig}
            treeSlug={tree.slug}
            draftTree={draftTree}
            isCurrentTree={isCurrentTree}
          />
        </>
      )}
    </div>
  )
}

const ManageTrees = ({ treeType = USER_TREE }) => {
  const history = useHistory()
  const dispatchCreateEmptyTree = useActionDispatcher(createEmptyTree)
  const user = useSelector(selectUser)
  const draftTrees = useSelector(selectDraftTrees)
  let rootTrees = useSelector(selectRootTrees)
  let showOnePlaceStudyButton = useIsAlphaFeatureUser().onePlaceStudy

  const manageConfig = manageConfigLookup[treeType]

  rootTrees = rootTrees?.filter(t =>
    manageConfig?.treeTypes?.includes(t.treeType)
  )

  const draftTreeByRootID = new Map()
  for (let draftTree of draftTrees) {
    draftTreeByRootID.set(draftTree.draftOf, draftTree)
  }

  const navigateToCreateOnePlaceStudy = useCallback(() => {
    history.push(`/create-tree?focus=${FOCUS_ONE_PLACE_STUDY}`)
  }, [history])

  const navigateToCreateTree = useCallback(() => {
    const createBlog = async () => {
      try {
        await dispatchCreateEmptyTree({
          visibility: VISIBILITY_PUBLIC,
          treeType: BLOG_TREE,
        })

        sendEvent(ga4Events.BLOG_CREATED)

        history.push(manageConfig.createPath)
      } catch {
        console.error('error trying to create tree')
      }
    }

    if (manageConfig.treeType === BLOG_TREE) {
      createBlog()
    } else {
      history.push(manageConfig.createPath)
    }
  }, [history, manageConfig, dispatchCreateEmptyTree])

  rootTrees.sort((a, b) => {
    const aDate = new Date(a.created)
    const bDate = new Date(b.created)
    return bDate - aDate
  })

  return (
    <Container>
      <Typography sx={{ mt: 2, mb: 4 }} variant="h2">
        {manageConfig?.title}
      </Typography>
      <div>
        {(!rootTrees ||
          rootTrees.filter(t => t.userIsAdmin).length <
            config.maxNumAdminTrees) && (
          <>
            <Button
              onClick={navigateToCreateTree}
              permissionAction={ACTION_CREATE}
              permissionParams={{
                instanceType:
                  manageConfig?.treeType === BLOG_TREE
                    ? INSTANCE_TYPE_BLOG
                    : INSTANCE_TYPE_TREE,
              }}
            >
              {manageConfig?.createPrompt}
            </Button>{' '}
            {manageConfig?.showCreateOnePlaceStudy && showOnePlaceStudyButton && (
              <Button
                onClick={navigateToCreateOnePlaceStudy}
                permissionAction={ACTION_CREATE}
                permissionParams={{
                  instanceType:
                    manageConfig?.treeType === BLOG_TREE
                      ? INSTANCE_TYPE_BLOG
                      : INSTANCE_TYPE_TREE,
                }}
              >
                {manageConfig?.createOnePlaceStudyPrompt}
              </Button>
            )}
          </>
        )}
      </div>

      {rootTrees.map(tree => {
        let isCurrentTree = false
        if (user.currentTree?.id === tree.id) {
          isCurrentTree = true
        }
        return (
          <ManageTree
            manageConfig={manageConfig}
            tree={tree}
            key={tree.id}
            draftTree={draftTreeByRootID.get(tree.id)}
            isCurrentTree={isCurrentTree}
          />
        )
      })}
      <Divider sx={{ mt: 2 }} />
    </Container>
  )
}

export default ManageTrees
