import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import { NavLink } from 'src/modules/ui'

import { generateTreeRoute } from 'src/modules/app/links'

import { generateTreeLink } from '../app/links'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

import HomeFamilies from './HomeFamilies'
import HomeIndividuals from './HomeIndividuals'

const INDIVIDUAL_PATH = 'home/people/all'
const FAMILY_PATH = 'home/people/families'
const PEOPLE_ROOT_PATH = 'home/people'

const PEOPLE_ROUTE = generateTreeRoute(PEOPLE_ROOT_PATH)
const INDIVIDUAL_ROUTE = generateTreeRoute(INDIVIDUAL_PATH)
const FAMILY_ROUTE = generateTreeRoute(FAMILY_PATH)
const INDIVIDUAL_TAB_NUMBER = 0
const FAMILY_TAB_NUMBER = 1

const TabPanel = ({ children, tabIndex, tabNumber }) => {
  if (tabIndex === tabNumber) {
    return <div>{children}</div>
  } else {
    return null
  }
}

const HomePeople = () => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  const matchPeopleRootRoute = useRouteMatch(PEOPLE_ROUTE)
  const matchIndividualRoute = useRouteMatch(INDIVIDUAL_ROUTE)
  const matchFamiliyRoute = useRouteMatch(FAMILY_ROUTE)

  const [tabIndex, setTabIndex] = React.useState(INDIVIDUAL_TAB_NUMBER)

  useEffect(() => {
    if (matchPeopleRootRoute?.isExact) {
      setTabIndex(INDIVIDUAL_TAB_NUMBER)
    } else if (matchIndividualRoute?.isExact) {
      setTabIndex(INDIVIDUAL_TAB_NUMBER)
    } else if (matchFamiliyRoute?.isExact) {
      setTabIndex(FAMILY_TAB_NUMBER)
    }
  }, [
    setTabIndex,
    matchPeopleRootRoute,
    matchIndividualRoute,
    matchFamiliyRoute,
  ])

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink
              isActive={() => tabIndex === INDIVIDUAL_TAB_NUMBER}
              to={generateTreeLink(authorisedTreeSlug, INDIVIDUAL_PATH)}
            >
              Individuals
            </NavLink>
            <NavLink
              isActive={() => tabIndex === FAMILY_TAB_NUMBER}
              to={generateTreeLink(authorisedTreeSlug, FAMILY_PATH)}
            >
              Familes
            </NavLink>
          </Stack>
        </Box>
      </Stack>

      <TabPanel tabIndex={tabIndex} tabNumber={INDIVIDUAL_TAB_NUMBER}>
        <HomeIndividuals />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} tabNumber={FAMILY_TAB_NUMBER}>
        <HomeFamilies />
      </TabPanel>
    </div>
  )
}

export default HomePeople
