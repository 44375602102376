import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_LOCATION } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import {
  fetchLinkedPageLocations,
  selectLinkedPageLocations,
} from './pageSlice'

import { Box } from '@mui/material'
import Map from '../map/Map'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

// used inside an item's places tab
const Locations = () => {
  const debug = false

  const { linkedPageId } = useParams()

  const [currentMap, setCurrentMap] = useState(null)

  const linkedLocations = useSelector(selectLinkedPageLocations)

  useEffect(() => {
    const buildMapConfiguration = locations => {
      if (debug)
        console.debug(
          `buildMapConfiguration(): called with locations:`,
          locations
        )
      if (locations && locations.results && locations.results.length > 0) {
        const mapParams = {
          title: 'Map of places tagged with current location',
        }

        let linkId = 1
        const mapLinks = locations.results.flatMap(location => {
          if (location.address) {
            // MapEdit.WeAreMapFeature
            return {
              id: linkId++, //used for repeating element keys
              instanceType: 'location',
              title: location.title,
              target: {
                id: location.id,
                lati: location.address.lati,
                long: location.address.long,
                photo: location.photo,
              },
            }
          }
          return []
        })

        if (mapLinks && mapLinks.length > 0) {
          mapParams.mapLinks = mapLinks
        }

        if (debug)
          console.debug(
            `buildMapConfiguration(): setting currentMap to:`,
            mapParams
          )

        setCurrentMap(mapParams)
      } else {
        if (debug)
          console.debug(
            `buildMapConfiguration(): no locations - setting currentMap to null`
          )
        setCurrentMap(null)
      }
    }

    buildMapConfiguration(linkedLocations)
  }, [linkedLocations, debug])

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  return (
    <>
      <LinkedPageList
        action={fetchLinkedPageLocations}
        fetchPageSize={999}
        selector={selectLinkedPageLocations}
        target={linkedPageId}
        type={INSTANCE_TYPE_LOCATION}
        map={
          <Box
            style={{
              width: '100%',
              display: 'flex', // this and below just to center the 'Waiting for places with addresses' text
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            {currentMap && (
              <Map
                currentMap={currentMap}
                isArticle={false}
                flyOnMarkerClick={false}
                inlineMapHeight={400}
                maxSidepanelTitleLines={2}
                sidePanelShowLatLong={false}
                initialInteractive={false}
                fullwindowOnMapClick={true}
                interactiveWhenFullWindow={true}
                treeSlug={treeSlug} // used when linking in the side panel
                //markerItemType={INSTANCE_TYPE_LOCATION}
                allowThreeD={false}
                allowThreeDWhenFullWindow={true}
                allowEditButton={false}
                useDefaultMarkerIconsOnMap={true}
              />
            )}
          </Box>
        }
      />
    </>
  )
}

export default Locations
