import Joyride, { STATUS, LIFECYCLE } from 'react-joyride'

import React, { useEffect, useState } from 'react'
import { Card, CardContent, Box, CardActions } from '@mui/material'
import { Button, IconButton, Typography } from '../ui'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import { ACTION_ALL_ACCESS } from './appConstants'

export const Tooltip = ({ step, tooltipProps, closeProps }) => (
  <Card {...tooltipProps}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 0px 0px 15px',
      }}
    >
      <Typography variant="h5" component="h2">
        {step.title}
      </Typography>
      <IconButton permissionAction={ACTION_ALL_ACCESS} {...closeProps}>
        <CloseIcon />
      </IconButton>
    </Box>
    <CardContent>
      <Typography variant="body2">{step.content}</Typography>
    </CardContent>
  </Card>
)
export const TooltipFull = ({
  step,
  tooltipProps,
  index,
  primaryProps,
  skipProps,
}) => (
  <Card {...tooltipProps}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 0px 0px 15px',
      }}
    >
      <Typography variant="h5" component="h2">
        {step.title}
      </Typography>
      <IconButton permissionAction={ACTION_ALL_ACCESS} {...skipProps}>
        <CloseIcon />
      </IconButton>
    </Box>
    <CardContent>
      <Typography variant="body2">{step.content}</Typography>
    </CardContent>
    <CardActions
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        color="primary"
        {...primaryProps}
      >
        Next
      </Button>
    </CardActions>
  </Card>
)

const joyrides = {
  joyride_add_content_block_hint: {
    steps: [
      {
        title: 'Add Content Block',
        target: '.joyride_add_content_block',
        content:
          'Click to add a new content block with text, images, sub trees and video',
        disableBeacon: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: 'auto',
      },
    ],
    onceOnly: true,
  },
  joyride_add_profile_hint: {
    steps: [
      {
        title: 'Add profile',
        target: '.joyride_add_profile',
        content: 'Click to start creating an introductory article',
        disableBeacon: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: 'auto',
      },
    ],
  },
  joyride_add_overview_hint: {
    steps: [
      {
        title: 'Add overview',
        target: '.joyride_add_profile',
        content: 'Click to start creating an introductory article',
        disableBeacon: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: 'auto',
      },
    ],
  },
  joyride_individual_image_upload_hint: {
    steps: [
      {
        title: 'Add image',
        target: '.joyride_individual_image',
        content: 'Click the image icon to upload an image',
        disableBeacon: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: 'auto',
      },
    ],
  },
  joyride_first_time_user: {
    onCompleteComponent: null,
    steps: [
      {
        title: 'Family Pyramid Tool',
        target: '.joyride_first_time_user_families',
        content:
          "Let's have a quick tour, starting with this tool for opening your different Family sections",
        disableBeacon: true,
        hideFooter: true,
        placement: 'auto',
      },
      {
        title: 'TREE NAVIGATOR TOOL',
        target: '.joyride_first_time_user_navigator',
        content: 'Use to find any Individual or Family in the family tree',
        disableBeacon: true,
        hideFooter: true,
        placement: 'auto',
      },
      {
        title: 'Help System',
        target: '.joyride_first_time_user_help',
        content:
          'Access information and tutorials to help get the most out of your new site',
        disableBeacon: true,
        hideFooter: true,
        placement: 'auto',
      },
      {
        title: 'Create Content Tool',
        target: '.joyride_first_time_user_addcontent',
        content: 'Use to add content to specific Families and Individuals',
        disableBeacon: true,
        hideFooter: true,
        placement: 'auto',
      },
      {
        title: 'Sub Section Tabs',
        target: '.joyride_first_time_user_tab_bar',
        content:
          'Check out each tab for specific content relating to each section',
        disableBeacon: true,
        hideFooter: true,
        placement: 'auto',
      },
    ],
  },
}

const JOYRIDE_NORUN = 'joyride_norun'
const JOYRIDE_RUN = 'joyride_run'
const JOYRIDE_COMPLETE = 'joyride_complete'

export const UserJoyride = ({ joyRideName, runCondition = false }) => {
  const [joyRideState, setJoyrideState] = useState(JOYRIDE_NORUN)

  const joyrideConfig = joyrides[joyRideName]

  const handleStop = () => {
    setJoyrideState(JOYRIDE_NORUN)
    localStorage.setItem(joyRideName, 'complete ' + Date.now())
  }

  const handleJoyrideCallback = data => {
    const { status, lifecycle } = data

    if (status === STATUS.FINISHED && lifecycle === LIFECYCLE.COMPLETE) {
      if (joyrideConfig.onCompleteComponent) {
        setJoyrideState(JOYRIDE_COMPLETE)
      } else {
        handleStop()
      }
      sendEvent(ga4Events.USERJOYRIDE_COMPLETE)
    } else if (status === STATUS.SKIPPED && lifecycle === LIFECYCLE.COMPLETE) {
      sendEvent(ga4Events.USERJOYRIDE_SKIPPED)
    }
  }

  useEffect(() => {
    const previouslyRun = localStorage.getItem(joyRideName)
    if (!previouslyRun && runCondition) {
      setJoyrideState(JOYRIDE_RUN)
    }
  }, [setJoyrideState, joyRideName, runCondition])

  if (!joyrideConfig || joyRideState === JOYRIDE_NORUN) {
    return null
  }

  const OnCompleteComponent = joyrideConfig.onCompleteComponent

  /* DISABLE JOY RIDES AS NOW USING SIDE BAR */
  return null

  /* eslint-disable-next-line no-unreachable */
  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        debug={false}
        run={joyRideState === JOYRIDE_RUN}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        disableScrolling={true}
        disableOverlay={false}
        tooltipComponent={TooltipFull}
        steps={joyrideConfig?.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {joyRideState === JOYRIDE_COMPLETE && OnCompleteComponent && (
        <OnCompleteComponent handleClose={handleStop} />
      )}
    </>
  )
}

/*
 out of the box the joyride can only quit when you click finish or the close icon.  for the oneshot we wanted the joride to close whne you clicked on
 anything.  so made it a wrapper and detected the click on the child element
 */
export const OneShotJoyride = ({ children }) => {
  const location = useLocation()
  //set the joyride name and timestamp to run in the sate of the react-router push
  const { state } = location || {}

  const [run, setRun] = useState()
  const [joyrideTimeStamp, setJoyrideTimeStamp] = useState(0)
  const [joyrideConfig, setjoyrideConfig] = useState(0)
  const [joyRideName, setJoyRideName] = useState('joyride-no-name-set')

  useEffect(() => {
    //timestamp stops the joyride running again when it is complete ... timestamp will have ot be newer for it to run.
    //optional once only set to local storage
    const previouslyRun = localStorage.getItem(state?.joyrideName)
    const config = joyrides[state?.joyrideName]

    if (previouslyRun && config.onceOnly) {
      console.debug(`${state?.joyrideName} run once previously`)
    } else {
      if (
        state?.joyrideTimeStamp &&
        state?.joyrideTimeStamp > joyrideTimeStamp
      ) {
        setjoyrideConfig(config)
        setJoyRideName(state?.joyrideName)
        //a timeout to give the page a time to load.
        setTimeout(() => setRun(true), 1000)
        setJoyrideTimeStamp(state?.joyrideTimeStamp)
      }
    }
  }, [joyrideTimeStamp, state, setRun, setJoyrideTimeStamp])

  const handleClick = () => {
    if (joyrideConfig.onceOnly) {
      localStorage.setItem(joyRideName, 'complete ' + Date.now())
    }
    setRun(false)
  }

  /* DISABLE JOY RIDES AS NOW USING SIDE BAR */
  return children

  /* eslint-disable-next-line no-unreachable */
  return (
    <>
      {joyrideConfig?.steps && (
        <Joyride
          debug={false}
          run={run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlay={false}
          steps={joyrideConfig?.steps}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <div onClick={handleClick}>{children}</div>
    </>
  )
}
