import React, { useContext } from 'react'
import { Box } from '@mui/material'

import { Typography } from 'src/modules/ui'

import {
  generatePublicLinkForObject,
  INSTANCE_TYPE_FAMILY,
  useObjectLink,
} from '../app/links'
import { Link } from '../ui'

import { useIsPublic } from '../app'
import { PublicContext } from '../public/contexts'
import { formatFamilyWithDate } from '../ui/familyUtils'

const FamilyCard = ({ family }) => {
  const isPublic = useIsPublic()
  const publicContext = useContext(PublicContext)
  const privateFamilyLink = useObjectLink(INSTANCE_TYPE_FAMILY, family?.id)
  const publicFamilyLink = generatePublicLinkForObject(
    publicContext?.treeSlug,
    INSTANCE_TYPE_FAMILY,
    family?.id
  )

  const familyLink = isPublic ? publicFamilyLink : privateFamilyLink

  return (
    <Box
      boxShadow={2}
      borderRadius={1}
      my={0.6}
      p={1}
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '250px',
        minWidth: '250px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Link to={familyLink}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle5" ml={1}>
              {formatFamilyWithDate(family)}
            </Typography>
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

export default FamilyCard
