/*
This uses Geocoder, not SearchBox, because Geocoder has 'reverseGeocode' which supports lat/long coordinates
as inputs, which users have requested.
SearchBox has better POI support.

There are two ways to use this:
  1) to use Mapbox's layout management and have it embed the textinput inside the map, use the hook and call createGeocoder()
  2) to position the textinput within JSX 

1): 
import { useGeocoderOnMap } from '../services/GeocodeInputMapControl'


const useGeocoderOnMapHook = useGeocoderOnMap({
  geocodingItem,
  setGeocodingItem,
})

useGeocoderOnMapHook.createGeocoder(
  mapinst,
  onGeocoderBoxResult,
  onGeocodedExistingItemHandler
)




2):
 <GeocoderBox
    useGeocoderOnMapHook={useGeocoderOnMapHook}
    mapinst={map.current}
    resultHandler={onGeocoderBoxResult}
    onGeocodedExistingItemHandler={onGeocodedExistingItemHandler}
    geocodingItem={geocodingItem}
    setGeocodingItem={setGeocodingItem}
/>

*/

import config from '../../config'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { Map } from 'mapbox-gl'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_LOCATION,
} from '../app/links'
import { Feature, GeoJsonProperties, Point } from 'geojson'

import HouseIcon from '@mui/icons-material/House'
import EventIcon from '@mui/icons-material/Event'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'

import CribIcon from '@mui/icons-material/Crib'
import ReactDOMServer from 'react-dom/server'
import { useEffect, useRef } from 'react'
import { searchParameterised } from '../writeArticle/writeArticleSlice'
import { useActionDispatcher } from '../app'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { getTypeDisplayText } from '../map/Map'

import { Button } from '../ui'

import { ACTION_ALL_ACCESS } from '../app/appConstants'

interface SearchAPIResult {
  id: string
  instanceType: string
  instanceSubType?: string // for facts
  display: string
  previewThumbnail: { fileThumbnail: string; id: string }
  address: { freeText: string; long: number; lati: number }
}

export interface WeareLinkGeojsonProperty {
  id: string
  title: string
  instanceType: string
  instanceSubType?: string // for facts
  //display: string
  address: { freeText: string; long: number; lati: number }
  photo?: {
    fileThumbnail?: string
    // id: string
  }
}

type CreateGeocoderFnDefinition = {
  //description: string
  (
    map: Map,
    resultHandler: CallableFunction,
    onGeocodedExistingItemHandler: CallableFunction,
    addAsMapboxMapControl: boolean
  ): {
    geocoder: MapboxGeocoder
    callWhenMounted: CallableFunction
  }
}

interface UseGeocoderOnMapPropTypes {
  geocodingItem?: WeareLinkGeojsonProperty
  setGeocodingItem: CallableFunction
}

interface UseGeocoderOnMapHookResponse {
  createGeocoder: CreateGeocoderFnDefinition
}

/**
 * Returns an object with function 'createGeocoder'
 */
export const useGeocoderOnMap = ({
  geocodingItem,
  setGeocodingItem,
}: UseGeocoderOnMapPropTypes): UseGeocoderOnMapHookResponse => {
  const debug = false

  if (debug) {
    console.debug(`useGeocoderOnMap(): called, geocodingItem:`, geocodingItem)
    console.debug(`useGeocoderOnMap(): setGeocodingItem:`, setGeocodingItem)
  }

  const dispatchSearchParameterised = useActionDispatcher(searchParameterised)

  const geolocatingItemRef = useRef<WeareLinkGeojsonProperty>()

  useEffect(() => {
    //a parent component might cancel the operation
    //update the Ref so that the Mapbox-triggered event handler sees the latest data
    geolocatingItemRef.current = geocodingItem
  }, [geocodingItem])

  const createGeocoder: CreateGeocoderFnDefinition = (
    mapinst,
    resultHandler,
    onGeocodedExistingItemHandler,
    addAsMapboxMapControl = true
  ) => {
    // taken from https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/

    if (debug)
      console.debug(
        `createGeocoder(): called - addAsMapboxMapControl: ${addAsMapboxMapControl}`
      )

    const performLinkSearch = async (value: string) => {
      if (debug)
        console.debug(
          `performLinkSearch(): calling dispatchSearchParameterised(value: '${value}')...`
        )
      const results = await dispatchSearchParameterised({
        query: value,
        instanceTypes: [
          INSTANCE_TYPE_LOCATION,
          INSTANCE_TYPE_EVENT,
          INSTANCE_TYPE_ARTEFACT,
        ],
        detail: true,
      })
      if (debug)
        console.debug(
          `performLinkSearch(): after awaiting dispatchSearchParameterised(value: '${value}'), results:`,
          results
        )
      return results
    }

    const houseIconHtml = ReactDOMServer.renderToString(
      <HouseIcon fontSize="small" />
    )

    const eventIconHtml = ReactDOMServer.renderToString(
      <EventIcon fontSize="small" />
    )
    const artefactIconHtml = ReactDOMServer.renderToString(
      <MilitaryTechIcon fontSize="small" />
    )
    // console.debug(`houseIconHtml: ${houseIconHtml}`)

    const birthIconHtml = ReactDOMServer.renderToString(
      <CribIcon fontSize="small" />
    )

    const placesExternalGeocoder = async function (
      query: string
    ): Promise<Feature<Point, GeoJsonProperties>[]> {
      if (query) {
        let linkSearchResults: SearchAPIResult[] = []
        try {
          const fulfillment = await performLinkSearch(query)
          console.debug(
            `placesExternalGeocoder(): after awaiting performLinkSearch(query: '${query}'), fulfillment:`,
            fulfillment
          )
          if (fulfillment) {
            linkSearchResults = fulfillment.payload
          }
        } catch (err) {
          //meh
          console.error(
            `placesExternalGeocoder(): error from performLinkSearch(): ${err}`
          )
          return []
        }

        console.debug(`placesExternalGeocoder(): hello1`)
        if (linkSearchResults) {
          console.debug(`placesExternalGeocoder(): hello2`)

          console.debug(
            `placesExternalGeocoder(): linkSearchResults:`,
            linkSearchResults
          )

          const geocodeResults: any[] = linkSearchResults.map(
            (searchApiResultItem: SearchAPIResult) => {
              console.debug(
                `placesExternalGeocoder(): looking at searchApiResultItem:`,
                searchApiResultItem
              )
              let coords
              if (searchApiResultItem.address?.long) {
                coords = [
                  searchApiResultItem.address.long,
                  searchApiResultItem.address.lati,
                ]
              }

              //need to create Geojson (ish) to be displayed as a result in Mapbox's widget
              if (debug)
                console.debug(
                  `placesExternalGeocoder(): constructing Feature with coords:`,
                  coords
                )

              const weare_link: WeareLinkGeojsonProperty = {
                id: searchApiResultItem.id,
                title: searchApiResultItem.display,
                instanceType: searchApiResultItem.instanceType,
                instanceSubType: searchApiResultItem.instanceSubType,
                address: searchApiResultItem.address,
                photo: {
                  fileThumbnail:
                    searchApiResultItem.previewThumbnail?.fileThumbnail,
                },
              }

              // this is NOT a geojson standard Feature as it needs root properties place_name, place_type and center or the mapbox code crashes unhelpfully.
              // see: https://github.com/mapbox/mapbox-gl-geocoder/issues/359
              const feature: any = {
                type: 'Feature',
                //text: location.display,
                //place_name: `⌂ ${location.display}`,
                //place_name: `🏡 ${location.display}`,
                //place_name: '', //REQUIRED!!

                place_name: searchApiResultItem.display, //location.display,
                place_type: ['place'],
                center: coords,

                //place_name: location.display,
                //place_name: `${location.display}`,
                //place_name: `<span style="vertical-align: middle;">${houseIconHtml}</span>  ${location.display}, A place in this archive`,
                //place_type: ['place'],
                //center: center,
                //center: [location.address.long, location.address.lati],
                //location.address?.long ? {...{center: [location.address.long, location.address.lati]}} : {},
                properties: {
                  //title: `${location.display} is special`,
                  //original_place_name: location.display,
                  // weare_link_id: location.id,
                  // weare_link_display: location.display,
                  // weare_link_type: location.instanceType,
                  // weare_link_sub_type: location.instanceSubType,
                  weare_link: weare_link,
                  // weare_link: {
                  //   id: searchApiResultItem.id,
                  //   title: searchApiResultItem.display,
                  //   instanceType: searchApiResultItem.instanceType,
                  //   instanceSubType: searchApiResultItem.instanceSubType,
                  //   //thumbnail_url: location.previewThumbnail?.fileThumbnail,
                  //   address: searchApiResultItem.address,
                  //   photo: {
                  //     fileThumbnail:
                  //       searchApiResultItem.previewThumbnail?.fileThumbnail,
                  //   },
                  //   sdfsf: 'sdfs',
                  // } as WeareLinkGeojsonProperty,
                  //thumbnail_url: location.previewThumbnail?.fileThumbnail,
                  //weare_address_freeText: searchApiResultItem.address?.freeText,
                },
                ...(coords && {
                  geometry: {
                    type: 'Point',
                    //coordinates: [location.address.long, location.address.lati],
                    //...(center && { coordinates: center }),
                    coordinates: coords,
                  },
                }),
              }

              console.debug(
                `placesExternalGeocoder(): returning feature:`,
                feature
              )
              return feature
            }
          )
          console.debug(`placesExternalGeocoder(): hello3`)

          console.debug(
            `placesExternalGeocoder(): returning geocodeResults:`,
            geocodeResults
          )
          return geocodeResults
        } else {
          console.debug(
            `placesExternalGeocoder(): empty response from performLinkSearch`
          )
        }
      }
      console.debug(`placesExternalGeocoder(): done`)

      return []
    }

    /* Given a query in the form "lng, lat" or "lat, lng"
     * returns the matching geographic coordinate(s)
     * as search results in carmen geojson format,
     * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
    const coordinatesGeocoder = function (query: string) {
      if (debug)
        console.debug(`coordinatesGeocoder(): called with query: ${query}`)

      // Match anything which looks like
      // decimal degrees coordinate pair.
      const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
      )
      if (!matches) {
        // if (passthroughLocalGeocoderFn) {
        //   return passthroughLocalGeocoderFn(query)
        // }
        return null
        //return placesLocalGeocoder(query)
      }

      function coordinateFeature(lng: number, lat: number) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature',
        }
      }

      const coord1 = Number(matches[1])
      const coord2 = Number(matches[2])
      const geocodes = []

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2))
      }

      if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2))
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      return geocodes
    }

    const geocoder = new MapboxGeocoder({
      accessToken: config?.mapbox?.token ?? '',
      marker: false,
      placeholder: 'Search name or coordinates (e.g. 1, 52)',
      localGeocoder: coordinatesGeocoder as any,
      externalGeocoder: placesExternalGeocoder as any,
      //externalGeocoder: testExternalGeocoderAsync as any,
      //externalGeocoder: testExternalGeocoder as any,
      reverseGeocode: true,
      proximity: 'ip',
      // render: geojson => {
      //   return `<div>${geojson.place_name}</div>`
      // },
    })

    geocoder.setFlyTo(false) //disable automatic flyTo in case the selected item isn't geocoded already
    const geocoderDefaultZoom = geocoder.getZoom()
    geocoder.on('result', result => {
      console.debug(`GeocodeInputMapControl.onResult():`, result)
      console.debug(
        `GeocodeInputMapControl.onResult(): geolocatingItemRef:`,
        geolocatingItemRef
      )
      const weareLink: WeareLinkGeojsonProperty =
        result.result?.properties?.weare_link

      // if the selected result isn't geolocated then do a second search using its address
      // and set 'geolocatingItemRef' so we can update its geolocation if the user agrees
      if (!result.result.geometry) {
        //geocoder.setZoom(undefined)
        //geocoder.setFlyTo(false)
        console.debug(
          `GeocodeInputMapControl.onResult(): selected item has no geometry, setting geolocatingItemRef to it. weare_address_freeText: ${result.result?.properties?.weare_address_freeText}`,
          result
        )
        geolocatingItemRef.current = result.result
        // setGeocodingItem() makes MapEdit show a message prompting user to search for item's location
        if (weareLink?.address?.freeText) {
          geocoder.setInput(
            weareLink?.address?.freeText,
            //result.result.properties.weare_address_freeText,
            true
          )
        } else {
          console.debug(
            `GeocodeInputMapControls.onResult(): user clicked result with no geometry and also no address.freetext, cannot help user locate, calling setGeocodingItem()...`
          )
          geocoder.clear()
        }
        //this will cause the container to show an info box prompting the user to search for the location of their item
        setGeocodingItem(result.result.properties?.weare_link)
      } else {
        // selected entry has geometry

        // automatic flyTo has been disabled in case the selected item isn't geocoded already
        geocoder.clear()
        console.debug(
          `GeocodeInputMapControl.onResult(): calling fitBounds or flyTo()...`,
          result.result
        )
        if (result.result.bbox) {
          mapinst.fitBounds(result.result.bbox)
        } else {
          mapinst.flyTo({ ...result.result, zoom: geocoderDefaultZoom }) //zoom should be used if there isn't a bbox
        }

        // if we had previously selected an item to set the location of, do that now
        if (geolocatingItemRef.current) {
          result.result.properties.weare_geocoding_of =
            geolocatingItemRef.current

          // not sure if want to do this
          // it makes the popup show the item, but as the item has not yet been associated
          // with this location it might be confusing
          //result.result.properties.weare_link =
          //  geolocatingItemRef.current.properties.weare_link

          // result.result.properties.weare_link_type =
          //   geolocatingFactRef.current.properties.weare_link_type

          // result.result.properties.weare_link_id =
          //   geolocatingFactRef.current.properties.weare_link_id
          // result.result.properties.weare_link_display =
          //   geolocatingFactRef.current.properties.weare_link_display

          if (onGeocodedExistingItemHandler) {
            //defined in Map
            onGeocodedExistingItemHandler(
              geolocatingItemRef.current,
              result.result
            )
          }
        }

        if (resultHandler) {
          if (debug)
            console.debug(
              `GeocodeInputMapControl.onResult(): calling resultHandler()`,
              result.result
            )

          resultHandler(result.result)
        }
      }
    })

    const configureRenderFunction = () => {
      // don't run this until the geocoder UI has been rendered
      const defaultRenderFn = geocoder.getRenderFunction()
      geocoder.setRenderFunction(geojson => {
        console.debug(
          `GeocodeInputMapControl.createGeocoder().configureRenderFunction().renderFunction(): called with geojson`,
          geojson
        )
        if (geojson?.properties?.weare_link) {
          const link: WeareLinkGeojsonProperty = geojson.properties.weare_link
          let iconHtml = ''
          let typeDisplay = link.instanceType
          const display = link.title ?? link.address?.freeText
          switch (link.instanceType) {
            case 'location':
              iconHtml = houseIconHtml
              typeDisplay = 'place'
              break
            case 'event':
              iconHtml = eventIconHtml
              typeDisplay = 'occasion'
              break
            case 'artefact':
              iconHtml = artefactIconHtml
              break

            case 'fact':
              //TODO switch on weare_link_sub_type
              iconHtml = birthIconHtml
              typeDisplay = 'birth fact'
              break
          }
          //return `<span style="vertical-align: middle;">${houseIconHtml}</span>  ${geojson.place_name}, A place in this archive`
          return `
        <div class="mapboxgl-ctrl-geocoder--suggestion"><div class="mapboxgl-ctrl-geocoder--suggestion-title" style="vertical-align: middle;"><div style="vertical-align: middle; display: inline-block; width: 24px; height: 24px;">${iconHtml}</div>  ${display}</div>
        <div class="mapboxgl-ctrl-geocoder--suggestion-address"> A ${typeDisplay} in this archive</div></div>
`
        }
        return defaultRenderFn(geojson)
      })
    }

    if (addAsMapboxMapControl) {
      mapinst.addControl(
        geocoder,
        'top-left' //TODO bump the search box down to allow for image viewer's X button
      )
    }

    return { geocoder: geocoder, callWhenMounted: configureRenderFunction }
  }

  return { createGeocoder: createGeocoder }
}

interface GeocoderBoxPropTypes {
  mapinst: Map
  resultHandler: CallableFunction
  onGeocodedExistingItemHandler: CallableFunction
  geocodingItem: WeareLinkGeojsonProperty
  setGeocodingItem: CallableFunction
}
export const GeocoderBox = ({
  mapinst,
  resultHandler,
  onGeocodedExistingItemHandler,
  geocodingItem,
  setGeocodingItem,
}: GeocoderBoxPropTypes) => {
  const geocoderRef = useRef<MapboxGeocoder>()
  const geocoderContainerRef = useRef()

  const useGeocoderOnMapHook = useGeocoderOnMap({
    geocodingItem,
    setGeocodingItem,
  })

  if (!geocoderRef.current && geocoderContainerRef.current) {
    const createGeocoderRes = useGeocoderOnMapHook.createGeocoder(
      mapinst,
      resultHandler,
      onGeocodedExistingItemHandler,
      false
    )
    geocoderRef.current = createGeocoderRes.geocoder

    geocoderRef.current.addTo(geocoderContainerRef.current)

    if (createGeocoderRes.callWhenMounted) {
      createGeocoderRes.callWhenMounted()
    }
  }

  const initCap = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <Box id="geocoder-box">
      <Box id="weare-mapbox-geocoder-widget" ref={geocoderContainerRef}></Box>

      {geocodingItem && (
        <Box
          sx={{
            mt: '62px',
            //ml: '10px',
            ml: 'auto',
            //mr: 'auto',
            p: 2,
            pb: 1,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: 2,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <Typography>
              {initCap(getTypeDisplayText(geocodingItem.instanceType))} '
              {geocodingItem.title}'{' '}
              {geocodingItem.address?.freeText
                ? 'only has a free text address'
                : ' has no location set'}
              . Search again {/* or click on the map */} to set it.
            </Typography>{' '}
          </Box>
          <Box ml="auto" mt={1}>
            {/* @ts-expect-error some props are not mandatory */}
            <Button
              permissionAction={ACTION_ALL_ACCESS} //TODO tighten 'update place location' permission to ACTION_EDIT
              onClick={(e: Event) => {
                setGeocodingItem(undefined)
              }}
              //enabled={true}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
