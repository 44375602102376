import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { INSTANCE_TYPE_INDIVIDUAL } from '../../app/links'
import BaseIndividualsList from '../../page/BaseIndividualsList'
import {
  fetchPublicPageIndividuals,
  selectPublicIndividualsSearchTerm,
  selectPublicPageIndividuals,
  setPublicIndividualsSearchTerm,
  setPublicViewConfig,
} from '../page/pageSlice'
import { PublicContext } from '../contexts'
import { useLinkedPageTargets } from '../../page/hooks'
import PublicActionBar from './PublicActionBar'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../../common/viewConfigUtils'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import IndividualSearchBox from '../../ui/IndividualSearchBox'

const PublicHomeIndividualList = () => {
  const { treeSlug } = useContext(PublicContext)
  const { target } = useLinkedPageTargets()
  const dispatch = useDispatch()
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatchFetchPageIndividuals = useActionDispatcher(
    fetchPublicPageIndividuals
  )
  const publicPageIndividuals = useSelector(selectPublicPageIndividuals)
  const { next, results, updated } = publicPageIndividuals
  const type = INSTANCE_TYPE_INDIVIDUAL

  const makeArgs = useCallback(
    page => {
      const args = { page, type, treeSlug, target }
      return args
    },
    [type, treeSlug, target]
  )

  useEffect(() => {
    const fetchPageIndividuals = async () => {
      await dispatchFetchPageIndividuals(makeArgs(0))
    }

    if (!results.length) {
      fetchPageIndividuals()
    }
  }, [dispatchFetchPageIndividuals, makeArgs, type, results.length, updated])

  const handleFetchMore = () => {
    if (dispatchFetchPageIndividuals.status === 'loading') {
      return
    }
    dispatchFetchPageIndividuals(makeArgs(publicPageIndividuals.page + 1))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    publicPageIndividuals,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setPublicViewConfig({ type, viewConfig }))
    dispatchFetchPageIndividuals(makeArgs(0))
  }

  const search = useCallback(() => {
    dispatchFetchPageIndividuals(makeArgs(0))
  }, [makeArgs, dispatchFetchPageIndividuals])

  const refresh = useCallback(() => {
    dispatchFetchPageIndividuals(makeArgs(0, null))
  }, [makeArgs, dispatchFetchPageIndividuals])

  return (
    <>
      <PublicActionBar
        type={type}
        sort={sort}
        refresh={refresh}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        handleSetViewConfig={handleSetViewConfig}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <IndividualSearchBox
            searchTermAction={setPublicIndividualsSearchTerm}
            selector={selectPublicIndividualsSearchTerm}
            onChange={search}
            onClear={refresh}
            busy={dispatchFetchPageIndividuals.status === 'loading'}
          />
        </Box>
      </PublicActionBar>
      <BaseIndividualsList
        {...{
          dispatchFetchIndividuals: dispatchFetchPageIndividuals,
          next,
          handleFetchMore,
          individuals: publicPageIndividuals,
          results,
          type,
        }}
      />
    </>
  )
}

export default PublicHomeIndividualList
