import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import FormikTextField from '../ui/FormikTextField'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import Button from '../ui/Button'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { createQuestionGroups } from './AiWizardUtils'
import AiWizardAddMediaDialogForm from './AiWizardAddMediaDialogForm'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import {
  AddIndividualOnlyManualDialog,
  SelectIndividualOnlyManualDialog,
} from '../viewer/ChooseOrAddIndividual'
import { EditTreeContainer } from '../viewer/treeViewers'
import { setEditedIndividualId } from '../viewer/exploreTreeSlice'
import { useDispatch, useSelector } from 'react-redux'
import FixedHeightDialogContent from '../ui/FixedHeightDialogContent'
import { selectNodeDirectory } from '../viewer/viewerSlice'
import { styled } from '@mui/system'
import _ from 'lodash'
import {
  saveSelectedInstance,
  selectedInstances,
} from '../services/servicesSlice'
import { formatIndividualName } from '../ui/individualUtils'
import FormikRadioGroup from '../ui/FormikRadioGroup'

const AiWizard = ({
  wizardConfig,
  formDefinition,
  onSubmit,
  selectedInstanceOverride,
}) => {
  const [selectedInstance, setSelectedInstance] = useState(
    selectedInstanceOverride
  )

  const displaySelectIndividual =
    wizardConfig?.preWizardInstanceSelector === INSTANCE_TYPE_INDIVIDUAL &&
    selectedInstance === undefined

  const displayWizard =
    (selectedInstance !== undefined &&
      wizardConfig?.preWizardInstanceSelector === INSTANCE_TYPE_INDIVIDUAL) ||
    !wizardConfig?.preWizardInstanceSelector

  return (
    <>
      {displaySelectIndividual && (
        <div>
          <SelectIndividualDialog setSelectedInstance={setSelectedInstance} />
        </div>
      )}
      {displayWizard && (
        <div>
          <AiWizardGeneratedForm
            selectedInstance={selectedInstance}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
          />
        </div>
      )}
    </>
  )
}

const QuestionGroupContainer = styled('div')(({ theme }) => ({
  display: 'block',
  flex: 1,
  overflow: 'auto',
  minHeight: '60vh',
  maxHeight: '60vh',
}))

const ButtonRowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 4,
  padding: '1rem',
  backgroundColor: theme.palette.backgroundGrey.main,
}))

const AiWizardGeneratedForm = ({
  selectedInstance,
  formDefinition = [],
  onSubmit,
}) => {
  const [initialValues, setInitialValues] = useState({})
  const [groupIndex, setGroupIndex] = useState(0)

  formDefinition = _.cloneDeep(formDefinition)

  const questionGroups = createQuestionGroups(formDefinition, selectedInstance)

  useEffect(() => {
    const initValues = formDefinition.reduce((acc, field) => {
      acc[field.Question] = field.DefaultValue || ''
      return acc
    }, {})
    setInitialValues(initValues)
  }, [formDefinition, setInitialValues, selectedInstance])

  const setIndexWithDebugging = (index, values) => {
    // console.log('DEBUG values', values)
    setGroupIndex(index)
  }

  const isInitialized = Object.keys(initialValues).length > 0

  if (!isInitialized) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        if (onSubmit) {
          onSubmit(values, selectedInstance)
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, handleSubmit, values }) => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <QuestionGroupContainer>
              {questionGroups.groups?.map(groupId => (
                <AiWizardQuestionGroup
                  selectedInstance={selectedInstance}
                  groupIndex={groupIndex}
                  key={groupId}
                  groupId={groupId}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  questionGroups={questionGroups}
                  values={values}
                />
              ))}
            </QuestionGroupContainer>
            <ButtonRowContainer>
              {groupIndex > 0 && (
                <div>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    isLoading={isSubmitting}
                    onClick={() =>
                      setIndexWithDebugging(groupIndex - 1, values)
                    }
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Previous
                  </Button>
                </div>
              )}
              {groupIndex === questionGroups.groups.length - 1 && (
                <div>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              )}
              {groupIndex < questionGroups.groups.length - 1 && (
                <div>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    isLoading={isSubmitting}
                    onClick={() =>
                      setIndexWithDebugging(groupIndex + 1, values)
                    }
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Next
                  </Button>
                </div>
              )}
            </ButtonRowContainer>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const SelectIndividualDialog = ({ setSelectedInstance }) => {
  const [addIndividualModalOpen, setAddIndividualModalOpen] = useState(false)
  const [selectIndividualModalOpen, setSelectIndividualModalOpen] =
    useState(false)
  const dispatch = useDispatch()

  const nodeDirectory = useSelector(selectNodeDirectory)

  const handleIndividualSelected = individual => {
    const instance = nodeDirectory[individual?.id]
    dispatch(saveSelectedInstance(instance))
    setSelectedInstance(instance)
  }

  return (
    <>
      <Typography sx={{ mb: '1rem' }}>
        Select or add an individual from your archive to use in the wizard
      </Typography>
      <AddIndividualOnlyManualWithTreeEditorDialog
        setModalOpen={setAddIndividualModalOpen}
        modalOpen={addIndividualModalOpen}
        onIndividualAdded={individual => handleIndividualSelected(individual)}
      />
      <SelectIndividualOnlyManualDialog
        setModalOpen={setSelectIndividualModalOpen}
        modalOpen={selectIndividualModalOpen}
        onIndividualSelected={individual => {
          handleIndividualSelected(individual)
        }}
      />
      <AddOrSelectIndividualList
        setSelectIndividualModalOpen={setSelectIndividualModalOpen}
        setAddIndividualModalOpen={setAddIndividualModalOpen}
        onIndividualSelected={handleIndividualSelected}
      />
    </>
  )
}

const AddIndividualOnlyManualWithTreeEditorDialog = ({
  setModalOpen,
  modalOpen,
  onIndividualAdded,
}) => {
  const dispatch = useDispatch()
  const [addedIndividual, setAddedIndividual] = useState(null)
  const [editTreeModelOpen, setEditTreeModelOpen] = useState(false)

  const handleIndividualAdded = individual => {
    setModalOpen(false)
    setAddedIndividual(individual)
    dispatch(setEditedIndividualId(individual.id))
    setEditTreeModelOpen(true)
  }

  const handleIndividualAddedComplete = () => {
    setEditTreeModelOpen(false)
    onIndividualAdded(addedIndividual)
  }

  return (
    <>
      <AddIndividualOnlyManualDialog
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        onIndividualAdded={individual => handleIndividualAdded(individual)}
      />
      <EditTreeModelDialog
        modalOpen={editTreeModelOpen}
        setModelOpen={setEditTreeModelOpen}
        onEditComplete={handleIndividualAddedComplete}
      />
    </>
  )
}

const EditTreeModelDialog = ({ modalOpen, setModelOpen, onEditComplete }) => {
  return (
    <Dialog open={modalOpen} onClose={() => onEditComplete()} scroll="paper">
      <DialogTitle onClose={() => onEditComplete()}>Add Relatives</DialogTitle>
      <FixedHeightDialogContent>
        <Box sx={{ mb: 1.5 }}>
          <EditTreeContainer />
        </Box>
      </FixedHeightDialogContent>
      <DialogActions>
        <Button
          color="primary"
          permissionAction={ACTION_ALL_ACCESS}
          onClick={() => onEditComplete()}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const FieldLabel = ({ field }) => {
  return (
    <Typography sx={{ marginBottom: '0.5rem' }}>
      {field.questionIndex}. {field.Description}
    </Typography>
  )
}

const AiWizardQuestionGroup = ({
  groupId,
  values,
  questionGroups,
  setFieldValue,
  isSubmitting,
  groupIndex = 0,
  selectedInstance,
}) => {
  const questions = questionGroups?.page[groupId] || []
  const [previewTiles, setPreviewTiles] = useState([])
  const visibility =
    questionGroups.groups[groupIndex] === groupId ? 'block' : 'none'

  return (
    <div style={{ display: visibility }}>
      <Typography
        sx={{
          marginBottom: '1rem',
          backgroundColor: 'lightgrey',
          padding: '0.5rem',
        }}
        variant="h6"
      >
        {groupId}
      </Typography>
      {questions?.map((field, index) => (
        <Box key={field.Question} mb={2} ml={1}>
          <FieldLabel field={field} />
          {field.Type.toLowerCase() === 'text' && (
            <FormikTextField
              fullWidth
              label={field.Question}
              disabled={isSubmitting}
              multiline={true}
              name={field.Question}
              variant="outlined"
            />
          )}
          {field.Type.toLowerCase() === 'date' && (
            <div style={{ width: '30%' }}>
              <FormikGedDatePicker
                fullWidth
                label={field.Question}
                disabled={isSubmitting}
                margin="normal"
                name={field.Question}
                setFieldValue={setFieldValue}
              />
            </div>
          )}
          {field.Type.toLowerCase() === 'image' && (
            <AiWizardAddMediaDialogForm
              selectedInstance={selectedInstance}
              values={values}
              name={field.Question}
              setFieldValue={setFieldValue}
              previewTiles={previewTiles}
              setPreviewTiles={setPreviewTiles}
            />
          )}
          {field.Type.toLowerCase() === 'gender' && (
            <FormikRadioGroup
              value={values[field.Question]}
              optionLabelValues={[
                ['Male', 'Male'],
                ['Female', 'Female'],
                ['Unknown', 'Unknown'],
              ]}
              name={field.Question}
              setFieldValue={setFieldValue}
              disabled={isSubmitting}
            />
          )}
          {field.Type.toLowerCase() === 'state' && (
            <FormikRadioGroup
              value={values[field.Question]}
              optionLabelValues={[
                ['Alive', 'ALIVE'],
                ['Deceased', 'DECEASED'],
                ['Unknown', 'UNKNOWN'],
              ]}
              name={field.Question}
              setFieldValue={setFieldValue}
              disabled={isSubmitting}
            />
          )}
        </Box>
      ))}
    </div>
  )
}

const AddOrSelectIndividualList = ({
  setAddIndividualModalOpen,
  setSelectIndividualModalOpen,
  onIndividualSelected,
}) => {
  const selectSelectIndividual = () => {
    setSelectIndividualModalOpen(true)
  }
  const selectAddIndividual = () => {
    setAddIndividualModalOpen(true)
  }

  const selectedInstancesForWizardWithDupes = useSelector(selectedInstances)

  const selectedInstancesForWizard = _.take(
    _.uniqBy(selectedInstancesForWizardWithDupes, 'id'),
    3
  )

  return (
    <List
      sx={{
        width: '100%',
      }}
    >
      {selectedInstancesForWizard.map(instance => (
        <>
          <ListItemText
            sx={{
              padding: '1rem',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f0f0f0', // Change this color to whatever highlight color you prefer
              },
            }}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold' }, // Make the primary text bold
            }}
            onClick={() => onIndividualSelected(instance)}
            primary={'Use ' + formatIndividualName(instance)}
            secondary={
              <>
                <Typography sx={{ display: 'inline' }}>
                  use {formatIndividualName(instance)} again in the wizard
                </Typography>
              </>
            }
          />
          <Divider component="li" />
        </>
      ))}

      <ListItemText
        sx={{
          padding: '1rem',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#f0f0f0', // Change this color to whatever highlight color you prefer
          },
        }}
        primaryTypographyProps={{
          sx: { fontWeight: 'bold' }, // Make the primary text bold
        }}
        onClick={() => selectAddIndividual()}
        primary={'Add individual'}
        secondary={
          <>
            <Typography sx={{ display: 'inline' }}>
              Add a new individual to your archive
            </Typography>
          </>
        }
      />
      <Divider component="li" />
      <ListItemText
        sx={{
          padding: '1rem',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#f0f0f0', // Change this color to whatever highlight color you prefer
          },
        }}
        primaryTypographyProps={{
          sx: { fontWeight: 'bold' }, // Make the primary text bold
        }}
        onClick={() => selectSelectIndividual()}
        primary={'Select existing individual'}
        secondary={
          <>
            <Typography sx={{ display: 'inline' }}>
              Select an existing individual by searching for them in your
              archive
            </Typography>
          </>
        }
      />
      <Divider component="li" />
    </List>
  )
}

export default AiWizard
