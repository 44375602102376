import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { Box, styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import {
  useWaitForSystemEvent,
  SYSTEM_EVENT_STATE_DONE,
  SYSTEM_EVENT_STATE_FAILED,
} from 'src/modules/systemEvent/hooks'
import { Button, LoadingIndicator, Typography } from 'src/modules/ui'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

import { uploadGedCom } from './treesSlice'
import { ga4Events, sendPageview, sendEvent } from '../analytics/AnalyticsUtils'
import { stripGedFromTreeName } from './utils'
import { ACTION_ALL_ACCESS, ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_TREE } from '../app/links'
import Container from '../ui/Container'
import { useHistory } from 'react-router-dom'

const MODE_UPLOAD = 'UPLOAD'

const STATE_WAITING = 'WAITING'
const STATE_NAMING = 'NAMING'
const STATE_PROCESSING = 'PROCESSING'
const STATE_DONE = 'DONE'
const STATE_DONE_EMPTY = 'DONE_EMPTY'
const STATE_FAILED = 'FAILED'
const STATE_FAILED_UPLOAD = 'STATE_FAILED_UPLOAD'
const STATE_FAILED_PROCESSING = 'STATE_FAILED_PROCESSING'

export const CentreContainer = styled('div')(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
}))

const GoHomeButton = ({ label }) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const history = useHistory()
  const skip = () => {
    const url = `/${treeSlug}`

    history.push(url)
  }

  return (
    <>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
        color="secondary"
        variant="outlined"
        size="large"
        onClick={skip}
      >
        {label}
      </Button>
    </>
  )
}

export function UploadGedCom() {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const [componentState, setComponentState] = useState(STATE_WAITING)
  const dispatchUploadGedCom = useActionDispatcher(uploadGedCom)

  const mode = MODE_UPLOAD

  const [systemEventId, setSystemEventId] = useState(undefined)

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__CREATE_OR_UPDATE_TREE)
  }, [])

  const handleUploadGedCom = useCallback(
    async uploadFile => {
      sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_UPLOADING_GEDCOM)
      setComponentState(STATE_PROCESSING)
      try {
        const response = await dispatchUploadGedCom({
          treeSlug,
          file: uploadFile,
        }).unwrap()

        setSystemEventId(response?.systemEvent?.id)
        sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_UPLOADED)
      } catch {
        sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_UPLOAD_ERROR)
        setComponentState(STATE_FAILED_UPLOAD)
      }
    },
    [dispatchUploadGedCom, setComponentState, treeSlug]
  )

  const onDrop = useCallback(
    async acceptedFiles => {
      sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_FILE_SELECTED)
      try {
        const file = new File(
          [acceptedFiles[0]],
          stripGedFromTreeName(acceptedFiles[0].name)
        )
        handleUploadGedCom(file)
      } catch (err) {
        console.error(
          `CreateOrUpdateTree.onDrop(): error checking dropped file`,
          err
        )
        setComponentState(STATE_FAILED)
      }
    },
    [handleUploadGedCom]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({ accept: '.ged', onDrop, noClick: true, noKeyboard: true })

  const onSystemEventStateUpdate = useCallback(
    state => {
      if (state === SYSTEM_EVENT_STATE_DONE) {
        sendEvent(ga4Events.TREE_CREATED_FROM_GEDCOM_OK)
        sendEvent(ga4Events.ADMIN_CREATED)

        setComponentState(STATE_DONE)
      } else if (state === SYSTEM_EVENT_STATE_FAILED) {
        sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_PROCESSING_ERROR)
        setComponentState(STATE_FAILED_PROCESSING)
      }
    },
    [setComponentState]
  )

  useWaitForSystemEvent(onSystemEventStateUpdate, systemEventId)

  return (
    <Container>
      <CentreContainer>
        <div {...getRootProps()}>
          {componentState !== STATE_DONE_EMPTY && (
            <>
              <input data-cy="import_ged_drag-and-drop" {...getInputProps()} />

              <Box sx={{ mb: 4, mt: 2 }}>
                {mode === MODE_UPLOAD && (
                  <>
                    <Box sx={{ mb: 4 }}>
                      <Typography variant="h1" color="primary">
                        Upload a Gedcom file
                      </Typography>
                    </Box>
                    {componentState === STATE_WAITING && (
                      <Typography color="textSecondary" variant="subtitle1">
                        Upload a GEDCOM file to your archive
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              {componentState !== STATE_PROCESSING &&
                componentState !== STATE_DONE &&
                componentState !== STATE_DONE_EMPTY && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 5,
                    }}
                  >
                    <Button
                      permissionAction={ACTION_CREATE}
                      permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                      color="secondary"
                      variant="outlined"
                      size="large"
                      onClick={
                        componentState !== STATE_NAMING
                          ? () => {
                              sendEvent(
                                ga4Events.CREATE_OR_UPDATE_TREE_OPENED_FILE_DIALOG
                              )
                              openFileDialog()
                            }
                          : handleUploadGedCom
                      }
                      isLoading={componentState === STATE_PROCESSING}
                    >
                      Import GEDCOM
                    </Button>
                    <GoHomeButton label={'Skip'} />
                  </Box>
                )}
              <Box sx={{ mt: 4 }}>
                {isDragActive ? (
                  <Typography>Drop file to import...</Typography>
                ) : componentState === STATE_PROCESSING ? (
                  <>
                    <Typography>Processing tree...</Typography>
                    <LoadingIndicator />
                  </>
                ) : componentState === STATE_FAILED ||
                  componentState === STATE_FAILED_UPLOAD ||
                  componentState === STATE_FAILED_PROCESSING ? (
                  <Typography color="red" sx={{ mb: 2 }}>
                    {componentState === STATE_FAILED_UPLOAD ? (
                      <>
                        Sorry, that gedcom file failed to upload. Please try
                        again. If this continues please contact us using 'Send
                        Feedback' or the 'Support' link at the bottom of the
                        page.
                        <GoHomeButton label={'Skip'} />
                      </>
                    ) : (
                      <>
                        Sorry, we could not process that gedcom file. Please try
                        again. If this continues please contact us using 'Send
                        Feedback' or the 'Support' link at the bottom of the
                        page.
                        <GoHomeButton label={'Skip'} />
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography>&nbsp;</Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                {componentState === STATE_DONE && (
                  <>
                    <GoHomeButton label={'Finish'} />
                  </>
                )}
              </Box>
            </>
          )}
        </div>
      </CentreContainer>
    </Container>
  )
}

export default UploadGedCom
