import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Typography, styled } from '@mui/material'

import { ContentInlineComments } from 'src/modules/content/ContentInlineComments'
import AddMediaToPageDialog from './AddMediaToPageDialog'
import CreateMultiplePhotosDialog from 'src/modules/photo/CreateMultiple'

import { LoadingIndicator, MasonryCardList } from 'src/modules/ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import { useTreeLink } from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import Refresh from '../ui/Refresh'
import { AddStoryButton, UploadMediaButton } from '../ui/actionButtons'

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

const SemiBold = styled('span')(({ theme }) => ({
  fontWeight: 500,
}))

const NewsFeed = ({
  dispatchFetchFeed,
  feed,
  user,
  feedActionDispatcher,
  additionalButton,
  presetTargets,
  showUploadNotAddMedia = false,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  useEffect(() => {
    dispatchFetchFeed()
  }, [dispatchFetchFeed, treeSlug])

  const handleFetchMore = () => {
    if (feedActionDispatcher.status === 'loading') {
      return
    }
    const lastItem = feed.results[feed.results.length - 1]
    if (lastItem) {
      const before = lastItem.publishedAt
      dispatchFetchFeed({ before })
    }
  }

  const handleOnFinishedUploadingPhotos = () => {
    dispatchFetchFeed()
  }
  const writeLink = useTreeLink('write-article')

  return (
    <>
      {feedActionDispatcher.status === 'loading' &&
      !feed.before &&
      feed.results.length === 0 ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <div />
            <ActionButtons>
              <div>
                <AddStoryButton
                  to={{
                    pathname: writeLink,
                    state: {
                      presetTargets: presetTargets,
                    },
                  }}
                />
              </div>
              <div>
                {!showUploadNotAddMedia ? (
                  <AddMediaToPageDialog
                    targets={presetTargets}
                    onFinishedUploading={handleOnFinishedUploadingPhotos}
                  />
                ) : (
                  <CreateMultiplePhotosDialog
                    onFinishedUploading={handleOnFinishedUploadingPhotos}
                    trigger={props => <UploadMediaButton {...props} />}
                  />
                )}
              </div>
              {additionalButton}
            </ActionButtons>
            <Refresh onClick={dispatchFetchFeed} />
          </Box>

          <Feed
            {...{
              feedActionDispatcher,
              feed,
              handleFetchMore,
              user,
              handleDeleteFeedItem: dispatchFetchFeed,
            }}
          />
        </Box>
      )}
    </>
  )
}

export const Feed = ({
  feedActionDispatcher,
  feed,
  handleFetchMore,
  user,
  Card = ContentInlineComments,
  handleDeleteFeedItem,
}) => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography color="textPrimary">
          <SemiBold>Family Updates</SemiBold> Most recent
        </Typography>
      </Box>
      {feed.results.length === 0 &&
      feedActionDispatcher.status !== 'loading' ? (
        <EmptyFeed />
      ) : (
        <InfiniteScroll
          dataLength={feed.results.length}
          next={handleFetchMore}
          hasMore={feed.hasMore}
          style={{ overflowY: 'hidden' }}
          loader={<LoadingIndicator />}
        >
          <MasonryCardList
            contentData={feed.results}
            user={user}
            cardComponent={Card}
            onDelete={handleDeleteFeedItem}
          />
        </InfiniteScroll>
      )}
    </>
  )
}

export default NewsFeed
