// from https://stackblitz.com/edit/vitejs-vite-bvutvb?file=src%2FExtendedMapboxDraw.ts
// from https://github.com/mapbox/mapbox-gl-draw/issues/874#issuecomment-2031731131

import { Map } from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

const MapboxDrawConstants = MapboxDraw.constants
//const MapboxDrawLib = MapboxDraw.lib

export type DrawBarButton = {
  on: keyof HTMLElementEventMap
  action: (e: Event) => void
  classes?: string[]
  content: HTMLElement
  toolTip?: string
  id: string
  highlightButton?: boolean
}

export type ConstructorProps = {
  props: ConstructorParameters<typeof MapboxDraw>[0]
  buttons?: DrawBarButton[]
}

const debug = false

/**
 * This class extends the MapboxDraw to add custom functionalities like custom buttons
 * to the Draw Bar.
 */
export class ExtendedMapboxDraw extends MapboxDraw {
  private _newButtons: DrawBarButton[]
  private _container?: HTMLElement
  buttonContainer?: any
  private _activeButton?: Element

  parentOnAdd: (map: Map) => HTMLElement

  constructor({ buttons, props }: ConstructorProps) {
    super(props)
    this._newButtons = buttons || []
    this.parentOnAdd = this.onAdd
    // @ts-expect-error the only way to override this method from MapboxDraw. We have to delete and re-add it below
    delete this.onAdd

    // activateUIButton() is defined in mapbox-gl-draw/src/modes/mode_interface_accessors.js
    // it calls _ctx.ui.setActiveButton(name) which looks in its object 'buttonElements'
    // for the element with the passed name as key.
    // it adds Constants.classes.ACTIVE_BUTTON to the buttonElement.classList
    // activateUIButton doesn't work here because custom buttons aren't in _ctx.ui's buttonElements dict

    if (props && props.modes) {
      Object.keys(props.modes).forEach(key => {
        if (props.modes && props.controls) {
          const mode: any = props.modes[key]

          //TODO don't override activateUIButton for modes added as normal/non-custom buttons
          //  not sure how to detect that so hardcoding draw_line_string for now
          if (mode !== MapboxDraw.modes.draw_line_string) {
            mode.activateUIButton = (name: string) => {
              if (debug)
                console.debug(
                  `ExtendedMapboxDraw.mode('${key}').activateUIButton(): called with name '${name}'`
                )
              if (!name) {
                if (this._activeButton) {
                  this._activeButton.classList.remove(
                    MapboxDrawConstants.classes.ACTIVE_BUTTON
                  )
                  this._activeButton = undefined
                }
              }
            }
          }
        }
      })
    }
  }

  // function addButtons() {
  //   const controls = ctx.options.controls;
  //   const controlGroup = document.createElement('div');
  //   controlGroup.className = `${Constants.classes.CONTROL_GROUP} ${Constants.classes.CONTROL_BASE}`;

  //   if (!controls) return controlGroup;

  //   if (controls[Constants.types.LINE]) {
  //     buttonElements[Constants.types.LINE] = createControlButton(Constants.types.LINE, {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_LINE,
  //       title: `LineString tool ${ctx.options.keybindings ? '(l)' : ''}`,
  //       onActivate: () => ctx.events.changeMode(Constants.modes.DRAW_LINE_STRING),
  //       onDeactivate: () => ctx.events.trash()
  //     });
  //   }

  //   if (controls[Constants.types.POLYGON]) {
  //     buttonElements[Constants.types.POLYGON] = createControlButton(Constants.types.POLYGON, {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_POLYGON,
  //       title: `Polygon tool ${ctx.options.keybindings ? '(p)' : ''}`,
  //       onActivate: () => ctx.events.changeMode(Constants.modes.DRAW_POLYGON),
  //       onDeactivate: () => ctx.events.trash()
  //     });
  //   }

  //   if (controls[Constants.types.POINT]) {
  //     buttonElements[Constants.types.POINT] = createControlButton(Constants.types.POINT, {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_POINT,
  //       title: `Marker tool ${ctx.options.keybindings ? '(m)' : ''}`,
  //       onActivate: () => ctx.events.changeMode(Constants.modes.DRAW_POINT),
  //       onDeactivate: () => ctx.events.trash()
  //     });
  //   }

  //   if (controls.trash) {
  //     buttonElements.trash = createControlButton('trash', {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_TRASH,
  //       title: 'Delete',
  //       onActivate: () => {
  //         ctx.events.trash();
  //       }
  //     });
  //   }

  //   if (controls.combine_features) {
  //     buttonElements.combine_features = createControlButton('combineFeatures', {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_COMBINE_FEATURES,
  //       title: 'Combine',
  //       onActivate: () => {
  //         ctx.events.combineFeatures();
  //       }
  //     });
  //   }

  //   if (controls.uncombine_features) {
  //     buttonElements.uncombine_features = createControlButton('uncombineFeatures', {
  //       container: controlGroup,
  //       className: Constants.classes.CONTROL_BUTTON_UNCOMBINE_FEATURES,
  //       title: 'Uncombine',
  //       onActivate: () => {
  //         ctx.events.uncombineFeatures();
  //       }
  //     });
  //   }

  //   return controlGroup;
  // }

  onAdd(map: Map) {
    // the default buttons
    this._container = this.parentOnAdd(map)

    if (debug) console.debug(`onAdd(): _container: `, this._container)

    // adding each new button to Draw Bar
    this._newButtons.forEach(buttonConfig => {
      if (debug) console.debug(`onAdd(): button: `, buttonConfig)
      const elButton = document.createElement('button')
      elButton.className = 'mapbox-gl-draw_ctrl-draw-btn'
      if (buttonConfig.classes) {
        buttonConfig.classes.forEach(buttonClass => {
          elButton.classList.add(buttonClass)
        })
      }
      if (buttonConfig.toolTip) {
        elButton.title = buttonConfig.toolTip
      }
      elButton.appendChild(buttonConfig.content)

      const buttonActionFn = (e: Event) => {
        // called when the toolbar button is clicked

        this._activeButton = undefined

        if (buttonConfig.highlightButton) {
          if (e) {
            const buttonElement = customButtonSupport.findButtonElement(e)
            if (buttonElement) {
              this._activeButton = buttonElement
              customButtonSupport.highlightButton(true, buttonElement)
            }
          }
        }
        if (debug)
          console.debug(
            `ExtendedMapboxDraw.buttonActionFn: _activeButton: `,
            this._activeButton
          )

        // calls draw.changeMode(str)
        buttonConfig.action(e)
      }

      elButton.addEventListener(buttonConfig.on, buttonActionFn)

      this._container?.appendChild(elButton)
    })
    return this._container
  }
}

export const customButtonSupport = {
  findButtonElement(e: Event) {
    if (e?.target instanceof Element) {
      let p: Element | null = e.target

      while (p != null && p.nodeName !== 'BUTTON') {
        p = p.parentElement
      }
      return p
    }
  },

  highlightButton(highlighted: boolean, buttonElement: Element) {
    if (debug)
      console.debug(
        `CustomButtonSupport.highlightButton(): called with param highlighted: ${highlighted}, buttonElement:`,
        buttonElement
      )
    if (!buttonElement) {
      console.error(
        `CustomButtonSupport.highlightButton(): called with null buttonElement`
      )
      return
    }

    if (highlighted) {
      buttonElement.classList.add(MapboxDrawConstants.classes.ACTIVE_BUTTON)
    } else {
      if (debug)
        console.debug(
          `CustomButtonSupport.highlightButton(): removing class ${MapboxDrawConstants.classes.ACTIVE_BUTTON} from buttonElement:`,
          buttonElement
        )
      buttonElement.classList.remove(MapboxDrawConstants.classes.ACTIVE_BUTTON)
    }
  },
  changeMapMousePointer(map: Map, newStyle: string, set = true) {
    if (map) {
      const container = map.getContainer()
      if (container) {
        if (debug)
          console.debug(
            `AddMarkerMode.onSetup(): adding class 'mouse-add' to map container`,
            container
          )
        const className = `mouse-${newStyle}`
        if (set) {
          container.classList.add(className)
        } else {
          container.classList.remove(className)
        }
      }
    }
  },
  toDisplayFeatures(state: any, geojson: any, display: any) {
    return display(geojson)
  },
}
