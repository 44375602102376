import React from 'react'

import { styled } from '@mui/material'

import { INSTANCE_TYPE_MAP } from 'src/modules/app/links'
import { BaseContentList } from 'src/modules/page'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import { CreateMapButton } from '../ui/actionButtons'
import Refresh from '../ui/Refresh'

import { useHomeContentList } from './HomeContentList'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { CreateMapDialog } from '../page/AddMapControl'

const HomeMapList = ({ state }) => {
  const type = INSTANCE_TYPE_MAP

  const {
    content,
    contentCache,
    dispatchFetchHomeContent,
    handleFetchMore,
    handleSetViewConfig,
    loading,
    next,
    sort,
    hierarchical,
    ancestralOnly,
    user,
    fetchHomeContentList,
  } = useHomeContentList({ state, type })

  const actions = content?.results?.length > 0 && (
    <>
      <Actions
        onSetViewConfig={handleSetViewConfig}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        fetchContent={fetchHomeContentList}
        type={type}
        onUploadPhotos={fetchHomeContentList}
      />
    </>
  )

  return (
    <>
      <BaseContentList
        actions={actions}
        {...{
          content,
          contentCache,
          dispatchFetchContent: dispatchFetchHomeContent,
          handleFetchMore,
          loading,
          next,
          results: contentCache,
          type,
          user,
        }}
      />
    </>
  )
}

const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'space-between',
  paddingBottom: '0.5rem',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

export const Actions = ({
  onSetViewConfig,
  sort,
  hierarchical,
  ancestralOnly,
  type,
  fetchContent,
  onUploadPhotos,
}) => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  return (
    <ActionButtons>
      <div>
        <HomeViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={onSetViewConfig}
        />
      </div>
      <div>
        <CreateMapDialog
          onFinishedUploading={() => {
            onUploadPhotos()
          }}
          trigger={props => <CreateMapButton {...props} />}
          treeSlug={authorisedTreeSlug}
        />
        <Refresh onClick={() => fetchContent()} />
      </div>
    </ActionButtons>
  )
}

export default HomeMapList
