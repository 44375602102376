import ViewConfigSelector, {
  FILTER_ALL,
  FILTER_ANCESTRAL_ONLY,
  FILTER_PLACES_LIST_ONLY,
  FILTER_PLACES_SHOW_MAP,
  SORT_ALPHABETICAL,
  SORT_CREATED,
  SORT_CURATED,
  SORT_DATE_TAKEN,
  SORT_DATE_TAKEN_NEWEST,
  SORT_FULL_NAME_ASC,
  SORT_FULL_NAME_DESC,
  SORT_PUBLISHED,
  VIEW_TYPE_FLAT,
  VIEW_TYPE_HIERARCHICAL,
} from '../ui/ViewConfigSelector'
import React from 'react'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_TREE,
} from '../app/links'
import { usePermissions } from '../auth/authHooks'
import { useSelector } from 'react-redux'
import { selectCurrentTree } from '../auth/authSlice'
import { ACTION_EDIT } from '../app/appConstants'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { BLOG_TREE } from '../common/constants'
import { useIsPublic, usePublicTreeSettings, useTreeSettings } from '../app'

export const ViewConfigSelectorContainer = ({
  type,
  sortValue,
  hierarchicalValue,
  ancestralOnlyValue,
  showMapValue,
  handleChange,
  target,
  mapAvailable,
}) => {
  let options = {}
  const isPublic = useIsPublic()
  const tree = useSelector(selectCurrentTree)
  const checkPermissions = usePermissions()
  const publicTreeSettings = usePublicTreeSettings()
  const privateTreeSettings = useTreeSettings()

  const treeSettings = isPublic ? publicTreeSettings : privateTreeSettings

  const permissionAction = ACTION_EDIT
  const permissionParams = {
    instance: tree,
    instanceType: INSTANCE_TYPE_TREE,
  }

  const { hasPermission } = checkPermissions(permissionAction, permissionParams)

  switch (type) {
    case INSTANCE_TYPE_INDIVIDUAL:
      options.sortOptions = [
        SORT_CREATED,
        SORT_FULL_NAME_ASC,
        SORT_FULL_NAME_DESC,
      ]
      break
    case INSTANCE_TYPE_PHOTO_ALBUM:
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    case INSTANCE_TYPE_MEDIA:
      options.sortOptions = [
        SORT_CREATED,
        SORT_DATE_TAKEN,
        SORT_DATE_TAKEN_NEWEST,
        SORT_ALPHABETICAL,
      ]
      break
    case INSTANCE_TYPE_ARTICLE:
    case INSTANCE_TYPE_DOCUMENT:
      if (treeSettings?.isOnePlaceStudyHome()) {
        options.sortOptions = [SORT_PUBLISHED]
      } else {
        options.sortOptions = [SORT_PUBLISHED, SORT_CURATED]
      }
      break
    case INSTANCE_TYPE_LOCATION:
      options.showMapOptions = [FILTER_PLACES_SHOW_MAP, FILTER_PLACES_LIST_ONLY]
      options.heirarchicalOptions = [VIEW_TYPE_FLAT, VIEW_TYPE_HIERARCHICAL]
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    case INSTANCE_TYPE_EVENT:
      options.heirarchicalOptions = [VIEW_TYPE_FLAT, VIEW_TYPE_HIERARCHICAL]
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    case INSTANCE_TYPE_ARTEFACT:
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    default:
      options = {}
  }

  const showFeature = useIsAlphaFeatureUser()?.filterDropDown

  if (!showFeature || tree?.treeType === BLOG_TREE) {
    return null
  }

  if (Object.keys(options).length > 0) {
    return (
      <ViewConfigSelector
        sortValue={sortValue}
        ancestralOnlyValue={ancestralOnlyValue}
        hierarchicalValue={hierarchicalValue}
        showMapValue={showMapValue}
        sortOptions={options.sortOptions}
        hierarchicalOptions={options.heirarchicalOptions}
        ancestralOnlyOptions={isPublic ? [] : options.ancestralOnlyOptions}
        showMapOptions={mapAvailable ? options.showMapOptions : []}
        onChange={handleChange}
        canSetDefalt={hasPermission}
        target={target}
      />
    )
  } else {
    return null
  }
}

export const HomeViewConfigSelectorContainer = ({
  type,
  sortValue,
  hierarchicalValue,
  ancestralOnlyValue,
  showMapValue,
  handleChange,
  mapAvailable,
}) => {
  const isPublic = useIsPublic()
  const checkPermissions = usePermissions()
  const tree = useSelector(selectCurrentTree)

  const permissionAction = ACTION_EDIT
  const permissionParams = {
    instance: tree,
    instanceType: INSTANCE_TYPE_TREE,
  }

  const { hasPermission } = checkPermissions(permissionAction, permissionParams)

  let options = {}

  switch (type) {
    case INSTANCE_TYPE_FAMILY:
      options.sortOptions = [SORT_ALPHABETICAL]
      break
    case INSTANCE_TYPE_INDIVIDUAL:
      // options.ancestralOnlyOptions = [FILTER_ALL, FILTER_ANCESTRAL_ONLY]  << enable this line to show the filter dropdown
      options.sortOptions = [
        SORT_CREATED,
        SORT_FULL_NAME_ASC,
        SORT_FULL_NAME_DESC,
      ]
      break
    case INSTANCE_TYPE_ARTICLE:
    case INSTANCE_TYPE_DOCUMENT:
    case INSTANCE_TYPE_PHOTO_ALBUM:
      options.ancestralOnlyOptions = [FILTER_ALL, FILTER_ANCESTRAL_ONLY]
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    case INSTANCE_TYPE_MEDIA:
      options.ancestralOnlyOptions = [FILTER_ALL, FILTER_ANCESTRAL_ONLY]
      options.sortOptions = [
        SORT_CREATED,
        SORT_DATE_TAKEN,
        SORT_DATE_TAKEN_NEWEST,
        SORT_ALPHABETICAL,
      ]
      break
    case INSTANCE_TYPE_LOCATION:
    case INSTANCE_TYPE_ARTEFACT:
    case INSTANCE_TYPE_EVENT:
      options.ancestralOnlyOptions = [FILTER_ALL, FILTER_ANCESTRAL_ONLY]
      options.heirarchicalOptions = [VIEW_TYPE_FLAT, VIEW_TYPE_HIERARCHICAL]
      options.sortOptions = [SORT_PUBLISHED, SORT_ALPHABETICAL]
      break
    default:
      options = {}
  }

  const showFeature = useIsAlphaFeatureUser()?.filterDropDown

  if (!showFeature || tree?.treeType === BLOG_TREE) {
    return null
  }

  if (Object.keys(options).length > 0) {
    return (
      <ViewConfigSelector
        sortValue={sortValue}
        ancestralOnlyValue={ancestralOnlyValue}
        hierarchicalValue={hierarchicalValue}
        showMapValue={showMapValue}
        sortOptions={options.sortOptions}
        hierarchicalOptions={options.heirarchicalOptions}
        ancestralOnlyOptions={isPublic ? [] : options.ancestralOnlyOptions}
        showMapOptions={mapAvailable ? options.showMapOptions : []}
        onChange={handleChange}
        canSetDefalt={hasPermission}
        target={tree?.id}
      />
    )
  } else {
    return null
  }
}
