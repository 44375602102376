import EventIcon from '@mui/icons-material/Event'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import ArticleIcon from '@mui/icons-material/Article'
import PersonIcon from '@mui/icons-material/Person'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import CategoryIcon from '@mui/icons-material/Category'
import ImageIcon from '@mui/icons-material/Image'
import CollectionsIcon from '@mui/icons-material/Collections'
import HouseIcon from '@mui/icons-material/House'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import MapIcon from '@mui/icons-material/Map'
import TocIcon from '@mui/icons-material/Toc'

import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MAP,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_TREE,
} from '../app/links'

import React from 'react'

const SiteWideIcon = ({ instanceType, className, fontSize, color, sx }) => {
  switch (instanceType) {
    case INSTANCE_TYPE_EVENT:
      return (
        <EventIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_LOCATION:
      return (
        <HouseIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_ARTICLE:
      return (
        <ArticleIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_DOCUMENT:
      return (
        <ArticleIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_MAP:
      return (
        <MapIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_PHOTO_ALBUM:
      return (
        <CollectionsIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_MEDIA:
      return (
        <ImageIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_ARTEFACT:
      return (
        <MilitaryTechIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_INDIVIDUAL:
      return (
        <PersonIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_FAMILY:
      return (
        <FamilyRestroomIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case INSTANCE_TYPE_TREE:
      return (
        <AccountTreeOutlinedIcon
          className={className}
          fontSize={fontSize}
          color={color}
        />
      )
    case 'category':
      return (
        <CategoryIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    case 'TOC':
      return (
        <TocIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
    default:
      return (
        <CategoryIcon
          className={className}
          fontSize={fontSize}
          color={color}
          sx={sx}
        />
      )
  }
}

export default SiteWideIcon
