import React, { useRef, useState } from 'react'
import {
  MEDIA_ROW_ALIGNMENT_COVER,
  MEDIA_ROW_HEIGHT_LARGE,
  MEDIA_ROW_HEIGHT_MAP,
} from './contentBlockConstants'
import { Box, Dialog, Typography } from '@mui/material'
import { useActionDispatcher } from '../app'
import { updateContentBlock } from './writeArticleSlice'
import { isNull } from 'lodash'
import SiteWideIcon from '../ui/SiteWideIcon'
import { INSTANCE_TYPE_MAP } from '../app/links'
import Map from '../map/Map'
import { DisplayCaption } from './EditablePhoto'
import { useLocation } from 'react-router-dom'
import { useIsAlphaFeatureUser } from '../auth/hooks'

export const MapContainer = ({ children, onClick, rowHeight }) => (
  <Box
    onClick={onClick}
    sx={{
      height: rowHeight,
      width: '100%',
      cursor: 'pointer',
      position: 'relative',
    }}
  >
    {children}
  </Box>
)

const AddMapIcon = ({ onClick, width, height, label = 'Create map' }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height,
        backgroundImage: 'url(/map-placeholder.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        sx={{
          width,
          height,
          cursor: 'pointer',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        <SiteWideIcon
          color="primary"
          instanceType={INSTANCE_TYPE_MAP}
          fontSize={'large'}
        />
        <Typography variant="subtitle5" px={2} mt={1} textAlign="center">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

const MapBlock = ({
  contentId,
  id,
  mediaRowAlignment = MEDIA_ROW_ALIGNMENT_COVER,
  mediaRowMeta,
  mapBlock,
  isPublic,
}) => {
  const debug = false

  const containerRef = useRef()
  const location = useLocation()
  const [mapEditorOpen, setMapEditorOpen] = useState(false)
  const allowEditMap = useIsAlphaFeatureUser()?.maps
  const [mapSaveCount, setMapSaveCount] = useState(0)

  const [currentMap, setCurrentMap] = useState(
    mapBlock ? structuredClone(mapBlock) : null
  )
  //const currentMap = mapBlock ? structuredClone(mapBlock) : {}
  const isEditing = location?.pathname?.includes('write-article')

  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)

  const openMapEditor = () => {
    if (allowEditMap || !isEditing) setMapEditorOpen(true)
  }

  const closeMapEditor = () => {
    setMapEditorOpen(false)
  }

  // MapSidePanel calls mapSlice.createMap() or mapSlice.updateMap()
  // this is only called when a new Map is created so that it is associated
  // with its article
  const setMapBlockInArticle = async updatedMapBlock => {
    if (debug)
      console.debug(
        `writeArticle/MapBlock.setMapBlockInArticle(): called with updatedMapBlock:`,
        updatedMapBlock
      )
    if (!mapBlock) {
      // new map block created
      await dispatchUpdateContentBlock({
        contentId,
        id,
        setMapBlock: updatedMapBlock?.id,
      })
    }

    closeMapEditor()
  }

  const rowHeight = MEDIA_ROW_HEIGHT_MAP[MEDIA_ROW_HEIGHT_LARGE] || mediaRowMeta
  const mapIsEmpty = isNull(currentMap)

  console.debug(`writeArticle/MapBlock.render(): currentMap:`, currentMap)
  return (
    <Box ref={containerRef}>
      <Dialog
        open={mapEditorOpen}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: 'black',
          },
        }}
      >
        <Map
          initialIsEditing={isEditing}
          onSave={(updatedMap, onClose) => {
            //MapBlock will have been saved by MapSidePanel.saveMap() or createNewMap()
            // if this is a new MapBlock it needs to be associated with the article
            setMapBlockInArticle(updatedMap)
            //don't need to call onClose as setMapBlockInArticle closes the dialog this Map is in
            setCurrentMap(updatedMap)

            //this is a hack to force a re-render of the inline Map component below
            setMapSaveCount(mapSaveCount + 1)
          }}
          closeMap={closeMapEditor}
          currentMap={currentMap}
          initialMapIsFullWindow={true}
          addSearchBoxMapControl={!isPublic}
          initialInteractive={true}
          allowEditButton={false}
        />
      </Dialog>
      {mapIsEmpty ? (
        isEditing ? (
          <>
            <AddMapIcon
              onClick={openMapEditor}
              height={rowHeight}
              width={'100%'}
            />
            <div></div>
          </>
        ) : null
      ) : (
        <MapContainer onClick={openMapEditor} rowHeight={rowHeight}>
          <Map
            key={`inlinemap_${mapSaveCount}`} // this key forces a re-render of this inline Map component after the other copy of the map has been saved
            currentMap={currentMap}
            inlineMapHeight={rowHeight}
            allowThreeD={false}
            allowEditButton={false}
          />
        </MapContainer>
      )}
      {mapBlock?.title && (
        <Box py={2}>
          <DisplayCaption photo={{ ...mapBlock, caption: mapBlock?.title }} />
        </Box>
      )}
    </Box>
  )
}

export default MapBlock
