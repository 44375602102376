import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'

import {
  selectHomeFamilies,
  selectHomeIndividualsSearchTerm,
  setHomeViewConfig,
  setHomeIndividualsSearchTerm,
  fetchHomeFamilies,
} from './homeSlice'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../common/viewConfigUtils'
import { INSTANCE_TYPE_FAMILY, INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import { Box } from '@mui/material'
import { ActionsContainer } from '../page/LinkedPageList'
import { UpdateIndividualRelationshipsListDialog } from '../viewer/UpdateIndividualRelationships'
import { AddIndividualOnlyDialog } from '../viewer/ChooseOrAddIndividual'
import { ACTION_EDIT_TREE_INDIVIDUALS } from '../app/appConstants'
import { selectIndividualById } from '../viewer/viewerSlice'
import SiteWideIcon from '../ui/SiteWideIcon'
import Button from '../ui/Button'
import Refresh from '../ui/Refresh'
import Typography from '@mui/material/Typography'
import IndividualSearchBox from '../ui/IndividualSearchBox'
import BaseFamilyList from '../page/BaseFamilyList'

const HomeFamilies = () => {
  const [selectedIndividualId, setSelectedIndividualId] = useState(null)
  var individual = useSelector(selectIndividualById(selectedIndividualId))
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatch = useDispatch()
  const dispatchFetchHomeFamilies = useActionDispatcher(fetchHomeFamilies)
  const homeFamilies = useSelector(selectHomeFamilies)
  const { next, results, updated } = homeFamilies
  const type = INSTANCE_TYPE_FAMILY

  const makeArgs = useCallback(
    page => {
      const args = { page, type, viewConfigQueryParams }
      return args
    },
    [type, viewConfigQueryParams]
  )

  useEffect(() => {
    const fetchHomeFamilies = async () => {
      await dispatchFetchHomeFamilies(makeArgs(0))
    }

    if (!results.length) {
      fetchHomeFamilies()
    }
  }, [dispatchFetchHomeFamilies, makeArgs, type, results.length, updated])

  const refresh = useCallback(() => {
    dispatchFetchHomeFamilies(makeArgs(0, null))
  }, [makeArgs, dispatchFetchHomeFamilies])

  const handleFetchMore = () => {
    if (dispatchFetchHomeFamilies.status === 'loading') {
      return
    }
    dispatchFetchHomeFamilies(makeArgs(homeFamilies.page + 1))
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setHomeViewConfig({ type, viewConfig }))
    dispatchFetchHomeFamilies(makeArgs(0))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    homeFamilies,
    viewConfigQueryParams
  )

  const search = useCallback(() => {
    dispatchFetchHomeFamilies(makeArgs(0))
  }, [makeArgs, dispatchFetchHomeFamilies])

  return (
    <>
      <ActionsContainer>
        <HomeViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={handleSetViewConfig}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <IndividualSearchBox
            searchTermAction={setHomeIndividualsSearchTerm}
            selector={selectHomeIndividualsSearchTerm}
            onChange={search}
            onClear={refresh}
            busy={dispatchFetchHomeFamilies.status === 'loading'}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <AddIndividualOnlyDialog
            onIndividualAdded={individual =>
              setSelectedIndividualId(individual?.id)
            }
            trigger={({ onClick, ...props }) => (
              <Button
                permissionAction={ACTION_EDIT_TREE_INDIVIDUALS}
                endIcon={
                  <SiteWideIcon
                    instanceType={INSTANCE_TYPE_INDIVIDUAL}
                    fontSize={'large'}
                  />
                }
                color="primary"
                {...props}
                onClick={onClick}
              >
                Add Individual
              </Button>
            )}
          />
          <Refresh onClick={refresh} />
        </Box>
      </ActionsContainer>
      <BaseFamilyList
        {...{
          dispatchFetchFamilies: dispatchFetchHomeFamilies,
          next,
          handleFetchMore,
          families: homeFamilies,
          results,
          type,
        }}
      />
      <UpdateIndividualRelationshipsListDialog
        individual={individual}
        isLoadingOveride={true}
        onCloseParentDialog={() => setSelectedIndividualId(null)}
      />
    </>
  )
}

export default HomeFamilies
