import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchLinkedPhotos,
  selectLinkedPhotos,
} from 'src/modules/page/pageSlice'
import { MediaList } from 'src/modules/photo'
import { INSTANCE_TYPE_MEDIA } from '../app/links'
import { getViewConfig } from '../common/viewConfigUtils'
import { ViewConfigSelectorContainer } from './ViewConfigSelectorContainer'
import { selectLinkedPageItem, setViewConfig } from './pageSlice'
import AddMediaToPageDialog from './AddMediaToPageDialog'
import { Box } from '@mui/system'
import { selectIndividualById } from '../viewer/viewerSlice'
import { getIndividualTreeTitle, posessive } from '../ui/individualUtils'
import Refresh from '../ui/Refresh'

const PagePhotoList = ({ target }) => {
  const dispatch = useDispatch()
  const dispatchFetchLinkedPhotos = useActionDispatcher(fetchLinkedPhotos)
  const media = useSelector(selectLinkedPhotos)
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const individual = useSelector(selectIndividualById(target))

  const getPageTitle = () => {
    if (individual) {
      return getIndividualTreeTitle(individual)
    } else {
      return posessive(fetchedLinkedPage.title)
    }
  }

  const title = getPageTitle()

  useEffect(() => {
    if (dispatchFetchLinkedPhotos.status === 'loading') {
      return
    }
    if (!media?.results.length) {
      dispatchFetchLinkedPhotos({ target, page: 0 })
    }
  }, [dispatchFetchLinkedPhotos, target, media?.results.length])

  const onFetchMore = () => {
    if (dispatchFetchLinkedPhotos.status === 'loading') {
      return
    }
    dispatchFetchLinkedPhotos({ target, page: media.page + 1 })
  }

  const refreshMedia = () => {
    dispatchFetchLinkedPhotos({ target, page: 0 })
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setViewConfig({ type: INSTANCE_TYPE_MEDIA, viewConfig }))
    dispatchFetchLinkedPhotos({ target, page: 0 })
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(media)

  return (
    <>
      {media?.results?.length > 0 && (
        <Box Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ViewConfigSelectorContainer
            type={INSTANCE_TYPE_MEDIA}
            sortValue={sort}
            hierarchicalValue={hierarchical}
            ancestralOnlyValue={ancestralOnly}
            handleChange={handleSetViewConfig}
            target={target}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AddMediaToPageDialog
              shouldTagImages={true}
              onFinishedUploading={refreshMedia}
              targets={[target]}
              pageTitle={title}
            />
            <Refresh onClick={refreshMedia} />
          </Box>
        </Box>
      )}
      <MediaList
        onFetchMore={onFetchMore}
        media={media}
        status={dispatchFetchLinkedPhotos.status}
        refreshMedia={refreshMedia}
      />
    </>
  )
}

export default PagePhotoList
