import { Autocomplete, TextField } from '@mui/material'
import React, { useState } from 'react'
import {
  formatIndividualName,
  formatIndividualTagsAndSearch,
} from '../ui/individualUtils'
import { matchSorter } from 'match-sorter'
import { INDIVIDUAL_SEARCH_KEYS } from '../ui/individualUtils'

export const fuzzyFilterOptions = (options, { inputValue }) => {
  return matchSorter(options, inputValue, {
    keys: INDIVIDUAL_SEARCH_KEYS,
  })
}

export const SelectOthers = ({
  otherUsers = [],
  value,
  setValue,
  dropdownClassName,
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const filteredOptions = otherUsers.filter(o => o.id !== value)
  const noOptionsText =
    inputValue === '' ? 'Type to search...' : 'No matches found'

  return (
    <div className={dropdownClassName}>
      <Autocomplete
        disableClearable
        autoComplete
        freeSolo
        filterOptions={fuzzyFilterOptions}
        getOptionLabel={option => formatIndividualName(option)}
        inputValue={inputValue}
        multiple={true}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={filteredOptions}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {formatIndividualTagsAndSearch(option)}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label="Type to search people in archive..."
            fullWidth
          />
        )}
        renderTags={(tagValue, getTagProps) => {}}
        value={value}
      />
    </div>
  )
}

export default SelectOthers
