import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  fetchLinkedPageItem,
  resetPageToInitialState,
  selectLinkedPageArticle,
  selectLinkedPageItem,
  setLinkedPageArticle,
} from './pageSlice'
import PinnedArticle, {
  PINNED_ARTICLE_DISPLAY_CONFIG,
  PinnedArticleButtons,
} from './PinnedArticle'

import { Box } from '@mui/material'
import { selectIndividualById } from '../viewer/viewerSlice'
import { isUndefined } from 'lodash'
import { useActionDispatcher } from '../app'

import Map from '../map/Map'

const LINKED_PINNED_ARTICLE_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeaderAuthor: false,
  showInLineHeaderShare: false,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: true,
  showComments: true,
}

// an item's welcome page
const LinkedPinnedArticle = ({ pageType }) => {
  const { linkedPageId } = useParams()
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchLinkedPageItem)
  const cachedPinnedArticleData = useSelector(selectLinkedPageArticle)

  // For creation of entities linked to individuals, preselect the
  // family as well:
  const targets = [linkedPageId]
  const individual = useSelector(selectIndividualById(linkedPageId))
  if (!isUndefined(individual)) {
    targets.push(individual.family)
  }

  useEffect(() => {
    const actionParams = {
      pageType: pageType,
      linkedPageId: linkedPageId,
    }

    if (
      !cachedPinnedArticleData.articleId &&
      cachedPinnedArticleData?.loading
    ) {
      dispatchFetchLinkedPageItem(actionParams)
    }
  }, [
    dispatchFetchLinkedPageItem,
    cachedPinnedArticleData.articleId,
    cachedPinnedArticleData?.loading,
    pageType,
    linkedPageId,
  ])

  const [currentMap, setCurrentMap] = useState(null)

  const buildMapConfiguration = item => {
    // console.debug(
    //   `LinkedPinnedArticle.buildMapConfiguration(): called with item:`,
    //   item
    // )
    if (item && item.address) {
      const mapParams = { title: `Map showing ${item.title}` }

      mapParams.mapLinks = [
        {
          id: item.id,
          instanceType: 'address',
          title: item.title,
          target: {
            id: item.address.id,
            //latiGed: item.address.latiGed,
            //longGed: item.address.longGed,
            lati: item.address.lati,
            long: item.address.long,
            photo: item.photo?.fileThumbnail,
            freeText: item.address.freeText,
          },
        },
      ]

      // console.debug(
      //   `LinkedPinnedArticle.buildMapConfiguration(): setting currentMap to:`,
      //   mapParams
      // )

      setCurrentMap(mapParams)
    } else {
      setCurrentMap(null)
    }
  }

  useEffect(() => buildMapConfiguration(fetchedLinkedPage), [fetchedLinkedPage])

  return (
    <Box>
      {currentMap && (
        <Box
          // a spacer the map can appear in
          style={{
            width: '100%',
            //height: '400px',
            border: 'solid 1px',
            display: 'flex', // this and below just to center the 'Waiting for places with addresses' text
            textAlign: 'center',
            flexDirection: 'column',
          }}
          sx={{ mb: 4 }}
        >
          <Box>
            <Map
              currentMap={currentMap}
              clickableMarkers={false} // only 1 marker so disable marker info panel
              inlineMapHeight={400}
              //singleCoordZoomLevel={14}
              markerInfoPanelEnabled={true} // only 1 marker but it's nice to know what it is
              initialInteractive={false}
              preferAddressAsMarkerPopup={true}
              fullwindowOnMapClick={true}
              interactiveWhenFullWindow={true}
              allowThreeD={false}
              allowThreeDWhenFullWindow={true}
              allowEditButton={false}
              //treeSlug={treeSlug}
            />
          </Box>
        </Box>
      )}

      <PinnedArticle
        cachedPinnedArticleData={cachedPinnedArticleData}
        onArticleLoaded={setLinkedPageArticle}
        config={LINKED_PINNED_ARTICLE_CONFIG}
        pinTarget={fetchedLinkedPage?.id}
      >
        <>
          <>
            <PinnedArticleButtons
              createButtonText={'Create Description Article'}
              pinButtonText={'Pin an Existing Article'}
              pinTarget={fetchedLinkedPage}
              pinTargetType={pageType}
              onArticleChanged={resetPageToInitialState}
              presetTargets={targets}
              onArticleLoaded={setLinkedPageArticle}
            />
          </>
        </>
      </PinnedArticle>
    </Box>
  )
}

export default LinkedPinnedArticle
