import slugify from 'slugify'
import { EmptyStateWithIcon } from '../ui/EmptyFeed'
import Typography from '../ui/Typography'
import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const heightOfFixedNavBar = 85
const heightOfTabBar = 40

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const TOCBlock = ({
  idx,
  contentBlocks = [],
  isEditMode = false,
  loading = false,
  config = {},
}) => {
  useScrollToHash(loading, config)

  const handleLinkClick = (event, id) => {
    event.preventDefault()
    const element = document.getElementById(id)
    if (element) {
      let offset = heightOfFixedNavBar // Height of the fixed menu bar
      if (config.fullScrollOffset) {
        offset = offset + heightOfTabBar
      }
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const headings = contentBlocks
    .slice(idx + 1)
    .reduce((acc, block, blockIndex) => {
      if (block.textContent) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(block.textContent, 'text/html')
        const h4Elements = Array.from(doc.getElementsByTagName('h4'))
        return [
          ...acc,
          ...h4Elements.map((el, elIndex) => ({
            text: el.textContent,
            id: slugify(el.textContent, { lower: true, strict: true }),
          })),
        ]
      }
      return acc
    }, [])

  if (loading) {
    return null
  }

  return (
    <div>
      {headings.length > 0 ? (
        <>
          <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
            Contents
          </Typography>
          <div style={{ marginLeft: '1rem' }}>
            {headings.map((heading, index) => (
              <div key={index}>
                <StyledLink
                  to={`#${heading.id}`}
                  onClick={e => handleLinkClick(e, heading.id)}
                >
                  <div>{heading.text}</div>
                </StyledLink>
              </div>
            ))}
          </div>
        </>
      ) : isEditMode ? (
        <EmptyStateWithIcon
          instanceType="TOC"
          title={'Table of Contents'}
          bodyText={
            'To populate the table of contents, format some text in one of the blocks below with "Title"'
          }
        />
      ) : null}
    </div>
  )
}

function useScrollToHash(loading, config = {}) {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash && !loading) {
      const element = document.getElementById(hash.replace('#', ''))
      if (element) {
        let offset = heightOfFixedNavBar // Height of the fixed menu bar
        if (config.fullScrollOffset) {
          offset = offset + heightOfTabBar
        }
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [hash, loading, config.fullScrollOffset])
}

export default TOCBlock
