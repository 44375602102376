import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, styled } from '@mui/material'

import { LoadingIndicator } from 'src/modules/ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import FamilyCard from './FamilyCard'

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(-1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

const BaseFamilyList = ({
  dispatchFetchFamilies,
  next,
  handleFetchMore,
  families,
  results,
}) => {
  return (
    <Box>
      {dispatchFetchFamilies.status === 'loading' &&
      families.page === 0 &&
      results.length === 0 ? (
        <LoadingIndicator />
      ) : results.length === 0 && dispatchFetchFamilies.status !== 'loading' ? (
        <EmptyFeed type={INSTANCE_TYPE_INDIVIDUAL} />
      ) : (
        <StyledInfiniteScroll
          dataLength={results.length}
          next={handleFetchMore}
          hasMore={next}
        >
          {results.map(family => (
            <FamilyCard family={family} />
          ))}
        </StyledInfiniteScroll>
      )}
      {dispatchFetchFamilies.status === 'loading' && families.page > 0 && (
        <LoadingIndicator />
      )}
    </Box>
  )
}

export default BaseFamilyList
