import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

const Link = ({ to, disabled = false, children, ...props }) => {
  return (
    <>
      {to ? (
        <MuiLink
          component={RouterLink}
          to={`${to}`}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          children={children}
          {...props}
        />
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Link
