import { ImageSourceSpecification, Map } from 'mapbox-gl'

import {
  getImageSourceId,
  getImageLayerId,
  FourCoordinates,
} from './MapImageHelpers'

export interface MapImageHookResponse {
  addImageToGlobe: CallableFunction
}

const INITIAL_IMAGE_OPACITY = 0.5

/**
 * Hook that does nothing other than return function addImageToGlobe()
 * Contains no state or refs
 *
 * @returns
 */
export function useMapImage(): MapImageHookResponse {
  // BE CAREFUL WITH ANY STATE OR REFS HERE as useMapImage() is called every time Map re-renders

  /**
   * Adds an image as a MapBox source with the given coordinates and adds a layer using it.
   *
   */
  const addImageToGlobe = (
    mapinst: Map,
    imageId: string,
    url: string,
    rotatedImageBorderCoords: FourCoordinates // getRotatedImageWithLevelBoundaryBoxCoords calculates
  ) => {
    const debug = false
    if (debug) {
      console.debug(`useMapImage.addImageToGlobe(): called with url: ${url}`)

      console.debug(
        `useMapImage.addImageToGlobe(): called with rotatedImageBorderCoords`,
        rotatedImageBorderCoords
      )
    }
    if (!rotatedImageBorderCoords) {
      console.error(
        `useMapImage.addImageToGlobe(): called with no georeferenced coordinates`
      )
      return
    }

    if (rotatedImageBorderCoords.length !== 4) {
      console.error(
        `useMapImage.addImageToGlobe(): rotatedImageBorderCoords must be an array of 4 elements`,
        rotatedImageBorderCoords
      )
      return
    }

    const source: ImageSourceSpecification = {
      type: 'image',
      url: url,
      coordinates: rotatedImageBorderCoords, // must be 4 coords
      //not allowed... data: { bearing: rotateDegrees },
    }

    const imageSourceId = getImageSourceId(imageId)
    mapinst.addSource(imageSourceId, source)

    const imageLayerId = getImageLayerId(imageId)

    // place new layer before MapboxDraw layer if it exists
    const drawLayer = mapinst.getLayer('gl-draw-polygon-fill-inactive.cold')
    if (debug) {
      if (drawLayer) {
        console.debug(
          `addImageToGlobe(): MapboxDraw layer exists, adding new image layer before it`
        )
      } else {
        console.debug(
          `addImageToGlobe(): MapboxDraw layer does not exist adding new image layer to the top of the layer stack`
        )
      }
    }

    mapinst.addLayer(
      {
        id: imageLayerId,
        type: 'raster',
        source: imageSourceId,
        paint: {
          'raster-fade-duration': 0,
        },
        metadata: {},
      },
      drawLayer ? 'gl-draw-polygon-fill-inactive.cold' : undefined
    )

    mapinst.setPaintProperty(
      imageLayerId,
      'raster-opacity',
      INITIAL_IMAGE_OPACITY
    )

    // TODO add alt-mousewheel event to change image opacity in non-edit-mode

    if (debug)
      console.debug(
        `addImageToGlobe(): returning imageSourceId: '${imageSourceId}', imageLayerId: '${imageLayerId}'`
      )

    return [imageSourceId, imageLayerId]
  } // end of addImageToGlobe()

  return { addImageToGlobe: addImageToGlobe }
} // end of hook
