import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { PhotoAlbum as Gallery } from 'react-photo-album'

import { LoadingIndicator } from 'src/modules/ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import MediaNavigatorDialog from 'src/modules/photo/MediaNavigator'
import RefreshDialog from '../ui/RefreshDialog'
import { makeStyles } from '@mui/styles'
import { PhotoWithTitleV2 } from './PhotoGallery'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    '& .hover-overlay': {
      opacity: 0,
      transition: '0.3s opacity',
    },
    '&:hover .hover-overlay': {
      opacity: 1,
    },
    padding: '0 2px',
  },
}))

const InnerGallery = React.memo(({ results, handleClick, resultSetIdx }) => {
  const classes = useStyles()

  const photos = results.map(
    (
      {
        fileMedium,
        fileThumbnail,
        mediumImageHeight,
        mediumImageWidth,
        title,
        type,
      },
      index
    ) => ({
      src: fileMedium,
      height: mediumImageHeight ? mediumImageHeight : 100,
      width: mediumImageWidth ? mediumImageWidth : 100,
      title: title,
      className: classes.root,
      type,
      onClick: () => handleClick(resultSetIdx, index),
    })
  )

  return (
    <div style={{ paddingBottom: '10px' }}>
      <Gallery
        layout="rows"
        photos={photos}
        rowConstraints={{ maxPhotos: 4 }}
        spacing={'10'}
        renderPhoto={PhotoWithTitleV2}
        onClick={({ index }) => handleClick(resultSetIdx, index)}
      />
    </div>
  )
})

const MediaList = ({ onFetchMore, media, status, refreshMedia }) => {
  const [key, setKey] = useState(Date.now())
  const [triggerReload, setTriggerReload] = useState(false)
  const [resultSets, setResultSets] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [indexToSelectAfterFetchMore, setIndexToSelectAfterFetchMore] =
    useState(-1)

  const { count, next, page, results } = media

  useEffect(() => {
    const currentResults = resultSets.flat()
    const newResults = results.slice(currentResults.length)

    if (
      currentResults.length &&
      results.length &&
      currentResults[0].fileMedium !== results[0].fileMedium
    ) {
      // at least one image was added
      // kill current gallery components and start again by updating key
      setResultSets([results])
      setKey(Date.now())
    } else if (newResults.length) {
      // append any new results to the end
      setResultSets([...resultSets, newResults])
    } else if (results.length === currentResults.length) {
      // A photo has updated, probably because of crop/rotation. Replace
      // all state with new state.
      const newResults = resultSets.reduce((resultSets, resultSet) => {
        const start = resultSets.reduce((count, set) => count + set.length, 0)
        const newSet = results.slice(start, start + resultSet.length)
        return [...resultSets, newSet]
      }, [])
      setResultSets(newResults)
    }
    // complains about resultSets needed as a dep, but we're
    // setting it within the body of this function so would
    // just result in unnecessary runs for no benefit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const currentPhotoIndex = results.indexOf(selectedPhoto)
  const lastPhotoIndex = count - 1

  const canRequestPrev = currentPhotoIndex > 0
  const canRequestNext = currentPhotoIndex < lastPhotoIndex

  const handleRequestPrevPhoto = () => {
    setSelectedPhoto(results[currentPhotoIndex - 1])
  }

  const handleDelete = instances => {
    const treeIndividual = instances.some(str =>
      str.includes('tree individual')
    )
    if (treeIndividual) {
      setTriggerReload(true)
    } else {
      const newResults = resultSets[0].filter(
        photo => photo.id !== selectedPhoto.id
      )
      setResultSets([newResults])
    }
  }

  const handleRequestNextPhoto = () => {
    const nextPhotoIndex = currentPhotoIndex + 1
    const nextPhoto = results[nextPhotoIndex]
    if (nextPhoto) {
      setSelectedPhoto(nextPhoto)
    } else {
      // Desired photo is not yet fetched
      onFetchMore()
      // Save the desired index to be selected once available.
      setIndexToSelectAfterFetchMore(nextPhotoIndex)
    }
  }
  if (indexToSelectAfterFetchMore !== -1) {
    // Check whether it is now possible to select the desired photo
    const photoIfPresent = results[indexToSelectAfterFetchMore]
    if (photoIfPresent) {
      setSelectedPhoto(photoIfPresent)
      setIndexToSelectAfterFetchMore(-1)
    }
  }

  const handleClick = (setIdx, idx) => {
    // setIdx is the index of the result set
    // idx is the index of the photo within that resultsSet
    setSelectedPhoto(resultSets[setIdx][idx])
  }

  const maxWidth =
    results.length === 2 ? 800 : results.length === 1 ? 400 : 'auto'

  return (
    <>
      <MediaNavigatorDialog
        photo={selectedPhoto}
        onReportClosed={() => setSelectedPhoto(null)}
        onRequestNext={handleRequestNextPhoto}
        onRequestPrev={handleRequestPrevPhoto}
        canRequestNext={canRequestNext}
        canRequestPrev={canRequestPrev}
        handleDelete={handleDelete}
        refreshMedia={refreshMedia}
      />
      {status === 'loading' && page === 0 && results.length === 0 ? (
        <LoadingIndicator />
      ) : results.length === 0 && status !== 'loading' ? (
        <EmptyFeed type={INSTANCE_TYPE_MEDIA} />
      ) : (
        <InfiniteScroll
          dataLength={results.length}
          next={onFetchMore}
          hasMore={next}
          loader={<LoadingIndicator />}
          style={{ overflowY: 'hidden', maxWidth }}
          key={key}
        >
          {resultSets.map((resultSet, idx) => (
            <InnerGallery
              results={resultSet}
              handleClick={handleClick}
              key={`${key}-${idx}`}
              resultSetIdx={idx}
            />
          ))}
        </InfiniteScroll>
      )}
      <RefreshDialog refreshModalOpen={triggerReload} />
    </>
  )
}

export default MediaList
