import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import enGB from 'date-fns/locale/en-GB'
import * as Sentry from '@sentry/react'
import { Switch, Route, Redirect, Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Provider } from 'react-redux'

import { Auth } from './modules/auth/'
import App from './modules/app/App'
import Public from './modules/public/Public'
import theme from './modules/ui/theme'
import createStore from './createStore'
import config from './config'
import { initAnalytics, sendPageview } from './modules/analytics/AnalyticsUtils'
import { getExportTokenIfPresent } from './modules/auth/awsConfig'
import './index.css'
import HtmlHeader from './modules/app/HtmlHeader'
import { HelmetProvider } from 'react-helmet-async'
import { PUBLIC_BLOG, PUBLIC_ROOT, PUBLIC_SHARE } from './modules/app/links'

import { PrismicProvider } from '@prismicio/react'
import { client } from './modules/prismic/prismic'
import { ErrorBoundary } from '@sentry/react'
import FallBackComponent from './FallBackComponent'
import '@fontsource/ibm-plex-sans'
import Share from './modules/public/Share'
import PublicBlog from './modules/public/PublicBlog'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createRoot } from 'react-dom/client'
import Authprovider from './modules/auth/Authprovider'
import 'mapbox-gl/dist/mapbox-gl.css'

export const { store, history } = createStore()

const isExportMode = !!getExportTokenIfPresent()

if (!isExportMode) {
  if (config.sentry.dsn) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.sentry.environment,
      normalizeDepth: 3,
    })
  }

  if (config.googleAnalyticsTracking.key !== '') {
    initAnalytics(config)

    // Google Analytics 4 has 'enhanced event measurement' which automatically captures a page_view event when the
    // 'website changes the browser history state' - i.e. window location is modified via history. So don't add our
    // own location listener or views will be double-sent.
    // https://support.google.com/analytics/answer/9216061#page_view
    // history.listen(location => {
    //   sendPageview(location.pathname)
    // })

    // BUT, despite 'capture a page view event each time a page loads' being set, a page_view event is NOT sent
    // for the initial page load that loaded this index.js and ran this code.
    // So send one manually.
    sendPageview(history.location.pathname)
  } else {
    console.debug(
      `index.js: not initialising Google Analytics because config.googleAnalyticsTracking.key not set.`
    )
  }
}

const headerProps = {
  title:
    'WeAre.xyz - We Are [showcasing your family history like never before]',
  type: 'website',
  url: window.location.origin,
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <ErrorBoundary
    fallback={props => <FallBackComponent {...props} />}
    showDialog
  >
    <CssBaseline />
    <Provider store={store}>
      <HelmetProvider>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
              <SnackbarProvider>
                <PrismicProvider client={client}>
                  <Switch>
                    <Route path={`/${PUBLIC_ROOT}/:slug`} component={Public} />
                    <Route path={`/${PUBLIC_SHARE}/:slug`} component={Share} />
                    <Route
                      path={`/${PUBLIC_BLOG}/:slug`}
                      component={PublicBlog}
                    />
                    <Route
                      path={'/prerender/og/:anything'}
                      render={({ location }) => (
                        <Redirect
                          to={window.location.pathname.replace(
                            /^\/prerender\/og/,
                            ''
                          )}
                        />
                      )}
                    />
                    <Route>
                      <HtmlHeader {...headerProps} />
                      <Authprovider>
                        <Auth>
                          <App />
                        </Auth>
                      </Authprovider>
                    </Route>
                  </Switch>
                </PrismicProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </Router>
      </HelmetProvider>
    </Provider>
  </ErrorBoundary>
  // </React.StrictMode>
)
