import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectPublicIndividualById } from 'src/modules/public/tree/treeSlice'
import { PublicContext } from '../contexts'
import { Container } from 'src/modules/ui'

import {
  formatIndividualDates,
  formatIndividualName,
  formatIndividualWithDates,
} from 'src/modules/ui/individualUtils'
import {
  usePublicPageUrlSegments,
  usePublicSelectRelatedFamilies,
} from './hooks'

import BasePublicPageWithTree from './BasePublicPageWithTree'
import HtmlHeader from '../../app/HtmlHeader'

import { selectIndividualForPage, fetchIndividual } from '../tree/treeSlice'
import { usePrerender } from '../hooks'
import { resetPublicPageState } from './pageSlice'
import { getIndividualTreeTitle } from '../../ui/individualUtils'

const PublicIndividualPage = () => {
  const dispatch = useDispatch()
  const { linkedPageId } = usePublicPageUrlSegments()
  const { treeSlug, treeLoading } = useContext(PublicContext)

  var individual = useSelector(selectPublicIndividualById(linkedPageId))
  const individualForPage = useSelector(selectIndividualForPage)
  const [fetchedIndividual, setFetchedIndividual] = useState(false)

  const pageLoading =
    individual === undefined && individualForPage === undefined

  usePrerender(pageLoading === false)

  individual = individual || individualForPage || {}

  const { alias, knownAs, givenName, prefix, suffix, photo } = individual

  const families = usePublicSelectRelatedFamilies(individual)

  useEffect(() => {
    dispatch(resetPublicPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    //load the individual anyway to get the view defaults
    const doFetchIndividual = async () => {
      await dispatch(
        fetchIndividual({ treeSlug: treeSlug, individualId: linkedPageId })
      )
      setFetchedIndividual(true)
    }
    if (!fetchedIndividual && !treeLoading) {
      doFetchIndividual()
    }
  }, [
    dispatch,
    individualForPage,
    linkedPageId,
    treeSlug,
    treeLoading,
    setFetchedIndividual,
    fetchedIndividual,
  ])

  const headerProps = {
    title: formatIndividualWithDates({
      ...individual,
      shortenGivenName: false,
    }),
    type: 'profile',
    image: photo,
    url: window.location.href,
    first_name: individual?.knownAs || individual?.givenName,
    last_name: individual?.surname,
    structuredDataType: 'Person',
  }

  let title = ''
  let subtitle = ''

  if (givenName || knownAs || alias) {
    title = formatIndividualName({ ...individual })
    const givenNameLength = givenName.split(' ').length

    if (
      (knownAs === givenName && !prefix && !suffix && !alias) ||
      (!knownAs && !alias && givenNameLength === 1)
    ) {
      subtitle = formatIndividualDates(individual)
    } else {
      subtitle = formatIndividualWithDates(individual)
    }
  }

  return (
    <>
      {pageLoading ? (
        <Container>Loading ..</Container>
      ) : (
        <>
          <HtmlHeader {...headerProps} />
          <BasePublicPageWithTree
            photo={photo && photo.fileThumbnail}
            subtitle={subtitle}
            title={title}
            treeTitle={getIndividualTreeTitle(individual)}
            pinnedArticle={individual.pinnedArticle}
            pinnedArticleLabelOveride={'Profile'}
            families={families}
          />
        </>
      )}
    </>
  )
}

export default PublicIndividualPage
