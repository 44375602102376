import React, { useCallback } from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { makeStyles } from '@mui/styles'

import {
  AddTextBlock,
  AddTextImageBlock,
  AddFamilyTreeBlock,
  AddCarouselMediaBlock,
  AddFullWidthMediaBlock,
  AddMapBlock,
  AddWizardBlock,
  AddMoreWizardBlock,
  AddTOCBlock,
} from './AddBlocks'
import {
  CONTENT_BLOCK_LAYOUT_MEDIA_COLUMN,
  CONTENT_BLOCK_LAYOUT_TEXT_BLOCK,
  CONTENT_BLOCK_TYPE_MAP,
  CONTENT_BLOCK_TYPE_MEDIA_ROW,
  CONTENT_BLOCK_TYPE_SUB_TREE,
  CONTENT_BLOCK_TYPE_TEXT,
  CONTENT_BLOCK_TYPE_TOC,
} from './WriteArticle'
import {
  MEDIA_ROW_ALIGNMENT_CENTER,
  MEDIA_ROW_ALIGNMENT_COVER,
} from './contentBlockConstants'
import { selectIsBlogTree } from '../auth/authSlice'
import { useSelector } from 'react-redux'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { aiWizardsDefault } from '../aiWizard/aiWizardConfig'
import { chopArray } from '../common/generalUtils'

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}))

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    zIndex: 0,
  },
  backdrop: {
    position: 'absolute',
    color: theme.palette.backgroundGrey.main,
    zIndex: 99,
  },
}))

export const AddContentBlockModal = ({
  show,
  addBlockAtIndex,
  onAddAIContentBlock,
  onCloseModal,
  onAddContentBlock,
  disable = false,
}) => {
  const classes = useStyles()
  const isBlogTree = useSelector(selectIsBlogTree)
  const allowEditMap = useIsAlphaFeatureUser()?.maps
  const showAiWizard = useIsAlphaFeatureUser()?.aiWizard
  const showTockBlock = useIsAlphaFeatureUser().tocBlock

  const blocks = [
    {
      label: 'Text block',
      onClick: useCallback(
        () =>
          onAddContentBlock(
            CONTENT_BLOCK_TYPE_TEXT,
            CONTENT_BLOCK_LAYOUT_TEXT_BLOCK,
            addBlockAtIndex
          ),
        [onAddContentBlock, addBlockAtIndex]
      ),
      component: AddTextBlock,
    },
    {
      label: 'Text and image/media block',
      onClick: useCallback(
        () =>
          onAddContentBlock(
            CONTENT_BLOCK_TYPE_TEXT,
            CONTENT_BLOCK_LAYOUT_MEDIA_COLUMN,
            addBlockAtIndex
          ),
        [addBlockAtIndex, onAddContentBlock]
      ),
      component: AddTextImageBlock,
    },
    {
      label: 'Carousel Image/Media row',
      onClick: useCallback(
        () =>
          onAddContentBlock(
            CONTENT_BLOCK_TYPE_MEDIA_ROW,
            '',
            addBlockAtIndex,
            MEDIA_ROW_ALIGNMENT_CENTER
          ),
        [addBlockAtIndex, onAddContentBlock]
      ),
      component: AddCarouselMediaBlock,
    },
    {
      label: 'Full Width Image/Media row',
      onClick: useCallback(
        () =>
          onAddContentBlock(
            CONTENT_BLOCK_TYPE_MEDIA_ROW,
            '',
            addBlockAtIndex,
            MEDIA_ROW_ALIGNMENT_COVER
          ),
        [addBlockAtIndex, onAddContentBlock]
      ),
      component: AddFullWidthMediaBlock,
    },
  ]

  if (showTockBlock) {
    blocks.push({
      label: 'Table of Contents',
      onClick: () =>
        onAddContentBlock(CONTENT_BLOCK_TYPE_TOC, '', addBlockAtIndex, ''),
      component: AddTOCBlock,
    })
  }

  if (allowEditMap) {
    blocks.push({
      label: 'Map Row',
      onClick: () =>
        onAddContentBlock(
          CONTENT_BLOCK_TYPE_MAP,
          '',
          addBlockAtIndex,
          MEDIA_ROW_ALIGNMENT_CENTER
        ),
      component: AddMapBlock,
    })
  }

  if (!isBlogTree) {
    blocks.push({
      label: 'Family tree',
      onClick: () =>
        onAddContentBlock(CONTENT_BLOCK_TYPE_SUB_TREE, '', addBlockAtIndex),
      component: AddFamilyTreeBlock,
    })
  }

  const [visibleAiWizards, moreAiWizards] = chopArray(aiWizardsDefault, 5)

  let visibleAiWizardBlocks = []

  visibleAiWizardBlocks = visibleAiWizards.slice(0, 5).map(wizard => {
    return {
      label: wizard.title,
      component: ({ idx, label }) => (
        <AddWizardBlock
          addBlockAtIndex={addBlockAtIndex}
          idx={idx}
          label={label}
          wizard={wizard}
          onAddAIContentBlock={onAddAIContentBlock}
        />
      ),
    }
  })

  visibleAiWizardBlocks.push({
    label: 'More AI wizards',
    component: ({ idx, label }) => (
      <AddMoreWizardBlock
        addBlockAtIndex={addBlockAtIndex}
        onAddAIContentBlock={onAddAIContentBlock}
        idx={idx}
        label={label}
        aiwizardsOverride={moreAiWizards}
      />
    ),
  })

  return (
    <>
      <Dialog
        open={show}
        onClose={onCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <Box sx={{ position: 'relative' }}>
          <CloseButton onClick={onCloseModal}>
            <CloseIcon />
          </CloseButton>
          <DialogTitle>Add A Section</DialogTitle>
          <DialogContent className={classes.container}>
            <Divider sx={{ marginBottom: '0.5rem' }} />
            <Box>
              <Typography variant={'subtitle1'}>
                Add a new section to your article and manually fill it in:
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                m: -1,
              }}
            >
              {blocks.map(({ label, onClick, component }, idx) => {
                const AddBlockComponent = component
                return (
                  <AddBlockComponent
                    label={label}
                    onClick={onClick}
                    key={idx}
                  />
                )
              })}
            </Box>

            {showAiWizard && (
              <>
                <Divider sx={{ marginBottom: '0.5rem' }} />
                <Box sx={{ marginTop: '2rem' }}>
                  <Typography variant={'subtitle1'}>
                    Add a new section to your article by letting AI create it
                    for you:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    m: -1,
                  }}
                >
                  {visibleAiWizardBlocks.map(
                    ({ label, onClick, component }, idx) => {
                      const AddBlockComponent = component
                      return (
                        <AddBlockComponent
                          label={label}
                          onClick={onClick}
                          key={idx}
                        />
                      )
                    }
                  )}
                </Box>
              </>
            )}
            <Backdrop className={classes.backdrop} open={disable}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}
