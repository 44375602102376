import React from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material'

import { Typography } from 'src/modules/ui'
import MapIcon from '@mui/icons-material/Map'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import AiWizardDialog from '../aiWizard/AiWizardDialog'
import { wizardStates } from '../aiWizard/aiWizardConfig'
import TocIcon from '@mui/icons-material/Toc'

const useStyles = makeStyles(theme => ({
  addBlock: {
    cursor: 'pointer',
    margin: theme.spacing(0.25),
    padding: theme.spacing(1),
  },
  addBlockImage: {
    backgroundColor: 'white',
    boxShadow: `0 3px 6px ${theme.palette.lightGrey.main}`,
    border: theme.palette.lightGrey.main,
    height: 123,
    marginBottom: theme.spacing(1),
    width: 180,
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  addBlockImageRow: {
    backgroundColor: 'white',
    boxShadow: `0 3px 6px ${theme.palette.lightGrey.main}`,
    border: theme.palette.lightGrey.main,
    height: 123,
    marginBottom: theme.spacing(1),
    width: 180,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  addBlockLabel: {
    textAlign: 'center',
    fontSize: '0.8rem',
  },
  familyTree: {
    backgroundColor: theme.palette.purpleShadow.main,
    height: '65%',
    marginRight: '8%',
    marginLeft: '8%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  treeIcon: {
    height: '70%',
  },
  textTitle: {
    fontSize: 10,
    justifyContent: 'center',
    display: 'flex',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  textBlock: {
    fontSize: 8,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.grey.main,
    textAlign: 'center',
  },
  photo: {
    flexDirection: 'column',
    height: '40%',
    width: '40%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundImage: 'url(/image-default-2.svg)',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  fullWidthPhoto: {
    flexDirection: 'column',
    height: '50%',
    width: '50%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundImage: 'url(/image-default-2.svg)',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
  },
}))

const Icon = styled('img')({})

export const AddTextBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImage}>
        <div className={classes.textTitle}>We are at the start</div>
        <div className={classes.textBlock}>
          Lorem ipsum dolor sit amet, consectetur <br />
          adipiscing elit. Sed convallis volutpat quam <br />
          eget tincidunt. Marius id nisl a urna pulvinar <br />
          sodales nec nec massa.
        </div>
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddTextImageBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImageRow}>
        <div className={classes.textBlock}>
          Lorem ipsum dolor sit amet,
          <br /> consectetur adipiscing elit. Sed convallis volutpat quam eget
          <br /> tincidunt. Marius id nisl a urna <br />
          pulvinar sodales nec nec massa.
        </div>
        <div className={classes.photo}>
          <Icon src="/icon-photo-2.svg" />
        </div>
      </div>

      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddCarouselMediaBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImageRow}>
        {' '}
        <div className={classes.photo}>
          <Icon src="/icon-photo-2.svg" />
        </div>{' '}
        <div className={classes.photo}>
          <Icon src="/icon-photo-2.svg" />
        </div>{' '}
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddFullWidthMediaBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImageRow}>
        <div className={classes.fullWidthPhoto}>
          <Icon src="/icon-photo-2.svg" />
        </div>
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddTOCBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImageRow}>
        <div className={classes.fullWidthPhoto}>
          <TocIcon />
        </div>
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddFamilyTreeBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImage}>
        <div className={classes.familyTree}>
          <Icon src="/family-tree.svg" className={classes.treeIcon} />
        </div>
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddMapBlock = ({ idx, onClick, label }) => {
  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx} onClick={onClick}>
      <div className={classes.addBlockImageRow}>
        <div className={classes.fullWidthPhoto}>
          <MapIcon size="large" color="primary" />
        </div>
      </div>
      <Typography className={classes.addBlockLabel}>{label}</Typography>
    </div>
  )
}

export const AddWizardBlock = ({
  idx,
  label,
  wizard,
  onAddAIContentBlock,
  addBlockAtIndex,
}) => {
  const WizardIcon = wizard?.icon || HistoryEduIcon

  const wizardStateOverride = {
    status: wizardStates.LOADING_DEFINITION,
    selectedWizard: wizard,
    params: null,
    articleId: null,
    loadingMessage: null,
  }

  const classes = useStyles()
  return (
    <div className={classes.addBlock} key={idx}>
      <AiWizardDialog
        addBlockAtIndex={addBlockAtIndex}
        onAddAIContentBlock={onAddAIContentBlock}
        wizardStateOverride={wizardStateOverride}
        trigger={props => (
          <div onClick={props.onClick}>
            <div className={classes.addBlockImageRow}>
              <div className={classes.textBlock}>
                Lorem ipsum dolor sit amet,
                <br /> consectetur adipiscing elit. Sed convallis volutpat quam
                eget
                <br /> tincidunt. Marius id nisl a urna <br />
                pulvinar sodales nec nec massa.
              </div>
              <div className={classes.photo}>
                <WizardIcon />
              </div>
            </div>

            <Typography className={classes.addBlockLabel}>{label}</Typography>
          </div>
        )}
      />
    </div>
  )
}

export const AddMoreWizardBlock = ({
  idx,
  label,
  aiwizardsOverride,
  addBlockAtIndex,
  onAddAIContentBlock,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.addBlock} key={idx}>
      <AiWizardDialog
        addBlockAtIndex={addBlockAtIndex}
        onAddAIContentBlock={onAddAIContentBlock}
        aiwizardsOverride={aiwizardsOverride}
        trigger={props => (
          <div onClick={props.onClick}>
            <div className={classes.addBlockImageRow}>
              <div className={classes.fullWidthPhoto}>
                <UnfoldMoreIcon />
              </div>
            </div>

            <Typography className={classes.addBlockLabel}>{label}</Typography>
          </div>
        )}
      />
    </div>
  )
}
