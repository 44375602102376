import React, { useContext, useRef, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import {
  convertUIGedDate,
  gedcomStrToDateObj,
} from '../common/gedcomDateParser'
import EditButton from '../ui/EditButton'
import { UNION_FACT_TYPES } from './CreateUpdateFact'
import {
  formatIndividualName,
  formatIndividualWithYears,
} from '../ui/individualUtils'
import { Link } from '../ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import {
  INSTANCE_TYPE_FACT,
  INSTANCE_TYPE_INDIVIDUAL,
  useObjectLink,
} from '../app/links'
import {
  isHiddenIndividual,
  isUnknownIndividual,
} from '../viewer/api/nodeDirectory'
import { isNull } from 'lodash'
import { useSelector } from 'react-redux'
import { selectIndividualById } from '../viewer/viewerSlice'
import { selectPublicIndividualById } from '../public/tree/treeSlice'
import { useParams } from 'react-router-dom'
import { ACTION_EDIT } from '../app/appConstants'
import { FactListContext } from './Facts'
import { Link as MuiLink } from '@mui/material'
import ContentPhotos from '../content/ContentPhotos'

export const handleAddress = address => {
  if (address?.gedcomLines) {
    const gedcomLines = address.gedcomLines

    const findGedLinesSection = field => {
      const section = gedcomLines.find(
        addressItem => addressItem.tagName === field
      )?.lineValue
      if (!section) return ''
      return section
    }

    const addr = findGedLinesSection('ADDR')
    const city = findGedLinesSection('CITY')
    const state = findGedLinesSection('STAE')
    const post = findGedLinesSection('POST')
    const country = findGedLinesSection('CTRY')

    const handleAddressSection = section => {
      if (!section) return ''
      return `, ${section}`
    }

    let textAddress =
      addr +
      handleAddressSection(city) +
      handleAddressSection(state) +
      handleAddressSection(post) +
      handleAddressSection(country)

    if (address?.freeText) {
      textAddress = textAddress + `, ${address?.freeText}`
    }

    return textAddress
  } else if (address?.freeText) {
    return address.freeText
  } else {
    return ''
  }
}

export const DetailsAndNotes = ({ text, type = 'notes' }) => {
  const [showText, setShowText] = useState()
  const textRef = useRef()
  return (
    <Box
      sx={{
        borderTop: '1px solid #ccc',
        marginTop: 0.5,
        paddingTop: 0.5,
        position: 'relative',
      }}
    >
      <Collapse in={showText} collapsedSize={24}>
        {textRef?.current?.offsetHeight > 24 && !showText && (
          <Typography
            onClick={() => setShowText(true)}
            color="primary"
            sx={{
              position: 'absolute',
              textAlign: 'right',
              width: '100%',
              backgroundImage:
                'linear-gradient(to right, transparent 40%, rgba(255,255,255,0.9))',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Read more
          </Typography>
        )}
        <Typography
          ref={textRef}
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}: {text}{' '}
          {showText && (
            <span
              style={{
                textDecoration: 'underline',
                color: '#240048',
                cursor: 'pointer',
              }}
              onClick={() => setShowText(false)}
            >
              Close
            </span>
          )}
        </Typography>
      </Collapse>
    </Box>
  )
}
export const FactListSourcePhoto = props => {
  const photo = props.photo

  return (
    <>
      <img
        {...photo}
        alt={''}
        onClick={props.imageProps.onClick}
        style={{
          padding: '2px',
          height: 'auto',
          width: '200px',
          objectFit: 'cover',
        }}
      />
    </>
  )
}

const SourcePhotoGallery = ({ sourcesCitationsList }) => {
  if (!sourcesCitationsList) return null

  const allMedia = sourcesCitationsList.flatMap(obj =>
    obj.citationMedia ? obj.citationMedia : []
  )

  return (
    <div>
      <ContentPhotos media={allMedia} flexBoxGallery={true} />
    </div>
  )
}

const FactContainer = props => (
  <Box
    boxShadow={2}
    borderRadius={1}
    my={0.6}
    p={1}
    gap={1}
    display="flex"
    flexDirection="row"
    {...props}
  >
    {props.children}
  </Box>
)

export const IndividualOnFact = ({
  individual,
  disabled = false,
  ...props
}) => {
  const individualLink = useObjectLink(INSTANCE_TYPE_INDIVIDUAL, individual?.id)
  const displayName = formatIndividualName(individual)

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {individual?.hasPhoto && !individual?.photo ? (
        <img
          alt="Loading Spinner"
          src="/loading-spinner.svg"
          width={34}
          height={34}
        />
      ) : (
        <img
          alt={displayName}
          src={
            individual?.photo
              ? individual?.photo.fileThumbnail
              : individual?.gender === 'F'
              ? '/female-placeholder.png'
              : '/person-placeholder.png'
          }
          width={34}
          height={34}
          style={{ borderRadius: '50%' }}
        />
      )}
      {isUnknownIndividual(individual?.id) ||
        (!isHiddenIndividual(individual) && (
          <Link disabled={disabled} to={`${individualLink}/facts`}>
            <Typography ml={1} fontWeight="regular" color="primary">
              {formatIndividualWithYears({
                ...individual,
                shortenName: true,
              })}
            </Typography>
          </Link>
        ))}
    </Box>
  )
}
const Fact = ({
  id,
  factType,
  fromDateGed,
  toDateGed,
  recordedDatesGed,
  address,
  notes,
  detail,
  preferred,
  sourcesCitationsList,
  relationship,
  isPublic,
  individualId,
  customFactType,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const individual = useSelector(
    isPublic
      ? selectPublicIndividualById(individualId)
      : selectIndividualById(individualId)
  )
  const disabled = relationship
  const { linkedPageId } = useParams()

  const handleFactYear = () => {
    if (recordedDatesGed?.length && recordedDatesGed[0]) {
      const dateObj = gedcomStrToDateObj(recordedDatesGed?.[0])

      if (dateObj?.startDate) {
        return dateObj?.startDate?.year
      } else if (dateObj?.endDate) {
        return dateObj?.endDate?.year
      } else {
        return dateObj?.year
      }
    } else if (fromDateGed) {
      return gedcomStrToDateObj(fromDateGed)?.year
    }
  }

  const factYear = handleFactYear()
  const { setFactEditParams } = useContext(FactListContext)

  return (
    <FactContainer
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        backgroundColor: !isNull(relationship) ? '#ddd' : '#fff',
      }}
    >
      {factYear && (
        <Box sx={{ height: 'fit-content', display: 'flex', gap: 1 }}>
          <Typography fontWeight="bold">{factYear}</Typography>
        </Box>
      )}
      <Box
        sx={{
          borderLeft: factYear && '1px solid #ccc',
          paddingLeft: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingBottom: 1,
          }}
        >
          <Box>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>
                <span style={{ textTransform: 'capitalize' }}>
                  {factType.toLowerCase()}
                </span>
                {customFactType && `: ${customFactType}`}{' '}
                {relationship && `of ${relationship}`}
              </span>{' '}
              {((recordedDatesGed?.length && recordedDatesGed[0]?.length > 4) ||
                fromDateGed?.length > 4) && (
                <span>
                  ({convertUIGedDate(recordedDatesGed?.[0] || fromDateGed)})
                </span>
              )}{' '}
              {preferred && '(Preferred)'}
            </Typography>
            {address && <Typography>{handleAddress(address)}</Typography>}
            {individualId !== linkedPageId &&
              (relationship || UNION_FACT_TYPES.includes(factType)) && (
                <IndividualOnFact individual={individual} />
              )}
          </Box>
          <Box sx={{ display: disabled ? 'none' : 'auto' }}>
            {!isPublic && (
              <EditButton
                permissionAction={ACTION_EDIT}
                permissionParams={{
                  instance: individual,
                  instanceType: INSTANCE_TYPE_INDIVIDUAL,
                }}
                onClick={() =>
                  setFactEditParams({
                    factId: id,
                    editModalOpen: true,
                    defaultTab: 0,
                  })
                }
                style={{ opacity: isHovered ? 1 : 0 }}
              />
            )}
          </Box>
        </Box>
        {detail && detail.toLowerCase() !== 'none' && (
          <DetailsAndNotes text={detail} type="detail" />
        )}
        {notes && notes.toLowerCase() !== 'none' && (
          <DetailsAndNotes text={notes} />
        )}
        {sourcesCitationsList?.length > 0 && !isPublic && (
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              borderTop: '1px solid #ccc',
              marginTop: 0.5,
              paddingTop: 0.5,
              position: 'relative',
            }}
          >
            <MuiLink
              onClick={() =>
                setFactEditParams({
                  factId: id,
                  editModalOpen: true,
                  defaultTab: 1,
                })
              }
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {sourcesCitationsList?.length} source
                {sourcesCitationsList?.length > 1 && 's'}
              </Typography>
            </MuiLink>
            <SourcePhotoGallery sourcesCitationsList={sourcesCitationsList} />
          </Box>
        )}
      </Box>
    </FactContainer>
  )
}

export default function FactsList({ facts, individual, isPublic }) {
  console.log('DEBUG facts', facts, individual, isPublic)

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', pt: 4.5 }}>
      {facts?.map(fact => (
        <Fact isPublic={isPublic} {...fact} />
      ))}
      {!facts?.length && <EmptyFeed type={INSTANCE_TYPE_FACT} />}
      {individual?.notes && (
        <>
          <Typography variant="subtitle1" color="primary" mt={2}>
            General Notes:
          </Typography>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {individual.notes}
          </Typography>
        </>
      )}
    </Box>
  )
}
