import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_MAP,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  useTreeLink,
} from '../app/links'
import React from 'react'
import ButtonLink from './ButtonLink'
import SiteWideIcon from './SiteWideIcon'
import Button from './Button'
import { useAvailableFeatures } from '../auth/hooks'
import { clearPhotoOptionsResults } from '../photo/photoSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  ACTION_ALL_ACCESS,
  ACTION_CREATE,
  ACTION_EDIT,
} from '../app/appConstants'
import AddContentDialog from '../app/AddContentDialog'

import { Link as RouterLink } from 'react-router-dom'
import { usePermissions } from '../auth/authHooks'
import { PERMISSION_DENIED_TYPE_FEATURE } from '../auth/permissionUtils'

export const AddFactButton = props => {
  return (
    <Button
      permissionAction={ACTION_EDIT}
      permissionParams={{
        instanceType: INSTANCE_TYPE_INDIVIDUAL,
        instance: props?.individual,
      }}
      endIcon={
        <SiteWideIcon
          instanceType={INSTANCE_TYPE_DOCUMENT}
          fontSize={'large'}
        />
      }
      color="primary"
      size="large"
      {...props}
    >
      Add Fact
    </Button>
  )
}

export const AddStoryButton = ({
  to,
  buttonText = 'Add Article',
  className = 'joyride_first_time_user_addcontent',
}) => {
  const checkPermissions = usePermissions()

  const permissionAction = ACTION_CREATE
  const permissionParams = {
    instanceType: INSTANCE_TYPE_ARTICLE,
  }

  const { hasPermission, permissionDeniedType } = checkPermissions(
    permissionAction,
    permissionParams
  )

  const button = props => (
    <Button
      className={className}
      color="primary"
      size="large"
      permissionAction={permissionAction}
      permissionParams={permissionParams}
      {...props}
      sx={{ whiteSpace: 'nowrap' }}
      endIcon={
        <SiteWideIcon instanceType={INSTANCE_TYPE_ARTICLE} fontSize={'large'} />
      }
    >
      {buttonText}
    </Button>
  )

  if (hasPermission) {
    return (
      <>
        {permissionDeniedType === PERMISSION_DENIED_TYPE_FEATURE ? (
          <RouterLink
            target={'_self'}
            //className={classes.root}
            to={'/pricing'}
          >
            {button()}
          </RouterLink>
        ) : (
          <AddContentDialog
            offerArticleTemplates={true}
            ifNoTemplates={trigger => (
              <>
                <RouterLink target={'_self'} to={to}>
                  {trigger}
                </RouterLink>
              </>
            )}
            trigger={button}
            routerTo={to}
          />
        )}
      </>
    )
  } else {
    return button()
  }
}

export const AddBlogPostButton = ({ buttonText }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const path = 'write-article'
  let writeLink = useTreeLink(path)

  const handlePost = () => {
    const params = {
      pathname: writeLink,
    }
    dispatch(clearPhotoOptionsResults())
    history.push(params)
  }

  return (
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      color="primary"
      size="large"
      onClick={handlePost}
    >
      {buttonText}
    </Button>
  )
}

export const ViewBlogButton = ({ to }) => {
  return (
    <ButtonLink
      permissionAction={ACTION_ALL_ACCESS}
      endIcon={
        <SiteWideIcon instanceType={INSTANCE_TYPE_ARTICLE} fontSize={'large'} />
      }
      to={to}
      target="_blank"
    >
      View Blog
    </ButtonLink>
  )
}

export const UploadDocumentButton = ({ to }) => {
  return (
    <ButtonLink
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: INSTANCE_TYPE_DOCUMENT,
      }}
      endIcon={
        <SiteWideIcon
          instanceType={INSTANCE_TYPE_DOCUMENT}
          fontSize={'large'}
        />
      }
      to={to}
    >
      Upload Document
    </ButtonLink>
  )
}

export const UploadMediaButton = props => {
  return (
    <Button
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: INSTANCE_TYPE_MEDIA,
      }}
      endIcon={
        <SiteWideIcon instanceType={INSTANCE_TYPE_MEDIA} fontSize={'large'} />
      }
      color="primary"
      size="large"
      {...props}
    >
      Upload Media
    </Button>
  )
}

export const CreateAlbumButton = props => {
  return (
    <Button
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: INSTANCE_TYPE_PHOTO_ALBUM,
      }}
      endIcon={
        <SiteWideIcon
          instanceType={INSTANCE_TYPE_PHOTO_ALBUM}
          fontSize={'large'}
        />
      }
      color="primary"
      size="large"
      {...props}
    >
      Create Album
    </Button>
  )
}

export const CreateMapButton = props => {
  return (
    <Button
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: INSTANCE_TYPE_MAP,
      }}
      endIcon={
        <SiteWideIcon instanceType={INSTANCE_TYPE_MAP} fontSize={'large'} />
      }
      color="primary"
      size="large"
      {...props}
    >
      Create Map
    </Button>
  )
}

export const AddLinkedPageButton = props => {
  const canEditTree = useAvailableFeatures()?.tree?.editTree

  return (
    <Button
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: props.type,
      }}
      endIcon={<SiteWideIcon instanceType={props.type} fontSize={'large'} />}
      color="primary"
      size="large"
      featureAvailable={canEditTree}
      {...props}
    >
      {props.text}
    </Button>
  )
}

export const AddIndividualButton = props => {
  const canEditTree = useAvailableFeatures()?.tree?.editTree

  return (
    <Button
      permissionAction={ACTION_CREATE}
      permissionParams={{
        instanceType: props.type,
      }}
      endIcon={
        <SiteWideIcon
          instanceType={INSTANCE_TYPE_INDIVIDUAL}
          fontSize={'large'}
        />
      }
      color="primary"
      size="large"
      featureAvailable={canEditTree}
      {...props}
    >
      {props.text}
    </Button>
  )
}
