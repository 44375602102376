import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIndividualById } from '../viewer/viewerSlice'
import {
  AddIndividualOnlyManualDialog,
  AddOrSelectIndividualButton,
  SelectIndividualOnlyManualDialog,
} from '../viewer/ChooseOrAddIndividual'
import { UpdateIndividualRelationshipsListDialog } from '../viewer/UpdateIndividualRelationships'

const useAddIndividualControl = ({ target, presetTargets, pageType }) => {
  const [addIndividualModalOpen, setAddIndividualModalOpen] = useState(false)
  const [selectIndividualModalOpen, setSelectIndividualModalOpen] =
    useState(false)
  const [selectedIndividualId, setSelectedIndividualId] = useState(null)
  var individual = useSelector(selectIndividualById(selectedIndividualId))

  const addIndividualControl = (
    <>
      <AddIndividualOnlyManualDialog
        setModalOpen={setAddIndividualModalOpen}
        modalOpen={addIndividualModalOpen}
        presetTargets={presetTargets}
        onIndividualAdded={individual => setSelectedIndividualId(individual.id)}
      />
      <SelectIndividualOnlyManualDialog
        setModalOpen={setSelectIndividualModalOpen}
        modalOpen={selectIndividualModalOpen}
        onIndividualSelected={individual => {
          setSelectedIndividualId(individual.id)
        }}
      />
      <AddOrSelectIndividualButton
        setSelectIndividualModalOpen={setSelectIndividualModalOpen}
        setAddIndividualModalOpen={setAddIndividualModalOpen}
      />
      <UpdateIndividualRelationshipsListDialog
        individual={individual}
        target={target}
        pageType={pageType}
        isLoadingOveride={true}
        onCloseParentDialog={() => setSelectedIndividualId(null)}
      />
    </>
  )

  return {
    addIndividualControl,
    selectedIndividualId,
    setSelectedIndividualId,
  }
}

export default useAddIndividualControl
