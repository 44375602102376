import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material'
import {
  ACTION_ALL_ACCESS,
  ACTION_CREATE,
  STOP_SHOWING_SIDEBAR_KEY,
} from '../appConstants'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton } from '../../ui'
import { setSideBarVisible } from '../appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import {
  selectAuthorisedTreeSlug,
  selectIsBlogTree,
} from '../../auth/authSlice'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddContentDialog from '../AddContentDialog'
import AiWizardDialog from '../../aiWizard/AiWizardDialog'
import { wizardStates } from '../../aiWizard/aiWizardConfig'
import { INSTANCE_TYPE_ARTICLE, INSTANCE_TYPE_INDIVIDUAL } from '../links'
import { getStorage, setStorage } from '../useLocalStorageHook'
import { useIsAlphaFeatureUser } from '../../auth/hooks'
import { ga4Events, sendEvent } from '../../analytics/AnalyticsUtils'
import { usePermissions } from '../../auth/authHooks'
import { useNotification, useTreeSettings } from '../hooks'
import { selectIndividualForPage } from '../../viewer/viewerSlice'
import { formatIndividualName } from '../../ui/individualUtils'
import LinkedPageHelp from './LinkedPageHelp'

const defaultCondition = () => true

const StopShowingSideBarControl = () => {
  const dispatch = useDispatch()
  const state = getStorage(STOP_SHOWING_SIDEBAR_KEY) || {
    stopShowingSideBar: false,
  }
  const { showInfoMessage } = useNotification()

  const handleChange = event => {
    sendEvent(ga4Events.SIDEBAR_STOP_SHOWING)
    setStorage(STOP_SHOWING_SIDEBAR_KEY, { stopShowingSideBar: true })
    showInfoMessage('Click the help sidebar button in menu to show again.')
    dispatch(setSideBarVisible(false))
  }

  return (
    <>
      <Divider sx={{ mb: '2rem' }} />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.stopShowingSideBar}
            onChange={handleChange}
          />
        }
        label="Stop Showing Sidebar"
      />
    </>
  )
}

const HomePageSuggestions = () => {
  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body1">
        Hi I'm <strong>Gene Poole</strong>, your friendly assistant!
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Here are some suggestions on what you can do next with your archive:
      </Typography>
      <ul>
        <li>
          <Typography sx={{ mb: 2 }} variant="body1">
            Click on 'add content' to add articles, photos, individuals and more
            to your archive
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <AddContentDialog
              trigger={props => (
                <Button
                  sx={{ mr: 1 }}
                  size="large"
                  variant="outlined"
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={() => {
                    props.onClick()
                  }}
                >
                  Add Content
                </Button>
              )}
            />
          </Box>
        </li>
        <li>
          <Typography sx={{ mb: 2 }} variant="body1">
            Click on 'create biography' to use AI to create a quick biography
            article for your archive.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <AiWizardDialog
              wizardStateOverride={{
                status: wizardStates.LOADING_DEFINITION,
                selectedWizard: {
                  id: 'quick_bio',
                  title: 'Quick Biography',
                  preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
                },
                params: null,
                articleId: null,
                loadingMessage: null,
              }}
              trigger={props => (
                <Button
                  sx={{ mr: 1 }}
                  size="large"
                  variant="outlined"
                  permissionAction={ACTION_ALL_ACCESS}
                  {...props}
                >
                  Create Biography
                </Button>
              )}
            />
          </Box>
        </li>
      </ul>
    </>
  )
}

const IndividualPageSuggestions = () => {
  const individualForPage = useSelector(selectIndividualForPage)
  const individualName = formatIndividualName(individualForPage)

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body1">
        Hi I'm <strong>Gene Poole</strong>, your friendly assistant!
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Here are some suggestions on what you can do next with your archive for
        this <b>{individualName}</b>
      </Typography>
      <ul>
        <li>
          <Typography sx={{ mb: 2 }} variant="body1">
            Click on 'add content' to add articles, photos, individuals and more
            to your archive that will be connected to <b>{individualName}</b>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <AddContentDialog
              trigger={props => (
                <Button
                  sx={{ mr: 1 }}
                  size="large"
                  variant="outlined"
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={() => {
                    props.onClick()
                  }}
                >
                  Add Content
                </Button>
              )}
            />
          </Box>
        </li>
        <li>
          <Typography sx={{ mb: 2 }} variant="body1">
            Click on 'create biography' to use AI to create a quick biography
            article for <b>{individualName}</b>.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <AiWizardDialog
              selectedInstanceOverride={individualForPage}
              wizardStateOverride={{
                status: wizardStates.LOADING_DEFINITION,
                selectedWizard: {
                  id: 'quick_bio',
                  title: 'Quick Biography',
                  preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
                },
                params: null,
                articleId: null,
                loadingMessage: null,
              }}
              trigger={props => (
                <Button
                  sx={{ mr: 1 }}
                  size="large"
                  variant="outlined"
                  permissionAction={ACTION_ALL_ACCESS}
                  {...props}
                >
                  Create Biography
                </Button>
              )}
            />
          </Box>
        </li>
      </ul>
    </>
  )
}

const ArticleWriteHelp = () => {
  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body1">
        Hi I'm <strong>Gene Poole</strong>, your friendly assistant!
      </Typography>
      <Typography sx={{ mb: 2 }} variant="subTitle1">
        Add a block
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        You're currently writing an article. Articles are made of blocks. Blocks
        can be text, images, and even family trees.
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        You can add a block by clicking the add block button.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <AddCircleIcon fontSize="large" />
      </Box>
      <Typography sx={{ mb: 2 }} variant="body1">
        if you can't see the add block button, hover your pointer over the
        article.
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ mb: 2 }} variant="subTitle1">
        Tag your Article
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        Make sure you tag your article. The tag section is a the bottom of the
        article.
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        You can tag with individuals, families, places, occasions, and more.
        Tagging lets you find you content easily.
      </Typography>
    </>
  )
}

const sideBarConfigRoutes = [
  {
    id: 1000,
    urlPattern: ['/home', '/home/tree', '/home/research'],
    component: HomePageSuggestions,
    condition: defaultCondition,
    comment: 'Home page suggestions',
  },
  {
    id: 1001,
    urlPattern: ['/write-article/*'],
    component: ArticleWriteHelp,
    condition: defaultCondition,
  },
  {
    id: 1002,
    urlPattern: ['/places/*', '/occasions/*', '/artefacts/*'],
    component: LinkedPageHelp,
    condition: defaultCondition,
  },
  {
    id: 1003,
    urlPattern: [
      '/individuals/:id/research',
      '/individuals/:id/individual_profile',
      '/individuals/:id',
    ],
    component: IndividualPageSuggestions,
    condition: defaultCondition,
    comment: 'Individual page suggestions',
  },
]

const Sidebar = styled('div')(({ theme, visible }) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  minHeight: '200vh',
  backgroundColor: 'white',
  transition: 'transform 0.5s ease, width 0.5s ease',
  zIndex: 1002,
  overflow: 'hidden',
  transform: visible ? 'translateX(0)' : 'translateX(300px)',
  opacity: visible ? 1 : 0, // Adjust opacity
  width: visible ? '300px' : '0px', // Adjust visibility
}))

const SideBarContent = styled('div')(({ theme }) => ({
  marginTop: '86px',
  minHeight: '200vh',
  padding: '16px',
  backgroundColor: 'white',
  border: '1px solid #d3d3d3', // Add a left-hand border of 1px
}))

const AppSideBar = ({ sideBarVisible }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const history = useHistory()
  const checkPermissions = usePermissions()
  const treeSettings = useTreeSettings()
  const isBlogTree = useSelector(selectIsBlogTree)
  const handleClose = () => {
    sendEvent(ga4Events.SIDEBAR_HIDE)
    dispatch(setSideBarVisible(false))
  }
  const hasAuthPermission = checkPermissions(ACTION_CREATE, {
    instanceType: INSTANCE_TYPE_ARTICLE,
  }).hasPermission

  const showAiWizard = useIsAlphaFeatureUser()?.aiWizard && hasAuthPermission

  const [lastSideBarMatch, setLastSideBarMatch] = useState(null)
  const state = getStorage(STOP_SHOWING_SIDEBAR_KEY) || {
    stopShowingSideBar: false,
  }

  const matchingRoute = sideBarConfigRoutes.find(route => {
    return route.urlPattern.some(pattern => {
      const match = matchPath(location.pathname, {
        path: `/${treeSlug}${pattern}`,
        exact: true,
        strict: false,
      })
      return match && route.condition({ treeSettings })
    })
  })

  useEffect(() => {
    let timeoutId
    if (matchingRoute && !state.stopShowingSideBar && showAiWizard) {
      timeoutId = setTimeout(() => {
        setLastSideBarMatch(matchingRoute)
        sendEvent(ga4Events.SIDEBAR_SHOW)
        dispatch(setSideBarVisible(true))
      }, 2000)
    } else {
      setLastSideBarMatch(null)
      dispatch(setSideBarVisible(false))
    }
    return () => clearTimeout(timeoutId)
  }, [
    dispatch,
    matchingRoute,
    location.pathname,
    state.stopShowingSideBar,
    showAiWizard,
  ])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (lastSideBarMatch?.id !== matchingRoute?.id) {
        dispatch(setSideBarVisible(false))
      }
    })

    return () => {
      unlisten()
    }
  }, [dispatch, history, lastSideBarMatch?.id, matchingRoute?.id])

  if (!showAiWizard || isBlogTree) {
    return null
  }

  return (
    <Sidebar visible={sideBarVisible}>
      <SideBarContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">How Can I Help?</Typography>
          <Box>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>{matchingRoute && <matchingRoute.component />}</Box>
        <Box>
          <StopShowingSideBarControl />
        </Box>
      </SideBarContent>
    </Sidebar>
  )
}

export default AppSideBar
