import { PAGE_DISPLAY_NAME_SINGULAR } from './LinkedPage'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { generateLinkForObject } from '../app'
import { AddLinkedPageButton } from '../ui/actionButtons'
import React from 'react'
import CreateOrUpdateLinkedPageDialog from 'src/modules/page/CreateUpdateLinkedPage'

export const AddLinkedPageControl = ({ type, targets }) => {
  const title = PAGE_DISPLAY_NAME_SINGULAR[type]
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const navigateToPage = page => {
    const link = generateLinkForObject(treeSlug, type, page.id)
    history.push(link)
  }

  return (
    <>
      <CreateOrUpdateLinkedPageDialog
        type={type}
        onCreate={navigateToPage}
        presetTargets={targets}
        trigger={props => (
          <AddLinkedPageButton {...props} type={type} text={`Add ${title}`} />
        )}
      />
    </>
  )
}

export default AddLinkedPageControl
