import { EditTreeName } from './EditTreeName'
import React from 'react'

import { Button, Typography } from '../ui'
import { Formik } from 'formik'
import { AddressForm } from '../common/AddressForm'
import { Box, Card, CardActions, CardContent, styled } from '@mui/material'
import { ACTION_CREATE, FOCUS_ONE_PLACE_STUDY } from '../app/appConstants'
import { INSTANCE_TYPE_TREE } from '../app/links'
import * as Yup from 'yup'
import { createTreeWithFocus } from './treesSlice'
import { useActionDispatcher, useSetTree } from '../app'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { useHistory } from 'react-router-dom'
import { fetchUser } from '../auth/authSlice'
import TreeVisibilityFormControl from './TreeVisibilityFormControl'

const OnePlaceStudyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'left',
  gap: theme.spacing(2),
  width: '50%',
}))

const OnePlaceCardContent = styled(CardContent)(({ theme }) => ({
  textAlign: 'left',
}))

const OnePlaceCardElement = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}))

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Name is required'),
})

const CreateOnePlaceStudy = () => {
  const dispatchCreateTreeWithFocus = useActionDispatcher(createTreeWithFocus)
  const history = useHistory()
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const setTree = useSetTree()

  const initialValues = {
    title: undefined,
    address: undefined,
    visibility: VISIBILITY_PUBLIC,
  }

  const handleSubmit = async values => {
    const response = await dispatchCreateTreeWithFocus({
      archiveFocus: FOCUS_ONE_PLACE_STUDY,
      title: values.title,
      visibility: values.visibility,
      address: values.address,
    })
    await dispatchFetchUser()
    await setTree(response?.payload?.slug)

    const url = `/${response?.payload?.slug}/upload-gedcom`

    history.push(url)
  }

  return (
    <OnePlaceStudyContainer>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h1" color="primary">
          One Place Study
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ touched, errors, handleSubmit, isSubmitting, values }) => (
          <Card>
            <OnePlaceCardContent>
              <OnePlaceCardElement>
                <Typography color="textSecondary" variant="subtitle1">
                  1. NAME YOUR ONE PLACE STUDY
                </Typography>
                <EditTreeName
                  stopOnTreeNameChangedIfCollision={true}
                  onTreeNameChanged={name => (values.title = name)}
                />
                {errors.title && touched.title && (
                  <div style={{ color: 'red' }}>{errors.title}</div>
                )}
              </OnePlaceCardElement>
              <OnePlaceCardElement>
                <Typography color="textSecondary" variant="subtitle1">
                  2. SELECT A LOCATION
                </Typography>

                <>
                  <AddressForm
                    address={values.address}
                    onValuesChanged={address => {
                      values.address = address
                    }}
                  />
                </>
              </OnePlaceCardElement>
              <OnePlaceCardElement>
                <Typography
                  color="textSecondary"
                  variant="subtitle1"
                  sx={{ marginBottom: '1rem' }}
                >
                  3. VISIBILITY
                </Typography>
                <TreeVisibilityFormControl
                  defaultVisibility={VISIBILITY_PUBLIC}
                  onChange={visibility => (values.visibility = visibility)}
                />
              </OnePlaceCardElement>
            </OnePlaceCardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={isSubmitting}
                permissionAction={ACTION_CREATE}
                permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                onClick={handleSubmit}
                color="primary"
              >
                Next
              </Button>
            </CardActions>
          </Card>
        )}
      </Formik>
    </OnePlaceStudyContainer>
  )
}

export default CreateOnePlaceStudy
