import { attributesToProps, domToReact } from 'html-react-parser'
import slugify from 'slugify'
import { Link as MuiLink } from '@mui/material'
import { Link } from 'src/modules/ui'
import { SourceLink } from './SourcesUI'
import {
  generatePublicLinkForObject,
  generateTreeLink,
  isBlogPost,
  isSinglePageUrl,
} from '../app/links'
import pluralize from 'pluralize'
import React from 'react'

const handleHref = (domNode, isPublic, treeSlug, instanceType) => {
  const target = domNode.attribs['target']
  if (target !== '_blank') {
    const contentId = domNode.attribs['data-content-id']

    if (!isPublic) {
      if (instanceType === 'event') {
        instanceType = 'occasion'
      } else if (instanceType === 'location') {
        instanceType = 'place'
      }
    }

    if (instanceType && treeSlug && contentId) {
      const href = isPublic
        ? generatePublicLinkForObject(treeSlug, instanceType, contentId)
        : generateTreeLink(treeSlug, `${pluralize(instanceType)}/${contentId}`)

      domNode.attribs.href = href
    }
  }
}

const handleTOCElement = ({ domNode, tocElement }) => {
  const props = attributesToProps(domNode.attribs)
  const children = domNode.children

  // Check if the element contains only text
  if (children.length === 1 && children[0].type === 'text') {
    const text = children[0].data
    const slug = slugify(text, { lower: true, strict: true })

    return React.createElement(
      tocElement,
      { id: slug, ...props },
      React.createElement(
        'a',
        {
          href: `#${slug}`,
          style: { color: 'inherit', textDecoration: 'none' },
        },
        text
      )
    )
  } else {
    // If the element contains more than just text, return it as is
    return React.createElement(tocElement, props, domToReact(children, options))
  }
}

export const makeParseOptions = ({
  contentId,
  removeFormatting,
  url = '',
  enableLinks,
  tocElement = 'h4',
} = {}) => ({
  replace: domNode => {
    if (domNode.name === 'p' && domNode.children.length === 0) {
      return <br />
    }

    const instanceType =
      domNode.attribs?.['data-instance-type'] ||
      domNode.attribs?.['data-content-type'] ||
      null
    if (domNode.name === 'a' && instanceType) {
      handleHref(domNode, options.isPublic, options.treeSlug, instanceType)
      const { href: to, ...props } = attributesToProps(domNode.attribs)
      if (instanceType === 'source') {
        if (options.sources) {
          const sourceId = props['data-content-id']
          if (sourceId && sourceId !== 'undefined') {
            const source = options.sources.filter(it => it.id === sourceId)[0]
            if (source) {
              return (
                <SourceLink
                  contentId={contentId}
                  source={source}
                  editMode={false}
                />
              )
            } else {
              console.error(
                `parseOptions.js makeParseOptions().replace(): no source found with id '${sourceId}'. Sources:`,
                options.sources
              )
              return <></>
            }
          } else {
            console.error(
              `parseOptions.js makeParseOptions().replace(): Link with 'data-instance-type' === 'source' has unexpected 'data-content-id' of: '${sourceId}'`,
              domNode
            )
            return <></>
          }
        } else {
          return <></>
        }
      } else {
        let href = to

        if (enableLinks) {
          if (href) {
            if (isSinglePageUrl(url)) {
              //for single page urls like blog or share disable internal links by making em spans
              domNode.name = 'span'
            } else {
              if (
                options.isPublic &&
                href.includes(options.treeSlug) &&
                !href.includes('public')
              ) {
                href = `/public${href}`
              }
              let target = '_self'
              if (isBlogPost(url)) {
                target = '_blank'
              }

              return (
                <Link {...props} to={href} target={target}>
                  {domToReact(domNode.children)}
                </Link>
              )
            }
          } else {
            domNode.name = 'p'
          }
        } else {
          domNode.name = 'span'
        }
      }
    } else if (domNode.name === 'a') {
      handleHref(domNode, options.isPublic, options.treeSlug, instanceType)
      const props = attributesToProps(domNode.attribs)
      return <MuiLink {...props}>{domToReact(domNode.children)}</MuiLink>
    } else if (
      removeFormatting &&
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(domNode.name)
    ) {
      const props = attributesToProps(domNode.attribs)
      return <p {...props}>{domToReact(domNode.children, options)}</p>
    } else if ([tocElement].includes(domNode.name)) {
      return handleTOCElement({ domNode, tocElement })
    }
  },
})

export const options = makeParseOptions()
