import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import { Subtitle, Title, LastKnownCustodian } from 'src/modules/ui/PageTitle'
import Thumbnail, { THUMB_SIZE_PAGE_TITLE } from 'src/modules/photo/Thumbnail'
import { SplitIndividualFamilyLinkChips } from 'src/modules/content/LinkChips'
import EventIcon from '@mui/icons-material/Event'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import { INSTANCE_TYPE_FAMILY, INSTANCE_TYPE_INDIVIDUAL } from '../../app/links'
import { ShareButton } from '../../visibility/InstanceVisibilityControl'
import { usePublicTreeSettings, useShare } from '../../app'
import { useEmbed } from '../hooks'

const DefaultPhoto = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3.5),
  height: 100,
  width: 100,
  backgroundImage: 'url(/image-default-3.svg)',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  borderRadius: 70,
}))

const PublicPageTitle = ({
  description,
  entityTypeTitle,
  lastKnownCustodian,
  photoUrl,
  subtitle,
  address,
  title,
  links,
  pageType,
  treeSlug,
}) => {
  const handleShare = useShare(true)
  const embed = useEmbed()
  const { isOnePlaceStudyHome } = usePublicTreeSettings()

  const embedTitle = (
    <Typography variant="h1" color="primary">
      {title}
    </Typography>
  )

  const fullScreenTitle = (
    <Box>
      <Box sx={{ mt: 1 }}>
        <Box
          sx={{
            position: 'relative',
            mb: 0.5,
          }}
        >
          <Stack direction="row" alignItems="center">
            <Stack alignItems="center" alignSelf="flex-start">
              {photoUrl ? (
                <Box sx={{ mr: 3.5 }}>
                  <Thumbnail
                    round
                    size={THUMB_SIZE_PAGE_TITLE}
                    file={photoUrl}
                  />
                </Box>
              ) : pageType !== INSTANCE_TYPE_FAMILY ? (
                <DefaultPhoto
                  style={{
                    backgroundImage:
                      pageType !== INSTANCE_TYPE_INDIVIDUAL
                        ? 'url(/image-default-3.svg)'
                        : 'url(/person-placeholder.png)',
                    backgroundSize:
                      pageType === INSTANCE_TYPE_INDIVIDUAL
                        ? '100% 100%'
                        : null,
                  }}
                />
              ) : null}
            </Stack>
            <Stack gap={1}>
              {entityTypeTitle && (
                <Box>
                  <Subtitle>{entityTypeTitle}</Subtitle>
                </Box>
              )}
              <Title title={title} />
              {subtitle || address ? (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {subtitle && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-end',
                      }}
                    >
                      {pageType === INSTANCE_TYPE_INDIVIDUAL ? (
                        <PersonIcon />
                      ) : (
                        <EventIcon />
                      )}
                      <Subtitle>{subtitle}</Subtitle>
                    </Box>
                  )}
                  {address && (
                    <Box
                      sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}
                    >
                      <HomeIcon />
                      <Subtitle>{address}</Subtitle>
                    </Box>
                  )}
                </Box>
              ) : null}
              {lastKnownCustodian && (
                <LastKnownCustodian lastKnownCustodian={lastKnownCustodian} />
              )}
              {!!links?.length && !isOnePlaceStudyHome() && (
                <SplitIndividualFamilyLinkChips links={links} sx={{ mt: 1 }} />
              )}
            </Stack>
            <Box ml="auto">
              <ShareButton onClickShare={handleShare} isPublic={true} />
            </Box>
          </Stack>
          {/* {!!description && (
            <Box sx={{ mt: 2.5, mb: 1.5, maxWidth: 780 }}>
              <Description description={description} />
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  )

  return <>{embed ? embedTitle : fullScreenTitle}</>
}
export default PublicPageTitle
