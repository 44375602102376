import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Link as MuiLink, IconButton, Box, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar } from 'src/modules/ui'

import MobileNavBar from 'src/modules/app/MobileNavBar'
import PublicTreeExplorer from './tree/PublicTreeExplorer'
import PublicAncestralFamiliesContainer from './tree/PublicAncestralFamiliesContainer'
import { useStyles } from 'src/modules/app/NavBar'
import { setLastLocation } from '../app/appSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Logo } from '../app/NavBar'
import { useEmbed } from './hooks'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'
import { usePublicTreeSettings } from '../app'

const usePublicNavStyles = makeStyles(theme => ({
  gradientLink: {
    backgroundImage:
      'linear-gradient(135deg,rgb(243,64,121) 40%,rgb(252,137,77))',
    borderRadius: 10,
    padding: theme.spacing(1.4, 3),
    width: 'fit-content',
    transitionDuration: '0.4s',
    transitionProperty: 'transform',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'At Hauss Std',
    color: '#fff',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 2),
      fontSize: 18,
    },
  },
}))

export const GradientButton = ({
  onClick,
  text,
  opacity,
  gaEvent,
  ...props
}) => {
  const classes = usePublicNavStyles()

  return (
    <div
      style={{
        opacity: opacity,
        transitionDuration: '0.5s',
        transitionTimingFunction: 'ease-out',
      }}
    >
      <button
        className={classes.gradientLink}
        onClick={() => {
          if (gaEvent) {
            sendEvent(gaEvent)
          }
          window.open('https://weare.xyz', '_blank')
        }}
      >
        {text}
      </button>
    </div>
  )
}
const PublicNavBar = ({ baseUrl }) => {
  const mobileBreakpoint = useMediaQuery(theme =>
    theme.breakpoints.down('1080px')
  )

  const embed = useEmbed()

  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { showFamilyPyramid, showNavigator } = usePublicTreeSettings()

  useEffect(() => {
    const pathname = history.location.pathname
    return () => {
      dispatch(setLastLocation(pathname))
    }
  }, [dispatch, history.location.pathname])

  return (
    <>
      {!embed && (
        <AppBar
          leftSide={
            <>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <MobileNavBar
                  isPublic={true}
                  homeUrl={`${baseUrl}/home/tree`}
                  trigger={navBarTrigger => (
                    <IconButton
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={navBarTrigger.onClick}
                      color="inherit"
                      sx={{ mr: 1 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                />
              </Box>
              <Link to={`${baseUrl}/home/tree`}>
                <Logo alt="weare.xyz" src="/logo.png" />
              </Link>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'block',
                      transitionDuration: '0.5s',
                      transitionDelay: '0.08s',
                    },
                  }}
                >
                  <MuiLink
                    to={`${baseUrl}/home/tree`}
                    component={Link}
                    sx={{ pl: 4, fontWeight: 'bold' }}
                  >
                    Home
                  </MuiLink>
                </Box>
                {showFamilyPyramid && (
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block',
                        transitionDuration: '0.5s',
                        transitionDelay: '0.04s',
                      },
                    }}
                  >
                    <PublicAncestralFamiliesContainer
                      trigger={familiesTrigger => (
                        <MuiLink
                          onClick={familiesTrigger.onClick}
                          className={classes.navBarLinkStyle}
                        >
                          Families
                        </MuiLink>
                      )}
                    />
                  </Box>
                )}
                {showNavigator && (
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block',
                        transitionDuration: '0.5s',
                      },
                    }}
                  >
                    <PublicTreeExplorer
                      navigatorMode={true}
                      trigger={navigatorTrigger => (
                        <MuiLink
                          onClick={navigatorTrigger.onClick}
                          className={classes.navBarLinkStyle}
                        >
                          Navigator
                        </MuiLink>
                      )}
                    />
                  </Box>
                )}{' '}
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'block',
                      transitionDuration: '0.5s',
                      transitionDelay: '0.08s',
                    },
                  }}
                >
                  <MuiLink
                    to={'/login'}
                    component={Link}
                    sx={{ pl: 4, fontWeight: 'bold' }}
                  >
                    Login
                  </MuiLink>
                </Box>
              </Box>
            </>
          }
          rightSide={
            <>
              {!mobileBreakpoint ? (
                <GradientButton
                  text="Find Out More"
                  gaEvent={ga4Events.PUBLIC_HEADER_BUTTON_FIND_OUT_MORE}
                />
              ) : null}
            </>
          }
        />
      )}
    </>
  )
}

export default PublicNavBar
