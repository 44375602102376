import { useCallback, useContext, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'

import 'react-image-crop/dist/ReactCrop.css'

import { Typography, IconButton } from 'src/modules/ui'
import ThumbnailGallery from './ThumbnailGallery'
import UploadPhoto from './UploadPhoto'
import YouTubeLinkDialog from './YouTubeLinkDialog'
import { MEDIA_TYPE_PHOTO } from '.'
import { WriteArticleContext } from '../writeArticle/contexts'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    display: 'flex',
    minHeight: 500,
    overflow: 'auto',
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    paddingBottom: 0,
    flexDirection: 'row',
    display: 'flex',
  },
}))

const SelectMedia = ({
  aspectRatio,
  onSelect,
  onSelectCropped,
  open = false,
  trigger,
  presetTargets = [],
  cropAfterSelect = true,
  mediaType,
  subject,
  hideTags = false,
  defaultAllowNoTags = false,
  setCategory,
  isProfileImage,
  allowSelectFromLibrary = true,
  callbacksRef,
  includeOptionalFields,
}) => {
  const classes = useStyles()

  const { config } = useContext(WriteArticleContext)

  hideTags = config?.showTags === false ? true : hideTags

  const [youtubePreview, setYoutubePreview] = useState('')
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])
  const handleCloseModal = useCallback(
    () => setModalOpen(false),
    [setModalOpen]
  )

  const handleSelect = useCallback(
    (...args) => {
      handleCloseModal()
      onSelect(...args)
    },
    [handleCloseModal, onSelect]
  )
  const handleSelectCropped = useCallback(
    (...args) => {
      handleCloseModal()
      onSelectCropped(...args)
    },
    [handleCloseModal, onSelectCropped]
  )

  const uploadNewTab = {
    label: 'Upload New',
    component: (
      <Box sx={{ m: 3, mt: 0 }}>
        <UploadPhoto
          {...{
            aspectRatio,
            onSelect: handleSelect,
            onSelectCropped: handleSelectCropped,
            cropAfterSelect,
            presetTargets,
            subject,
            mediaType,
            youtubePreview,
            hideTags,
            defaultAllowNoTags,
            setCategory,
          }}
        />
      </Box>
    ),
  }

  const selectFromLibrary = {
    label: 'From Library',
    component: (
      <Box
        sx={{
          ml: 3,
          mb: 1.5,
          mt: 0,
          mr: 2.5,
          overflow: 'auto',
          display: 'flex',
        }}
      >
        <ThumbnailGallery
          {...{
            aspectRatio,
            onSelect: handleSelect,
            onSelectCropped: handleSelectCropped,
            cropAfterSelect,
            mediaType,
            open: modalOpen,
            alignTreePreview: 'left',
            includeOptionalFields,
          }}
        />
      </Box>
    ),
  }

  let tabs = []
  if (allowSelectFromLibrary) {
    tabs = [uploadNewTab, selectFromLibrary]
  } else {
    tabs = [uploadNewTab]
  }

  const addYouTubeVideo = async youtubeVideoPreview => {
    setYoutubePreview(youtubeVideoPreview)
  }

  if (mediaType !== MEDIA_TYPE_PHOTO) {
    tabs.unshift({
      label: (
        <YouTubeLinkDialog
          open={false}
          onAddURL={addYouTubeVideo}
          trigger={props => (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              {...props}
              sx={{ mt: 2 }}
            >
              Add YouTube Video
            </Button>
          )}
        />
      ),
      component: <Box sx={{ m: 3, mt: 0 }}></Box>,
      onClick: e => {
        e.preventDefault()
      },
    })
  }

  if (callbacksRef) {
    callbacksRef.current = { handleShowModal: handleShowModal }
  }
  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={'lg'}
        fullWidth
        PaperProps={{
          sx: {
            height: '100%', // maxHeight is set in MUI and this will not override that, this just stops it jumping in size as more rows get loaded
          },
        }}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h7">
            {isProfileImage ? 'Select Profile Image' : 'Select Media'}
          </Typography>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {tabs.map(tab => (
            <div key={`media_dialog_tab_${tab.label}`}>
              <Typography variant="subtitle1">{tab.label}</Typography>
              {tab.component}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}
export default SelectMedia
