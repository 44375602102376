import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Button } from '../ui'
import { useActionDispatcher, useTreeSettings } from '../app'
import { fetchUser, selectTreeBySlug } from '../auth/authSlice'
import { makeStyles } from '@mui/styles'
import { Formik } from 'formik'
import { INSTANCE_TYPE_LOCATION } from '../app/links'

import SearchLinkType, { useSearchLinkTypes } from '../ui/editor/SearchLinkType'
import {
  ACTION_ALL_ACCESS,
  FOCUS_FAMILY_ARCHIVE,
  FOCUS_ONE_PLACE_STUDY,
} from '../app/appConstants'

import { updateTreeSettings } from './treesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { clearSearchResults } from '../writeArticle/writeArticleSlice'

const useStyles = makeStyles(theme => ({
  settingsContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const TreeSettingsButton = ({ treeSlug, manageConfig }) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const linkTypes = useSearchLinkTypes()
  const currentTree = useSelector(selectTreeBySlug(treeSlug))
  const treeSettings = useTreeSettings({ treeOverride: currentTree })
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const dispatchUpdateTreeSettings = useActionDispatcher(updateTreeSettings)
  const dispatch = useDispatch()
  const linkType = linkTypes.find(t => t.id === INSTANCE_TYPE_LOCATION)

  const performSearch = value => {
    if (linkType && linkType.fetchData) {
      linkType.fetchData({ query: value, treeSlug })
    }
  }

  const handleShowModal = () => {
    dispatch(clearSearchResults())
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const initialValues = {
    location: undefined,
    archiveFocus: treeSettings?.archiveFocus,
  }

  const handleSubmit = async values => {
    const treeSettings = {
      focus: values.archiveFocus,
      write_home_instance_id: values.location,
      write_home_instance_model: INSTANCE_TYPE_LOCATION,
    }

    await dispatchUpdateTreeSettings(
      { treeSlug, updates: treeSettings },
      {
        successNotification: 'Archive settings updated',
      }
    )
    await dispatchFetchUser()
    handleCloseModal()
  }

  return (
    <Box className={classes.settingsContainer}>
      <MenuItem onClick={handleShowModal}>
        {manageConfig.treeSettingsPrompt}
      </MenuItem>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={true}
        sx={{
          '& .MuiDialog-paper': {
            width: '30vw',
            height: '50vh',
          },
        }}
      >
        <DialogTitle>Archive Settings</DialogTitle>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
            <>
              <DialogContent sx={{ paddingTop: '1rem', width: '500px' }}>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  An archive can have a focus which sets a particular
                  configuration for an archive. By default an archive is set to
                  a <b>Family Archive</b> but you can choose to select a{' '}
                  <b>One Place Study</b>. One place studies require you to
                  select a location for the study. If you choose this the next
                  time you open the archive the home page will open to the
                  selected location.
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="archive-focus-selection-label"
                    value={values.archiveFocus}
                    onChange={e =>
                      setFieldValue('archiveFocus', e.target.value)
                    }
                  >
                    <MenuItem value={FOCUS_FAMILY_ARCHIVE}>
                      Family Archive
                    </MenuItem>
                    <MenuItem value={FOCUS_ONE_PLACE_STUDY}>
                      One Place Study
                    </MenuItem>
                  </Select>
                </FormControl>
                {values.archiveFocus === FOCUS_ONE_PLACE_STUDY && (
                  <SearchLinkType
                    treeSlugOverride={treeSlug}
                    defaultSearchValue={treeSettings?.homeInstanceTitle}
                    showBack={false}
                    linkType={linkType}
                    onSearch={performSearch}
                    onSelectLink={link => setFieldValue('location', link?.id)}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={isSubmitting}
                  onClick={() => handleCloseModal()}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </Box>
  )
}

export default TreeSettingsButton
