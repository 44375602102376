import { useCallback, useEffect, useState } from 'react'

import { Stack, TextField } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { Typography, IconButton } from 'src/modules/ui'
import { useActionDispatcher } from 'src/modules/app'
import { fetchTreeNameAvailability } from './treesSlice'
import { slugFromName } from './utils'
import { generatePublicLinkWithSiteUrl } from '../app/links'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const EditTreeName = ({
  initialName,
  onTreeNameChanged,
  isExistingTree = false,
  stopOnTreeNameChangedIfCollision = false,
}) => {
  const [name, setName] = useState(initialName)
  const slug = slugFromName(name)
  const publicUrlPreview = generatePublicLinkWithSiteUrl(`/public/${slug}`)

  const [nameCollision, setNameCollision] = useState(false)
  const dispatchFetchTreeNameAvailability = useActionDispatcher(
    fetchTreeNameAvailability
  )

  const handleNameChanged = useCallback(
    async e => {
      const newName = e.target.value
      setName(newName)

      const isInitial = newName === initialName
      let shouldCheck = !!newName
      if (isExistingTree) {
        shouldCheck = !isInitial
      }

      if (shouldCheck) {
        const nameAvailability = await dispatchFetchTreeNameAvailability({
          name: newName,
          slug: slugFromName(newName),
        }).unwrap()
        const nameAvailable =
          nameAvailability.nameAvailable && nameAvailability.slugAvailable
        setNameCollision(!nameAvailable)
      } else {
        setNameCollision(false)
      }
    },
    [dispatchFetchTreeNameAvailability, initialName, isExistingTree]
  )

  // Check once with initial name
  useEffect(() => {
    if (initialName) {
      handleNameChanged({ target: { value: initialName } })
    }
  }, [handleNameChanged, initialName])

  const handleTreeNameChanged = useCallback(() => {
    onTreeNameChanged && onTreeNameChanged(name)
  }, [name, onTreeNameChanged])

  return (
    <Stack sx={{ width: '100%' }}>
      <TextField
        name={'EditTreeName'}
        value={name}
        onChange={handleNameChanged}
        onBlur={handleTreeNameChanged}
        error={stopOnTreeNameChangedIfCollision && nameCollision}
        helperText={nameCollision ? 'That name is taken' : ''}
        InputProps={{
          style: { fontSize: '1.5rem' },
          endAdornment: (
            <IconButton
              disabled={nameCollision}
              name={'EditTreeNameCheck'}
              permissionAction={ACTION_ALL_ACCESS}
              size="small"
              sx={{ mb: 1 }}
              onClick={handleTreeNameChanged}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          ),
        }}
      ></TextField>

      <Typography sx={{ color: nameCollision ? 'red' : 'auto' }}>
        Public URL preview: {publicUrlPreview}
      </Typography>
    </Stack>
  )
}
