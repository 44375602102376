import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import FactsList from '../../page/FactsList'
import PublicIndividualRelationships from '../tree/PublicIndividualRelationships'
import { selectPublicIndividualById } from '../tree/treeSlice'
import { fetchPublicPageFacts, selectPublicPageFacts } from './pageSlice'
import { useActionDispatcher } from '../../app'
import { PublicContext } from '../contexts'

export default function PublicFacts() {
  const publicPageFacts = useSelector(selectPublicPageFacts)
  const { linkedPageId } = useParams()
  const dispatchFetchPublicPageFacts = useActionDispatcher(fetchPublicPageFacts)
  const { treeSlug } = useContext(PublicContext)

  const individual = useSelector(selectPublicIndividualById(linkedPageId))

  useEffect(() => {
    dispatchFetchPublicPageFacts({ target: linkedPageId, treeSlug })
  }, [linkedPageId, treeSlug, dispatchFetchPublicPageFacts])

  if (dispatchFetchPublicPageFacts.status === 'loading') {
    return <Box>Loading...</Box>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <FactsList
        isPublic={true}
        facts={publicPageFacts}
        individual={individual}
      />
      {individual && <PublicIndividualRelationships individual={individual} />}
    </Box>
  )
}
