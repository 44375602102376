import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dialog } from '@mui/material'
import { fetchMap } from './mapSlice'

import { useParams, useHistory } from 'react-router-dom'
import Map from './Map'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { fetchPublicMap } from 'src/modules/public/page/pageSlice'
import { PublicContext } from 'src/modules/public/contexts'
import { usePublicPageUrlSegments } from 'src/modules/public/page/hooks'

const MapPage = () => {
  let { id } = useParams()
  const publicParams = usePublicPageUrlSegments()
  const publicContext = useContext(PublicContext)
  let fetchMapApi = fetchMap
  if (publicContext) {
    fetchMapApi = fetchPublicMap
    id = publicParams.linkedPageId
  }

  const history = useHistory()
  const [currentMap, setCurrentMap] = useState({})

  let treeSlug = useSelector(selectAuthorisedTreeSlug)
  if (publicContext) {
    treeSlug = publicContext.treeSlug
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      const fn = async () => {
        console.debug(
          `DEBUG MapPage: calling dispatchFetchMap(mapId: ${id})...`
        )
        const dispatchResponse = await dispatch(fetchMapApi({ id, treeSlug }))

        console.debug(
          `MapPage: dispatchFetchMap(mapId: ${id}) returned dispatchResponse:`,
          dispatchResponse
        )
        setCurrentMap(dispatchResponse.payload)

        console.debug(
          `MapPage: dispatchFetchMap(mapId: ${id}) returned, called setCurrentMap() with dispatchResponse.payload:`,
          dispatchResponse.payload
        )
      }

      fn()
    }
  }, [dispatch, id, treeSlug, fetchMapApi])

  //console.debug(`MapPage: rendering with id: ${id}, currentMap:`, currentMap)

  return (
    <>
      <Dialog
        open={true}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: 'black',
          },
        }}
      >
        <>
          {((currentMap && Object.keys(currentMap).length > 0) || !id) && (
            <Map
              currentMap={currentMap}
              isEditing={false}
              closeMap={(params, reduceMapToInline) => {
                console.debug(`MapPage.Map.closeMap() called. params:`, params)

                // don't need to call reduceMapToInline() as we're leaving this page

                history.goBack()
              }}
              onSave={(updatedPayload, reduceMapToInline) => {
                console.debug(
                  `MapPage.Map.onSave() called, map saved, closing... updatedPayload:`,
                  updatedPayload
                )
                if (updatedPayload && !('status' in updatedPayload)) {
                  history.goBack()
                }
              }}
              initialMapIsFullWindow={true}
              initialInteractive={true}
              addSearchBoxMapControl={true}
              treeSlug={treeSlug}
            />
          )}
        </>{' '}
      </Dialog>
    </>
  )
}

export default MapPage
