import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'

import { UserContext } from './Users'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

const UsersByAlphabet = ({ users = [], availableRoles = [] }) => {
  const classes = useStyles()

  const { IndividualComponent } = useContext(UserContext)

  const sortedUsers = users.slice().sort((a, b) => {
    const result = a.surname.localeCompare(b.surname)
    return result !== 0 ? result : a.givenName.localeCompare(b.givenName)
  })

  return (
    <div className={classes.root}>
      {sortedUsers.map((u, idx) => (
        <IndividualComponent
          key={`alphaSection-${idx}-${u.id}`}
          individual={u}
          availableRoles={availableRoles}
        />
      ))}
    </div>
  )
}

export default UsersByAlphabet
