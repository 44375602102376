import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchHomePhotos,
  selectHomePhotos,
  setHomeViewConfig,
} from './homeSlice'
import { MediaList } from 'src/modules/photo'
import { ActionButtons } from './HomeContentList'
import { INSTANCE_TYPE_MEDIA } from '../app/links'
import { getViewConfig } from '../common/viewConfigUtils'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import { useTheme } from '@mui/material/styles'
import Refresh from '../ui/Refresh'
import CreateMultiplePhotosDialog from '../photo/CreateMultiple'
import useMediaQuery from '@mui/material/useMediaQuery'
import { UploadMediaButton } from '../ui/actionButtons'

const HomePhotoList = () => {
  const theme = useTheme()
  const isLessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const dispatchFetchHomePhotos = useActionDispatcher(fetchHomePhotos)
  const media = useSelector(selectHomePhotos)
  const type = INSTANCE_TYPE_MEDIA

  useEffect(() => {
    dispatchFetchHomePhotos({ page: 0 })
  }, [dispatchFetchHomePhotos])

  const onFetchMore = () => {
    if (dispatchFetchHomePhotos.status === 'loading') {
      return
    }
    dispatchFetchHomePhotos({ page: media.page + 1 })
  }

  const refreshMedia = () => {
    dispatchFetchHomePhotos({ page: 0 })
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setHomeViewConfig({ type: INSTANCE_TYPE_MEDIA, viewConfig }))
    dispatchFetchHomePhotos({ page: 0 })
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(media)

  return (
    <>
      {media?.results?.length > 0 && (
        <ActionButtons>
          <div>
            <HomeViewConfigSelectorContainer
              type={type}
              sortValue={sort}
              hierarchicalValue={hierarchical}
              ancestralOnlyValue={ancestralOnly}
              handleChange={handleSetViewConfig}
            />
          </div>
          <div>
            <CreateMultiplePhotosDialog
              onFinishedUploading={refreshMedia}
              presetTargets={[]}
              defaultAllowNoTags={isLessThanSmall}
              trigger={props => <UploadMediaButton {...props} />}
            />
            <Refresh onClick={() => refreshMedia()} />
          </div>
        </ActionButtons>
      )}
      <MediaList
        onFetchMore={onFetchMore}
        media={media}
        status={dispatchFetchHomePhotos.status}
        refreshMedia={refreshMedia}
      />
    </>
  )
}

export default HomePhotoList
