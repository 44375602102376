import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { HomeViewConfigSelectorContainer } from '../page/ViewConfigSelectorContainer'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../common/viewConfigUtils'
import { INSTANCE_TYPE_FAMILY } from '../app/links'
import { Box } from '@mui/material'
import { ActionsContainer } from '../page/LinkedPageList'
import { UpdateIndividualRelationshipsListDialog } from '../viewer/UpdateIndividualRelationships'
import { selectIndividualById } from '../viewer/viewerSlice'
import Refresh from '../ui/Refresh'
import Typography from '@mui/material/Typography'
import IndividualSearchBox from '../ui/IndividualSearchBox'
import BaseFamilyList from '../page/BaseFamilyList'
import { useLinkedPageTargets } from './hooks'
import {
  fetchLinkedFamilies,
  selectLinkedFamilies,
  setViewConfig,
} from './pageSlice'
import {
  selectHomeIndividualsSearchTerm,
  setHomeIndividualsSearchTerm,
} from '../home/homeSlice'
import useAddIndividualControl from './useAddIndividualControl'

const PageFamilies = ({ pageType }) => {
  const { target, presetTargets } = useLinkedPageTargets()

  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatch = useDispatch()
  const dispatchFetchLinkedFamilies = useActionDispatcher(fetchLinkedFamilies)
  const linkedFamilies = useSelector(selectLinkedFamilies)
  const { next, results, updated } = linkedFamilies
  const type = INSTANCE_TYPE_FAMILY

  const {
    addIndividualControl,
    setSelectedIndividualId,
    selectedIndividualId,
  } = useAddIndividualControl({ target, presetTargets, pageType })
  var individual = useSelector(selectIndividualById(selectedIndividualId))

  const makeArgs = useCallback(
    page => {
      const args = { target, page, type, viewConfigQueryParams }
      return args
    },
    [type, viewConfigQueryParams, target]
  )

  useEffect(() => {
    const fetchLinkedFamilies = async () => {
      await dispatchFetchLinkedFamilies(makeArgs(0))
    }
    if (!results.length) {
      fetchLinkedFamilies()
    }
  }, [dispatchFetchLinkedFamilies, makeArgs, type, results.length, updated])

  const refresh = () => {
    dispatchFetchLinkedFamilies(makeArgs(0))
  }

  const handleFetchMore = () => {
    if (dispatchFetchLinkedFamilies.status === 'loading') {
      return
    }
    dispatchFetchLinkedFamilies(makeArgs(linkedFamilies.page + 1))
  }

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setViewConfig({ type, viewConfig }))
    dispatchFetchLinkedFamilies(makeArgs(0))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    linkedFamilies,
    viewConfigQueryParams
  )

  const search = useCallback(() => {
    dispatchFetchLinkedFamilies(makeArgs(0))
  }, [makeArgs, dispatchFetchLinkedFamilies])

  return (
    <>
      <ActionsContainer>
        <HomeViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={handleSetViewConfig}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <IndividualSearchBox
            searchTermAction={setHomeIndividualsSearchTerm}
            selector={selectHomeIndividualsSearchTerm}
            onChange={search}
            onClear={refresh}
            busy={dispatchFetchLinkedFamilies.status === 'loading'}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {addIndividualControl}
          <Refresh onClick={refresh} />
        </Box>
      </ActionsContainer>
      <BaseFamilyList
        {...{
          dispatchFetchFamilies: dispatchFetchLinkedFamilies,
          next,
          handleFetchMore,
          families: linkedFamilies,
          results,
          type,
        }}
      />
      <UpdateIndividualRelationshipsListDialog
        individual={individual}
        isLoadingOveride={true}
        onCloseParentDialog={() => setSelectedIndividualId(null)}
      />
    </>
  )
}

export default PageFamilies
