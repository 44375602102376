import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectTreeLoading,
  selectUser,
  selectUserIndividualOnTree,
} from '../auth/authSlice'
import PinnedArticle, {
  PINNED_ARTICLE_DISPLAY_CONFIG,
  PinnedArticleButtons,
} from '../page/PinnedArticle'
import {
  fetchHomePageArticleId,
  resetHomePageState,
  selectHomePageArticle,
  setHomePageArticle,
} from './homeSlice'
import { useActionDispatcher } from '../app'
import { INSTANCE_TYPE_TREE } from '../app/links'

const HOME_PINNED_ARTICLE_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeaderAuthor: false,
  showInLineHeaderShare: false,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: false,
  inLineEdit: true,
}

const HomePinnedArticle = () => {
  const user = useSelector(selectUser)
  const userIndividualOnTree = useSelector(selectUserIndividualOnTree)
  const dispatchFetchArticleId = useActionDispatcher(fetchHomePageArticleId)
  const cachedPinnedArticleData = useSelector(selectHomePageArticle)
  const treeLoading = useSelector(selectTreeLoading)

  useEffect(() => {
    if (
      !cachedPinnedArticleData.articleId &&
      cachedPinnedArticleData?.loading &&
      !treeLoading
    ) {
      dispatchFetchArticleId()
    }
  }, [
    dispatchFetchArticleId,
    cachedPinnedArticleData.articleId,
    cachedPinnedArticleData?.loading,
    treeLoading,
  ])

  if (!user) {
    return <>Missing User!!</>
  }

  return (
    <>
      <PinnedArticle
        cachedPinnedArticleData={cachedPinnedArticleData}
        onArticleLoaded={setHomePageArticle}
        config={HOME_PINNED_ARTICLE_CONFIG}
        pinTarget={user?.currentTree.id}
      >
        <>
          <PinnedArticleButtons
            createButtonText={'Create Welcome Article'}
            pinTarget={user?.currentTree}
            pinTargetType={INSTANCE_TYPE_TREE}
            onArticleChanged={resetHomePageState}
            presetTargets={[userIndividualOnTree?.id]}
            showPinButton={false}
          />
        </>
      </PinnedArticle>
    </>
  )
}

export default React.memo(HomePinnedArticle)
