import { CreateMapButton } from '../ui/actionButtons'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useActionDispatcher } from '../app'
import { fetchHomeMaps } from '../home/homeSlice'
import SimpleDialog from '../ui/SimpleDialog'
import Map from '../map/Map'
import { WeAreMap } from '../map/MapEdit'

// used in HomeMapList
export const CreateMapDialog = ({ trigger, onFinishedUploading, treeSlug }) => {
  return (
    <SimpleDialog
      fullScreen
      title="Map"
      onClose={() => onFinishedUploading()}
      trigger={trigger}
    >
      {({ closeDialog }) => (
        <Map
          initialIsEditing={true}
          initialInteractive={true}
          closeMap={params => {
            closeDialog()
          }}
          currentMap={new WeAreMap()}
          initialMapIsFullWindow={true}
          allowThreeD={false}
          addSearchBoxMapControl={true}
          onSave={(updatedPayload, closeMap) => {
            console.debug(
              `AddMapControl.CreateMapDialog.onSave() called, map saved, closing... updatedPayload:`,
              updatedPayload
            )
            if (updatedPayload && !('status' in updatedPayload)) {
              closeDialog()
            } else {
              console.error(
                `AddMapControl.CreateMapDialog.onSave() looks like map did not create successfully:`,
                updatedPayload
              )
            }
          }}
          treeSlug={treeSlug}
        />
      )}
    </SimpleDialog>
  )
}

const AddMapControl = () => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  const dispatchHomeMaps = useActionDispatcher(fetchHomeMaps)
  const refreshMaps = () => {
    dispatchHomeMaps()
  }

  return (
    <>
      <CreateMapDialog
        onFinishedUploading={refreshMaps}
        trigger={props => <CreateMapButton {...props} />}
        treeSlug={authorisedTreeSlug}
      />
    </>
  )
}

export default AddMapControl
