import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageContext } from 'src/modules/page/pageContexts'
import {
  fetchLinkedPageItem,
  selectLinkedPageItem,
  updateLinkedPageItem,
} from 'src/modules/page/pageSlice'
import { useActionDispatcher } from 'src/modules/app/hooks'
import { LoadingIndicator } from 'src/modules/ui'
import InstanceVisibilityControl from 'src/modules/visibility/InstanceVisibilityControl'

import Page from './Page'
import HtmlHeader from 'src/modules/app/HtmlHeader'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_ARTICLE,
} from 'src/modules/app/links'
import { convertUIGedDate } from '../common/gedcomDateParser'
import { resetPageState } from './pageSlice'

export const PAGE_DISPLAY_NAME_SINGULAR = {
  [INSTANCE_TYPE_ARTEFACT]: 'Artifact',
  [INSTANCE_TYPE_EVENT]: 'Occasion',
  [INSTANCE_TYPE_FAMILY]: 'Family',
  [INSTANCE_TYPE_INDIVIDUAL]: 'Individual',
  [INSTANCE_TYPE_LOCATION]: 'Place',
  [INSTANCE_TYPE_ARTICLE]: 'Article',
}

export const titleFromPageType = ({ instanceType }) =>
  PAGE_DISPLAY_NAME_SINGULAR[instanceType]

const LinkedPage = ({ routes, pageType, linkedPageId, backButton }) => {
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchLinkedPageItem)
  const dispatchUpdateLinkedPageItem = useActionDispatcher(updateLinkedPageItem)
  const dispatch = useDispatch()

  const handleUpdateLinkedPageitem = photo => {
    dispatchUpdateLinkedPageItem({ pageType: pageType, linkedPageId, photo })
  }

  useEffect(() => {
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    dispatchFetchLinkedPageItem({
      pageType,
      linkedPageId,
    })
  }, [dispatchFetchLinkedPageItem, pageType, linkedPageId])

  return (
    <>
      {dispatchFetchLinkedPageItem.status === 'loading' ||
      fetchedLinkedPage.id !== linkedPageId ? (
        <LoadingIndicator />
      ) : (
        <LinkedPageDisplay
          fetchedLinkedPage={fetchedLinkedPage}
          routes={routes}
          linkedPageId={linkedPageId}
          pageType={pageType}
          handleUpdateLinkedPageitem={handleUpdateLinkedPageitem}
          backButton={backButton}
        />
      )}
    </>
  )
}

/*
 in some instances we need to grab the linkedPageItem further up the compopnent tree so use this in that case e.g events
 */
export const LinkedPageWithItem = ({
  routes,
  pageType,
  linkedPageId,
  backButton,
  fetchedLinkedPage,
}) => {
  const dispatch = useDispatch()
  const dispatchUpdateLinkedPageItem = useActionDispatcher(updateLinkedPageItem)
  const handleUpdateLinkedPageitem = photo => {
    dispatchUpdateLinkedPageItem({ pageType: pageType, linkedPageId, photo })
  }

  useEffect(() => {
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  return (
    <>
      {fetchedLinkedPage?.id !== linkedPageId ? (
        <LoadingIndicator />
      ) : (
        <LinkedPageDisplay
          fetchedLinkedPage={fetchedLinkedPage}
          routes={routes}
          linkedPageId={linkedPageId}
          pageType={pageType}
          handleUpdateLinkedPageitem={handleUpdateLinkedPageitem}
          backButton={backButton}
        />
      )}
    </>
  )
}

const LinkedPageDisplay = ({
  fetchedLinkedPage,
  routes,
  linkedPageId,
  pageType,
  handleUpdateLinkedPageitem,
  backButton,
}) => {
  const title = fetchedLinkedPage.title
  const entityTypeTitle = titleFromPageType({ pageType })

  const photoUrl = fetchedLinkedPage.photo?.fileThumbnail
  const headerProps = {
    title: title,
    type: 'website',
    url: window.location.href,
  }

  const handleSubtitle = subtitleStr => {
    let subtitle = subtitleStr || null
    if (subtitle) {
      subtitle = convertUIGedDate(subtitle)
    }
    return subtitle
  }

  return (
    <PageContext.Provider
      value={{
        pageType: pageType,
        pageObject: fetchedLinkedPage,
      }}
    >
      <HtmlHeader {...headerProps} />
      <Page
        fullWidthSubRootContainer={true}
        routes={routes}
        targetId={linkedPageId}
        target={fetchedLinkedPage}
        title={title}
        description={fetchedLinkedPage.description}
        address={fetchedLinkedPage.textAddress}
        locations={fetchedLinkedPage.locations}
        type={pageType}
        photoUrl={photoUrl}
        entityTypeTitle={entityTypeTitle}
        handleSelect={handleUpdateLinkedPageitem}
        backButton={backButton}
        lastKnownCustodian={fetchedLinkedPage.lastKnownCustodian}
        subtitle={handleSubtitle(fetchedLinkedPage?.dateOccurredGed)}
        author={fetchedLinkedPage?.author}
        editComponent={
          <InstanceVisibilityControl
            id={linkedPageId}
            autho={fetchedLinkedPage?.author}
            type={pageType}
            visibility={fetchedLinkedPage.visibility}
            iconSize="medium"
          />
        }
      />
    </PageContext.Provider>
  )
}

export default LinkedPage
