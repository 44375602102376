import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import { fetchHomeContentAlbums, selectHomeContentAlbums } from './homeSlice'
import { NavLink } from 'src/modules/ui'

import HomePhotoList from './HomePhotoList'
import {
  generateTreeRoute,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from 'src/modules/app/links'

import { LoadingIndicator } from '../ui'
import { generateTreeLink } from '../app/links'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

import HomeAlbumList from './HomeAlbumList'

const PHOTO_PATH = 'home/media/all'
const ALBUM_PATH = 'home/media/albums'
const MEDIA_ROOT_PATH = 'home/media'

const MEDIA_ROUTE = generateTreeRoute(MEDIA_ROOT_PATH)
const PHOTO_ROUTE = generateTreeRoute(PHOTO_PATH)
const ALBUM_ROUTE = generateTreeRoute(ALBUM_PATH)
const PHOTO_TAB_NUMBER = 0
const ALBUM_TAB_NUMBER = 1

const TabPanel = ({ children, tabIndex, tabNumber }) => {
  if (tabIndex === tabNumber) {
    return <div>{children}</div>
  } else {
    return null
  }
}

const Photos = () => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  const matchMediaRootRoute = useRouteMatch(MEDIA_ROUTE)
  const matchPhotoListRoute = useRouteMatch(PHOTO_ROUTE)
  const matchAlbumRoute = useRouteMatch(ALBUM_ROUTE)

  const dispatch = useDispatch()
  const albums = useSelector(selectHomeContentAlbums)
  const [loading, setLoading] = React.useState(true)
  const [tabIndex, setTabIndex] = React.useState(PHOTO_TAB_NUMBER)

  useEffect(() => {
    if (matchMediaRootRoute?.isExact & loading) {
      if (albums.results.length > 0) {
        setTabIndex(ALBUM_TAB_NUMBER)
        setLoading(false)
      } else {
        dispatch(
          fetchHomeContentAlbums({
            page: 0,
            sort: '-published_at',
            type: INSTANCE_TYPE_PHOTO_ALBUM,
          })
        ).then(response => {
          const results = response?.payload?.results || []
          if (results.length > 0) {
            setTabIndex(ALBUM_TAB_NUMBER)
          } else {
            setTabIndex(PHOTO_TAB_NUMBER)
          }
          setLoading(false)
        })
      }
    } else if (matchPhotoListRoute?.isExact) {
      setTabIndex(PHOTO_TAB_NUMBER)
      setLoading(false)
    } else if (matchAlbumRoute?.isExact) {
      setTabIndex(ALBUM_TAB_NUMBER)
      setLoading(false)
    }
  }, [
    setTabIndex,
    matchMediaRootRoute,
    matchPhotoListRoute,
    matchAlbumRoute,
    setLoading,
    dispatch,
    albums,
    loading,
  ])

  if (loading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink
              isActive={() => tabIndex === PHOTO_TAB_NUMBER}
              to={generateTreeLink(authorisedTreeSlug, PHOTO_PATH)}
            >
              All Media
            </NavLink>
            <NavLink
              isActive={() => tabIndex === ALBUM_TAB_NUMBER}
              to={generateTreeLink(authorisedTreeSlug, ALBUM_PATH)}
            >
              Albums
            </NavLink>
          </Stack>
        </Box>
      </Stack>

      <TabPanel tabIndex={tabIndex} tabNumber={PHOTO_TAB_NUMBER}>
        <HomePhotoList />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} tabNumber={ALBUM_TAB_NUMBER}>
        <HomeAlbumList />
      </TabPanel>
    </div>
  )
}

export default Photos
