import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { customButtonSupport } from './ExtendedMapboxDraw'

const MapboxDrawConstants = MapboxDraw.constants
const MapboxDrawLib = MapboxDraw.lib

const debug = false

// no point making this an instance of a Class because MapboxDraw's object_to_mode.js
// extracts all the functions from this and copies them to a new ModeInterface instance
const AddMarkerMode = {
  // called when the user clicks on the toolbar button
  onSetup: function (opts) {
    const state = {}
    this.onMapClick = opts.onMapClick

    // other Modes call this.updateUIClasses to change the mouse pointer over the map:
    // this.updateUIClasses({ mouse: Constants.cursors.ADD })
    // but this.updateUIClasses is defined in mapbox-gl-draw/src/modes/mode_interface_accessors.js
    // and calls _ctx.ui.queueMapClasses()
    // nothing happens until updateMapClasses() is called and I can't see what does that
    // So instead call our own function:
    customButtonSupport.changeMapMousePointer(
      this.map,
      MapboxDrawConstants.cursors.ADD
    )

    //this.activateUIButton('marker')
    // activateUIButton() is defined in mapbox-gl-draw/src/modes/mode_interface_accessors.js
    // it calls _ctx.ui.setActiveButton(name) which looks in its object 'buttonElements'
    // for the element with the passed name as key.
    // it adds CSS Constants.classes.ACTIVE_BUTTON to the buttonElement.classList
    // activateUIButton doesn't work here because this button isn't in _ctx.ui's buttonElements dict
    // So ExtendedMapboxDraw has a workaround - the CSS class is added on button click
    // if the buttonConfig has highlightButton: true

    this.clearSelectedFeatures()

    return state
  },

  stopDrawingAndRemove: function (state) {
    if (debug) console.debug(`AddMarkerMode.stopDrawingAndRemove(): called`)

    this.activateUIButton()

    customButtonSupport.changeMapMousePointer(
      this.map,
      MapboxDrawConstants.cursors.ADD,
      false
    )

    this.changeMode(MapboxDrawConstants.modes.SIMPLE_SELECT)
  },

  //onTap
  onClick: function (state, e) {
    if (debug)
      console.debug(
        `AddMarkerMode.onClick(): called with state: ${state} and e:`,
        e
      )

    this.onMapClick(e.lngLat)

    this.stopDrawingAndRemove(state)
  },

  // toDisplayFeatures needs to be here even though it's just a default implementation that
  // does nothing, otherwise MapboxDraw complains
  toDisplayFeatures: function (state, geojson, display) {
    console.debug(
      `AddMarkerMode.toDisplayFeatures(): called with geojson`,
      geojson
    )
    return display(geojson)
  },

  onKeyUp: function (state, e) {
    if (
      MapboxDrawLib.CommonSelectors.isEscapeKey(e) ||
      MapboxDrawLib.CommonSelectors.isEnterKey(e)
    ) {
      return this.stopDrawingAndRemove(state, e)
    }
  },

  // AddMarkerMode.onMouseMove = function (state, e) {
  //   //const features = this.featuresAt(e);
  //   this.map.getCanvas().style.cursor = 'crosshair'
  // }
}

export default AddMarkerMode
