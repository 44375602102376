import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'

import { selectUser } from 'src/modules/auth/authSlice'
import { useActionDispatcher } from 'src/modules/app'
import { setLinkablePageOpen } from 'src/modules/informationRequest/informationRequestSlice'
import { selectLinkedPageItem } from 'src/modules/page/pageSlice'
import { Container } from 'src/modules/ui'

import { fetchArticle, fetchArticleStub, selectContent } from './contentSlice'
import ArticleHeader from './ArticleHeader'
import ArticleBody from './ArticleBody'
import ArticleTags from './ArticleTags'
import Comments from './Comments'
import { Sources } from './SourcesUI'
import HtmlHeader from '../app/HtmlHeader'
import clip from 'text-clipper'
import parse from 'html-react-parser'
import { makeParseOptions } from './parseOptions'
import { formatIndividualName } from '../ui/individualUtils'
import { INSTANCE_TYPE_ARTICLE, PATH_SEGMENT_DOCUMENT } from '../app/links'
import { clearExploredNode } from '../viewer/exploreTreeSlice'
import { LoadingIndicator } from '../ui'
import { Divider } from '@mui/material'
import ArticleInLineHeader from './ArticleInLineHeader'
import { Box } from '@mui/material'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

import { convertUIGedDate } from '../common/gedcomDateParser'
import { useLocation, useRouteMatch } from 'react-router-dom'

const DEFAULT_BLOCK_LIMIT = 2

export const blockquoteStyles = {
  borderLeft: '4px solid #ccc',
  paddingLeft: 4,
}

export const titleStyles = {
  color: '#240048',
  fontFamily: 'FS Split Serif',
  fontSize: '3rem',
  margin: 0,
  fontWeight: 300,
  textAlign: 'left',
}
export const subtitleStyles = {
  color: '#7D737D',
  fontWeight: 400,
  fontSize: '1.5rem',
  margin: 0,
  fontFamily: 'At Hauss Std',
}

export const bodyTextStyles = {
  fontFamily: 'IBM Plex Sans',
  fontWeight: 300,
  fontSize: '1.25rem',
  lineHeight: '2rem',
}

export const imageCaptionStyles = {
  fontFamily: 'At Hauss Std',
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
}

const useStyles = makeStyles(theme => ({
  author: {
    marginBottom: theme.spacing(0),
  },
  comments: {
    maxWidth: 600,
  },
  commentsTitle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  date: {
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const DEFAULT_ARTICLE_DISPLAY_CONFIG = {
  showHTMLHeaders: true,
  showFullHeader: true,
  showInLineHeader: false,
  showInLineHeaderAuthor: true,
  showInLineHeaderShare: true,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: true,
  showBackGround: true,
  showComments: true,
  showTags: true,
  showSources: true,
  showBackButton: true,
  fullScrollOffset: false,
}

export const Article = ({
  article,
  className,
  user,
  fetchedLinkedPage,
  loading,
  config,
  handleUnPinArticle,
  onArticleEdit,
}) => {
  const { comments, contentBlocks, id, links } = article

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const classes = useStyles()
  const allSources = contentBlocks
    .flatMap(block => block.sources)
    .filter(n => n)

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none'

    return () => {
      document.body.style.overscrollBehaviorX = 'auto'
    }
  }, [])

  const subtitle = article.dateOfOriginGed
    ? convertUIGedDate(article.dateOfOriginGed)
    : null

  return (
    <Container className={className} showBackGround={config.showBackGround}>
      <div>
        {config.showFullHeader && (
          <ArticleHeader
            article={article}
            config={config}
            subtitle={subtitle}
            subtitleContainerMb={{ xs: 4, md: 2 }}
          />
        )}
        {config.showInLineHeader && (
          <ArticleInLineHeader
            article={article}
            config={config}
            handleUnPinArticle={handleUnPinArticle}
            onArticleEdit={onArticleEdit}
          />
        )}
        <ArticleBody
          contentBlocks={contentBlocks}
          contentId={id}
          config={config}
          loading={loading}
          treeSlug={treeSlug}
          contentType={article.type}
          // mediaDetailEditDialogTitle={}
          // mediaDetailShowTranscription={}
          // mediaDetailShowTakenOnDate={}
          // mediaDetailHideTags={}
          // mediaDetailDefaultAllowNoTags={}
        />
        {loading ? <LoadingIndicator /> : null}
        {config.showSources && allSources && allSources.length > 0 && (
          <>
            <Divider variant="middle" sx={{ mb: 1, ml: 0, mr: 0 }} />
            <Sources
              sources={allSources}
              contentBlockId={undefined}
              contentId={id}
              editMode={false}
              collapsible={false}
              initiallyExpanded={false}
              collatePhotos={true}
            />
          </>
        )}
        {config.showTags && <ArticleTags links={links} />}
      </div>
      {config.showComments && (
        <Comments
          canComment
          comments={comments}
          className={classes.comments}
          contentId={id}
          user={user}
          initialPageSize={5}
        />
      )}
    </Container>
  )
}

const ArticleContainer = ({
  match,
  articleId,
  config = DEFAULT_ARTICLE_DISPLAY_CONFIG,
  onArticleLoaded,
  handleUnPinArticle,
  cachedArticle,
  onArticleEdit,
  sx = {},
}) => {
  const id = articleId ? articleId : match?.params?.id
  const location = useLocation()
  const isDocumentPath = location.pathname.includes(PATH_SEGMENT_DOCUMENT)

  /* articles could come from another tree after the introduction of blogs so grab the tree from the url */
  const {
    params: { treeSlug },
  } = useRouteMatch('/:treeSlug')

  const dispatchFetchArticle = useActionDispatcher(fetchArticle)
  const dispatchFetchArticleStub = useActionDispatcher(fetchArticleStub)
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  let article = useSelector(selectContent)
  article = article ? article : cachedArticle?.article
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  const descriptionForHeader = useMemo(() => {
    const textContentBlocks = (article?.contentBlocks || []).filter(
      block => block.type === 'TEXT'
    )
    let textContent = ''
    textContentBlocks.forEach(block => {
      textContent += block.textContent
    })
    if (!textContent) {
      return ''
    }

    const options = makeParseOptions({
      contentId: article.id,
      removeFormatting: true,
    })

    const text = parse(clip(textContent, 200, { html: true, maxLines: 3 }), {
      ...options,
      replace: domNode => {
        if (domNode.name === 'blockquote') {
          domNode.name = 'p'
        }
      },
    })

    return text.props?.children || ''
  }, [article])

  // We don't want explored node to show up on article's subtrees
  useEffect(() => {
    dispatch(clearExploredNode())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
   do a stub load first and then another full load if you get the same number of blocks as the DEFAULT_BLOCK_LIMIT
   various cruft here to detect loading which I am not thrilled about
   */
  useEffect(() => {
    const fetchArticle = async () => {
      await dispatchFetchArticle({ id: id, treeSlug: treeSlug })
      if (onArticleLoaded) {
        dispatch(onArticleLoaded(article))
      }
      setLoading(false)
    }

    const block_limit = isDocumentPath ? 1 : DEFAULT_BLOCK_LIMIT

    const contentBlockCount = article
      ? article.contentBlocks?.length
      : undefined
    if (dispatchFetchArticle.status === 'fulfilled' && article?.id === id) {
      setLoading(false)
    } else if (contentBlockCount === undefined || article.id !== id) {
      dispatchFetchArticleStub({
        id: id,
        treeSlug: treeSlug,
        block_limit: block_limit,
      })
    } else if (contentBlockCount === block_limit) {
      fetchArticle()
    } else {
      setLoading(false)
    }
  }, [
    dispatch,
    onArticleLoaded,
    dispatchFetchArticle,
    dispatchFetchArticleStub,
    id,
    article,
    setLoading,
    treeSlug,
    isDocumentPath,
  ])

  useEffect(() => {
    if (article) {
      dispatch(
        setLinkablePageOpen({
          target: article.id,
          instanceType: INSTANCE_TYPE_ARTICLE,
          display: article.title,
        })
      )
    }
    return () => dispatch(setLinkablePageOpen())
  }, [dispatch, article])

  if (!article || article.id !== id) {
    return (
      <Container showBackGround={config.showBackGround}>
        <LoadingIndicator />
      </Container>
    )
  }

  const headerProps = {
    title: article.title,
    type: 'article',
    image: article.previewThumbnail,
    url: window.location.href,
    description: descriptionForHeader,
    published_time: article.publishedAt,
    modified_time: article.modified,
    author: formatIndividualName(article.author),
  }

  return (
    <Box sx={sx}>
      {config.showHTMLHeaders && <HtmlHeader {...headerProps} />}
      <Article
        article={article}
        user={user}
        loading={loading}
        config={config}
        fetchedLinkedPage={fetchedLinkedPage}
        handleUnPinArticle={handleUnPinArticle}
        onArticleEdit={onArticleEdit}
      />
    </Box>
  )
}

export default ArticleContainer
