import { CreateAlbumButton } from '../ui/actionButtons'
import CreateAlbumDialog from '../photo/CreateAlbum'
import React from 'react'
import { INSTANCE_TYPE_PHOTO_ALBUM } from '../app/links'
import { fetchPageContentAlbums } from './pageSlice'
import { useActionDispatcher } from '../app'
import { fetchHomeContentAlbums } from '../home/homeSlice'

/*
 had to refactor pages to so that empty state can have create buttons etc so added the create to here with associated dispatch
 */
const AddAlbumControl = ({ presetTargets, linkedPageId }) => {
  const dispatchFetchLinkedAlbums = useActionDispatcher(fetchPageContentAlbums)
  const dispatchFetchHomeAlbums = useActionDispatcher(fetchHomeContentAlbums)
  const handleOnFinishedUploadingPhotos = () => {
    if (linkedPageId) {
      dispatchFetchLinkedAlbums({
        target: linkedPageId,
        page: 0,
        sort: '-published_at',
        type: INSTANCE_TYPE_PHOTO_ALBUM,
      })
    } else {
      dispatchFetchHomeAlbums({
        page: 0,
        sort: '-published_at',
        type: INSTANCE_TYPE_PHOTO_ALBUM,
      })
    }
  }

  return (
    <>
      <CreateAlbumDialog
        presetTargets={presetTargets}
        onFinishedUploading={handleOnFinishedUploadingPhotos}
        trigger={props => <CreateAlbumButton {...props} />}
      />
    </>
  )
}

export default AddAlbumControl
