import {
  ImageSource,
  ImageSourceSpecification,
  LayerSpecification,
  LngLatLike,
  Map,
  MapWheelEvent,
  Marker,
  Popup,
} from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import {
  scaleImageAndContents,
  addRectangleToMapboxDraw,
  repositionImageReferencePoints,
  calcNewImageBorderCoords,
  getImageLayerIdAtMapViewportPixels,
  getImageReferencePointsSourceId,
  getImageBorderRectFeature,
  getImageReferencePointsLayerId,
  featureIsImageBorderRectangle,
  imageBorderRectangleIdToImageId,
  getImageSourceId,
  calcPercentageWithinImageLayer,
  getImageBorderRectangleOutlineLayerId,
  getImageBorderRectangleOutlineSourceId,
  updateImageReferencePointPosition,
  recalcImagePropertyCenterCoordsBasedOnRect,
  adjustFeatureWidthForLatitude,
  getSideLengthsInMetresFromFeature,
  moveImageBorderRectToImage,
  TXRECT_MODE_OPTS,
  RectCoordinates,
  updateImageProperties,
  getImageProperty,
  recalcImagePropertiesForResize,
  getImageBorderRectangleId,
  snapReferencePointsTogether,
  updateImageProperty,
  getCenterCoords,
  FourCoordinates,
} from './MapImageHelpers'
import { Feature, Geometry, GeoJsonProperties, Polygon } from 'geojson'
import { useEffect, useRef, useState } from 'react'
import { MapImageHookResponse } from './MapImage'
import { Button } from '../ui'

import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { MEDIA_TYPE_PHOTO } from '../photo'
import { SelectMedia } from '../photo'
//import AddMediaDialog from 'src/modules/photo/AddMediaDialog'
import { TxRectModeDemo } from '../photo/TxRectModeDemo'

import center from '@turf/center'
import rhumbDistance from '@turf/rhumb-distance'

import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon'

import { createPortal } from 'react-dom'

import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone'
import Slider from '@mui/material/Slider'
import { Box } from '@mui/material'
import Slide from '@mui/material/Slide'
//import { Palette } from '@mui/styles/createPalette'

import { v4 as uuidv4 } from 'uuid'
import { MapLayerImageInfo, WeAreMap } from './MapEdit'

import { isEqual } from 'lodash'
import { getImageLayerId } from './MapImageHelpers'

// ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE: when the image is moved around account for changing degree width/height
// ratio according to latitude by stretching or compressing the rect's width.
// called after drag (mouseup), optionally during any on('data') call containing an image rect, and optionally whilst moving
const ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE = true
const APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_IMAGE_WHILST_DRAGGING = true
const APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_BOUNDARY_RECT_WHILST_MOVING = false // causes infinite loop when resizing
const USE_REFERENCE_POINTS = true
const WATCH_FOR_IMAGE_RECT_MOVEMENT_USING_ON_DATA = true // the way txrect does it, possibly too many updates
const WATCH_FOR_IMAGE_RECT_DRAGGING_USING_ON_MOUSE_MOVE = true // doesn't handle resize

const IMAGE_REFERENCE_POINTS_ARE_MARKERS = true

const referencePointColours = ['#FF0000', '#3FB1CE']

interface SnapButtonProps {
  mapinst: Map
  mapboxDraw: MapboxDraw
}

export interface EditableMapImageHookResponse {
  onShapeDeleted(
    mapinst: Map,
    mapboxDraw: MapboxDraw | undefined,
    feature: Feature<Geometry, GeoJsonProperties>
  ): unknown
  addEditableImageToGlobe: CallableFunction
  selectedImageReferenceMarkers: any
  SnapButton: ({ mapinst, mapboxDraw }: SnapButtonProps) => React.JSX.Element
  addImagesFromCurrentMap: CallableFunction
}

interface EditableMapImageComponentProps {
  mapinst: Map
  currentMap: WeAreMap
  setCurrentMap: CallableFunction
  mapboxDraw: MapboxDraw
  mapImageHookResponse: MapImageHookResponse
  openSelectMediaDialogFnRef: any //React.Ref<any>
  useEditableMapImageResponseRef: any //React.Ref<EditableMapImageHookResponse>
  setHideUi?: any
}

/**
 * Returned JSX includes a SelectMedia dialog
 *
 *
 * Mounted inside MapEditComponent
 *
 */
export const EditableMapImageComponent = ({
  mapinst,
  currentMap,
  setCurrentMap,
  mapboxDraw,
  mapImageHookResponse: mapImageHook,
  openSelectMediaDialogFnRef, // passed a Ref which is set with the function to open the SelectMedia dialog
  useEditableMapImageResponseRef,
  setHideUi,
}: EditableMapImageComponentProps) => {
  const debug = true

  const selectMediaRef = useRef<any>({}) //the SelectMedia component sets its handleShowModal() fn in this Ref
  const initialImagesAdded = useRef<boolean>(false)

  // changes as the user clicks on image rects
  // value not used - only here to get the jsx below to re-render
  const [selectedImageId, setSelectedImageId] = useState<string>()

  const useEditableMapImageResponse = useEditableMapImage(mapImageHook)
  useEditableMapImageResponseRef.current = useEditableMapImageResponse

  // selectedImageReferenceMarkers needed by the portal code in the JSX below
  const selectedImageReferenceMarkers =
    useEditableMapImageResponse.selectedImageReferenceMarkers

  useEffect(() => {
    if (setHideUi) {
      setHideUi((existingValue: any) => {
        const newValue = !!selectedImageId
        if (existingValue !== newValue) {
          console.debug(
            `EditableMapImageComponent.useEffect([selectedImageId]): changing hideUi to: ${newValue}`
          )
        }
        return newValue
      })
    }
  }, [selectedImageId, setHideUi])

  //this fn is set in openSelectMediaDialogFnRef.current
  const onOpenSelectMediaDialog = () => {
    selectMediaRef.current.handleShowModal()
  }
  if (openSelectMediaDialogFnRef) {
    console.debug(
      `EditableMapImageComponent: setting openSelectMediaDialogFnRef.current with onOpenSelectMediaDialog:`,
      onOpenSelectMediaDialog
    )
    openSelectMediaDialogFnRef.current = onOpenSelectMediaDialog
  } else {
    console.debug(
      `EditableMapImageComponent: openSelectMediaDialogFnRef not set`
    )
  }

  // passed as a callback to mapbox
  // just sets local state item SelectedImageId
  // NOTE!!! as this is called from a mapbox callback function outside react context the react state may be out of date
  const onImageSelected = (selected: boolean, imageId: string) => {
    console.debug(
      `useMapEdit.onImageSelected(): called with selected '${selected}' and imageId: ${imageId}`
    )
    setSelectedImageId(selected ? imageId : undefined)
  }

  // passed to the SelectMedia dialog, called when media selected
  const handleSelectMedia = async (parms: any) => {
    if (debug)
      console.debug(
        `EditableMapImageComponent.handleSelectMedia(): called with parms:`,
        parms
      )
    if (useEditableMapImageResponse) {
      //TODO use original image url not medium scaled
      const url = parms.fileMedium

      if (debug)
        console.debug(
          `EditableMapImageComponent.handleSelectMedia(): calling useEditableMapImageResponse.addEditableImageToGlobe()...`
        )

      let mapImageCornerCoords
      if (parms.mapImageCornersGeoPosition) {
        const geojsonobj = JSON.parse(parms.mapImageCornersGeoPosition)
        mapImageCornerCoords = geojsonobj?.coordinates
          ? geojsonobj.coordinates[0].slice(0, 4)
          : null
      }
      if (debug)
        console.debug(
          `EditableMapImageComponent.handleSelectMedia(): calling useEditableMapImageResponse.addEditableImageToGlobe() with mapImageCornerCoords:`,
          mapImageCornerCoords
        )

      const mapLayerImageInfo =
        await useEditableMapImageResponse.addEditableImageToGlobe(
          mapinst,
          mapboxDraw,
          parms.id,
          url,
          parms.width,
          parms.height,
          false, //flyto
          mapImageCornerCoords, //rotatedImageBorderCoords
          true, //addReferencePoints,
          true, //addBorderMapboxDrawRect
          onImageSelected // onImageSelected
        )
      if (debug)
        console.debug(
          `EditableMapImageComponent.handleSelectMedia(): called useEditableMapImageResponse.addEditableImageToGlobe().`
        )

      if (!mapLayerImageInfo) {
        console.error(
          `EditableMapImageComponent.handleSelectMedia(): addEditableImageToGlobe() returned null`
        )
        return
      }

      currentMap.addMapImage(mapLayerImageInfo)

      if (debug)
        console.debug(
          `EditableMapImageComponent.handleSelectMedia(): called currentMap.addMapImage(), currentMap now:`,
          currentMap
        )
      if (setCurrentMap) {
        setCurrentMap(currentMap)
      }
    } else {
      if (debug)
        console.debug(
          `EditableMapImageComponent.handleSelectMedia(): useEditableMapImageResponse not set`,
          mapImageHook
        )
    }
  }

  if (selectedImageId) {
    if (debug)
      console.debug(
        `EditableMapImageComponent: rendering, selectedImageId is set, selecting ${selectedImageId}...`
      )
    const borderFeature = getImageBorderRectFeature(mapboxDraw, selectedImageId)
    if (borderFeature && borderFeature.id) {
      mapboxDraw.changeMode('tx_poly', {
        ...TXRECT_MODE_OPTS,
        featureId: `${borderFeature.id}`,
      })
    }
  } else {
    if (debug)
      console.debug(
        `EditableMapImageComponent: rendering, selectedImageId is not set.`
      )
  }

  // need to call addImagesFromCurrentMap() ONCE per map load, multiple times will error
  // useEffect() not good enough as the useEditableMapImageResponse from the hook changes on every render
  // so use another Ref...
  if (debug)
    console.debug(
      `EditableMapImageComponent: rendering, initialImagesAdded.current: ${initialImagesAdded.current}, currentMap:`,
      currentMap
    )
  if (!initialImagesAdded.current) {
    initialImagesAdded.current = true

    if (debug)
      console.debug(
        `EditableMapImageComponent: rendering, calling addImagesFromCurrentMap()... currentMap:`,
        currentMap
      )
    useEditableMapImageResponse.addImagesFromCurrentMap(
      mapinst,
      mapboxDraw,
      currentMap,
      onImageSelected
    )
  }

  return (
    <>
      {/* @ts-expect-error some props are not mandatory */}
      <SelectMedia
        callbacksRef={selectMediaRef} //the SelectMedia component sets its handleShowModal() fn in this Ref
        onSelect={handleSelectMedia}
        onSelectCropped={{}}
        cropAfterSelect={false}
        presetTargets={[]}
        hideTags={false}
        defaultAllowNoTags={false}
        setCategory={null}
        mediaType={MEDIA_TYPE_PHOTO}
        includeOptionalFields={['map_image_corners_geo_position']}
        trigger={(onClick: any): any => {
          return ''
        }}
      />
      <Box style={{ marginLeft: 'auto', marginRight: 'auto' }} mt={1}>
        <useEditableMapImageResponse.SnapButton
          mapinst={mapinst}
          mapboxDraw={mapboxDraw}
        />
      </Box>
      {/* When an image is selected we want to show two pairs of draggable markers the user can drag about to help align
          the map image on the globe.
          These are called 'global reference markers' and 'image reference markers'.
          We use MapBox 'Marker's for this but as we want them to look different we want to tweak the appearance of the
          image ones - which Mapbox's API does not allow - so we set their HTML to empty divs and inject our own HTML
          using react portals.
          The below renders the HTML we want for the image reference markers and uses createPortal to inject it into
          the Marker's element using marker.getElement().
      */}
      {selectedImageReferenceMarkers && (
        <div id="imageReferencePointMarkers">
          {selectedImageReferenceMarkers.map((marker: Marker, idx: number) => {
            return (
              <div
                key={`image_${selectedImageId}_reference_point_marker_${
                  idx + 1
                }`}
              >
                {createPortal(
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <PlaceTwoToneIcon
                      fontSize="large"
                      htmlColor={referencePointColours[idx]} // border and center circle
                      style={{
                        marginTop: 'auto',
                      }}
                    />{' '}
                  </div>,
                  marker.getElement()
                )}
              </div>
            )
          })}
        </div>
      )}{' '}
    </>
  )
}

function useEditableMapImage(
  mapImageHookResponse: MapImageHookResponse
): EditableMapImageHookResponse {
  const debug = false

  if (debug) console.debug(`useEditableMapImage: rendering...`)

  const globeReferenceMarkersForSelectedImageRef = useRef<Array<Marker>>()
  const mouseOverImageReferenceMarkerRef = useRef<boolean>()

  // frozenImageRectFeatureIdRef set when the user moves the mouse over an image reference Marker and the border rect is
  // frozen so it doesn't move around when the user drags the Marker
  const frozenImageRectFeatureIdRef = useRef<string>()

  // temporary mouse operation state tracking
  const isMouseDownRef = useRef(false)
  const isMovingRef = useRef(false)
  const isResizingRef = useRef<string>()
  const isRotatingRef = useRef<string>()

  // set by mapinst.on('draw.selectionchange'...
  const selectedImageIdRef = useRef<string>()

  const [selectedImageReferenceMarkers, setSelectedImageReferenceMarkers] =
    useState<Marker[]>()

  const addImagesFromCurrentMap = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    currentMap: WeAreMap,
    onImageSelected: CallableFunction
  ) => {
    //extract an array from the object
    const mapLayerImagesArray = currentMap?.mapLayerImages
      ? Object.values(currentMap.mapLayerImages)
      : null
    if (mapLayerImagesArray && mapLayerImagesArray.length > 0) {
      // console.debug(
      //   `EditableMapImageComponent.addImagesFromCurrentMap(): there are mapLayerImages:`,
      //   currentMap.mapLayerImages
      // )
      mapLayerImagesArray.forEach((mapLayerImageInfo: MapLayerImageInfo) => {
        // console.debug(
        //   `EditableMapImageComponent.addImagesFromCurrentMap(): calling addEditableImageToGlobe() with mapLayerImageInfo:`,
        //   mapLayerImageInfo
        // )
        addEditableImageToGlobe(
          mapinst,
          mapboxDraw,
          mapLayerImageInfo.id,
          mapLayerImageInfo.fileName,
          mapLayerImageInfo.width,
          mapLayerImageInfo.height,
          false, // flyTo
          mapLayerImageInfo.coordinates,
          true, // addReferencePoints
          true, // addBorderMapboxDrawRect
          onImageSelected
        )
      })
    } else {
      console.debug(
        `useEditableMapImage.addImagesFromCurrentMap(): there are no mapLayerImages:`,
        currentMap
      )
    }
  }

  const onShapeDeleted = (
    mapinst: Map,
    mapboxDraw: MapboxDraw | undefined,
    feature: Feature
  ) => {
    if (debug)
      console.debug(
        `useEditableMapImage().onShapeDeleted(): deleted feature:`,
        feature
      )
    if (featureIsImageBorderRectangle(feature)) {
      if (feature.id && typeof feature.id === 'string') {
        const imageId = imageBorderRectangleIdToImageId(feature.id)
        if (imageId && feature.properties) {
          const imageSourceId =
            feature.properties.overlaySourceId ??
            feature.properties.user_overlaySourceId

          if (imageSourceId) {
            const imageLayerId = getImageLayerId(imageId)
            mapinst.removeLayer(imageLayerId)
            mapinst.removeSource(imageSourceId)

            if (!IMAGE_REFERENCE_POINTS_ARE_MARKERS) {
              const imageReferencePointsSourceId =
                getImageReferencePointsSourceId(imageId)
              const imageReferencePointsLayerId =
                getImageReferencePointsLayerId(imageId)

              if (imageReferencePointsLayerId) {
                mapinst.removeLayer(imageReferencePointsLayerId)
              }

              if (imageReferencePointsSourceId) {
                mapinst.removeSource(imageReferencePointsSourceId)
              }
            } else {
              // image reference points are markers!
              // if any are stored in local state, remove them then clear local state
              setSelectedImageReferenceMarkers(
                selectedImageReferenceMarkers => {
                  if (selectedImageReferenceMarkers) {
                    selectedImageReferenceMarkers.forEach(marker =>
                      marker.remove()
                    )
                    return undefined
                  } else {
                    return selectedImageReferenceMarkers
                  }
                }
              )
            }

            // console.debug(
            //   `useEditableMapImage().onShapeDeleted(imageId: ${imageId}): calling hideGlobeReferencePoints()...`
            // )
            hideGlobeReferencePoints()
            // console.debug(
            //   `useEditableMapImage().onShapeDeleted(imageId: ${imageId}): done.`
            // )
          }
        }
      }
    }
    if (mapboxDraw) {
      mapboxDraw.changeMode('simple_select', {
        featureIds: [],
      })
    }
  }

  /**
   * Called when mouse overs/leaves an image reference point marker
   *
   */
  const setImageBorderRectColor = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string,
    color?: string
  ) => {
    const debug = false

    if (debug)
      console.debug(`setImageBorderRectColor(): called with color ${color}`)
    const imageSourceId = getImageSourceId(imageId)

    const imageBorderLayerId = getImageBorderRectangleOutlineLayerId(imageId)
    const borderLayer = mapinst.getLayer(imageBorderLayerId)

    const borderOutlineSourceId =
      getImageBorderRectangleOutlineSourceId(imageId)
    const borderOutlineSource = mapinst.getSource(borderOutlineSourceId)

    if (!color) {
      if (borderLayer) {
        try {
          mapinst.removeLayer(imageBorderLayerId)
        } catch (e) {
          //don't care
        }
      }
      if (borderOutlineSource) {
        try {
          mapinst.removeSource(borderOutlineSourceId)
        } catch (e) {
          //don't care
        }
      }
      return
    }

    if (!borderOutlineSource) {
      const imageSource = mapinst.getSource(
        imageSourceId
      ) as ImageSourceSpecification
      if (imageSource && imageSource.coordinates) {
        const imageCoords = imageSource.coordinates // .map(pos => pos.toArray())

        let newFeatureCoords
        if (imageCoords.length === 4) {
          newFeatureCoords = [...imageCoords, imageCoords[0]]
        } else {
          newFeatureCoords = [...imageCoords]
        }
        if (debug)
          console.debug(
            `setImageBorderRectColor(): adding borderOutlineSource with id '${borderOutlineSourceId}', newFeatureCoords:`,
            newFeatureCoords
          )
        mapinst.addSource(borderOutlineSourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline the image.
              coordinates: [[...newFeatureCoords]], // , imageCoords[0]
            },
            properties: {},
          },
        })
      } else {
        console.error(
          `setImageBorderRectColor(): image source for image '${imageSourceId}' not found or it has no coordinates`,
          imageSource
        )
        return
      }
    }

    if (!borderLayer) {
      mapinst.addLayer({
        id: imageBorderLayerId,
        type: 'line',
        source: borderOutlineSourceId,
        layout: {},
        paint: {
          'line-color': color,
          'line-width': 3,
        },
      })

      const newLayer = mapinst.getLayer(imageBorderLayerId)

      if (debug)
        console.debug(
          `setImageBorderRectColor(): added layer '${imageBorderLayerId}':`,
          newLayer
        )
    } else {
      if (debug)
        console.debug(
          `setImageBorderRectColor(): layer '${imageBorderLayerId}' already exists:`,
          borderLayer
        )
      //}
    }
  }

  /**
   * If ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE is true
   *     update the image properties if dragging or resizing
   *     if enableAdjustFeatureWidthForLatitude or applyAdjustmentToBorderRectFeature are true:
   *         set adjustedCoords by re-plotting the image rectangle from its new center/scale/rotation
   *         if applyAdjustmentToBorderRectFeature is true move the border rect to the adjustedCoords
   * calculate new imageReferencePoint positions, update imageProperties, move the Markers
   * if applyFinalBorderPlacementToImage is true, move the image Source to match
   *
   *
   * called from:
   *   on('data') if WATCH_FOR_IMAGE_RECT_MOVEMENT_USING_ON_DATA is true
   *   onMouseMoveWhenDownOnSelectedImage() if WATCH_FOR_IMAGE_RECT_MOVEMENT_USING_ON_MOUSE_MOVE is true
   *   onImageBorderRectDragEnd()
   *
   */
  const onImageBorderRectMoved = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string,
    borderRectFeature: Feature<Polygon>,
    enableAdjustFeatureWidthForLatitude: boolean,
    applyFinalBorderPlacementToImage: boolean,
    isMoving: boolean,
    isResizing: boolean,
    isRotating: boolean,
    applyAdjustmentToBorderRectFeature = APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_BOUNDARY_RECT_WHILST_MOVING
  ) => {
    //const debug = true
    if (debug)
      console.debug(
        `useEditableMapImage.onImageBorderRectMoved(): called with enableAdjustFeatureWidthForLatitude: ${enableAdjustFeatureWidthForLatitude}, applyAdjustmentToBorderRectFeature: ${applyAdjustmentToBorderRectFeature}, applyFinalBorderPlacementToImage: ${applyFinalBorderPlacementToImage}, isDragging: ${isMoving}, isResizing: ${isResizing}, borderRectFeature:`,
        borderRectFeature
      )

    let adjustedCoords

    if (!Array.isArray(borderRectFeature.geometry.coordinates[0])) {
      console.error(
        `useEditableMapImage.onImageBorderRectMoved(): called with borderRectFeature that does not have a coordinates array:`,
        borderRectFeature
      )
      return
    }

    const nonAdjustedCoords = borderRectFeature.geometry.coordinates[0].slice(
      0,
      4
    ) as RectCoordinates

    if (ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE) {
      let newCenterCoordsAfterMoving
      if (isMoving) {
        //feature property user_centerCoords is used by adjustFeatureWidthForLatitude() to redraw rect
        // the adjusted image is in the horizontal center of the borderRect so the borderRect feature's
        // adjusted center will be calculated correctly using its current position
        newCenterCoordsAfterMoving = recalcImagePropertyCenterCoordsBasedOnRect(
          mapinst,
          imageId,
          borderRectFeature
        )

        if (debug)
          console.debug(
            `useEditableMapImage.onImageBorderRectMoved(): recalcFeatureUserCenterCoordsBasedOnRect() returned newCenterCoordsAfterMoving:`,
            newCenterCoordsAfterMoving
          )
      } else {
        if (isResizing) {
          recalcImagePropertiesForResize(mapinst, imageId, borderRectFeature)
        } else if (isRotating) {
          console.error(
            `useEditableMapImage.onImageBorderRectMoved(): TODO: update user prop with new rotation bearing`
          )
        }
      }

      //  Sets the border rect coordinates if they need adjusting
      if (
        enableAdjustFeatureWidthForLatitude ||
        applyAdjustmentToBorderRectFeature
      ) {
        //this doesn't touch the image. It will move the border rect if applyAdjustmentToBorderRectFeature is true
        adjustedCoords = adjustFeatureWidthForLatitude(
          mapinst,
          imageId,
          borderRectFeature,
          applyAdjustmentToBorderRectFeature, // change the borderRectFeature
          mapboxDraw
        )

        if (debug && isMoving) {
          console.debug(
            `useEditableMapImage.onImageBorderRectMoved(): isMoving, newCenterCoordsAfterMoving: ${newCenterCoordsAfterMoving}, nonAdjustedCoords from dragged rect feature - nonAdjustedCoords:`,
            nonAdjustedCoords
          )
          if (applyFinalBorderPlacementToImage) {
            console.debug(
              `useEditableMapImage.onImageBorderRectMoved(): isMoving, adjustedCoords after re-laying-out rect based on newCenterCoordsAfterMoving: ${newCenterCoordsAfterMoving} (applyAdjustmentToBorderRectFeature: ${applyAdjustmentToBorderRectFeature}) - will move image to match adjustedCoords:`,
              adjustedCoords
            )
          } else {
            console.debug(
              `useEditableMapImage.onImageBorderRectMoved(): isMoving, adjustedCoords after re-laying-out rect based on newCenterCoordsAfterMoving: ${newCenterCoordsAfterMoving} (applyAdjustmentToBorderRectFeature: ${applyAdjustmentToBorderRectFeature}) - will not move image`
            )
          }
        }
      }
    }

    const lengths = getSideLengthsInMetresFromFeature(
      mapinst,
      imageId,
      mapboxDraw
    )
    if (!lengths) {
      return
    }
    const [corner0toCorner1LengthMeters, corner0toCorner3LengthMeters] = lengths

    //call local state setter just to get its value because this fn is called from a non-react context
    const newImageCoords = adjustedCoords ?? nonAdjustedCoords
    setSelectedImageReferenceMarkers(selectedImageReferenceMarkers => {
      console.debug(
        `useEditableMapImage.onImageBorderRectMoved(): inside setter - calling repositionImageReferencePoints(imageId: ${imageId}, corner0toCorner1LengthMeters: ${corner0toCorner1LengthMeters}), selectedImageReferenceMarkers:`,
        selectedImageReferenceMarkers
      )

      repositionImageReferencePoints(
        mapinst,
        imageId,
        newImageCoords,
        corner0toCorner1LengthMeters,
        corner0toCorner3LengthMeters,
        selectedImageReferenceMarkers
      )
      return selectedImageReferenceMarkers
    })

    if (applyFinalBorderPlacementToImage) {
      // move the image to match the border rect
      // overlaySourceId is set as a borderRect property when it is created, something in the TxRect system renames it to user_overlaySourceId???!!
      const overlaySourceId =
        borderRectFeature.properties?.overlaySourceId ??
        borderRectFeature.properties?.user_overlaySourceId

      // console.debug(
      //   `useEditableMapImage.onImageBorderRectMoved(): overlaySourceId: ${overlaySourceId}`
      // )

      if (overlaySourceId) {
        const imageSource = mapinst.getSource(overlaySourceId) as ImageSource
        // console.debug(
        //   `useEditableMapImage.onImageBorderRectMoved(): imageSource: ${imageSource}`
        // )
        if (imageSource) {
          if (debug)
            console.debug(
              `useEditableMapImage.onImageBorderRectMoved(): applyFinalBorderPlacementToImage is ${applyFinalBorderPlacementToImage}, nonAdjustedCoords: ${nonAdjustedCoords}, adjustedCoords: ${adjustedCoords}, setting imageSource coords to:`,
              newImageCoords
            )

          const newImageCoordsClosed = [...newImageCoords, newImageCoords[0]]
          const existingCoords = (imageSource as ImageSourceSpecification)
            .coordinates
          if (!isEqual(existingCoords, newImageCoordsClosed)) {
            if (debug)
              console.debug(
                `useEditableMapImage.onImageBorderRectMoved(): calling imageSource.setCoordinates() - existingCoords, newImageCoordsClosed:`,
                existingCoords,
                newImageCoordsClosed
              )
            const firstFourImageCoordinates: FourCoordinates = [
              newImageCoords[0],
              newImageCoords[1],
              newImageCoords[2],
              newImageCoords[3],
            ]
            imageSource.setCoordinates(firstFourImageCoordinates)
            //imageSource.setCoordinates(newImageCoordsClosed)

            if (debug)
              console.debug(
                `useEditableMapImage.onImageBorderRectMoved(): imageSource.setCoordinates() returned`
              )
          }
        }
      }
    } else {
      if (debug)
        console.debug(
          `useEditableMapImage.onImageBorderRectMoved(): applyFinalBorderPlacementToImage not set, not moving image.`
        )
    }
  }

  /**
   * Moves the border rect to match the image Source's coordinates
   *
   * Called by onMouseUpAfterDownOnSelectedImage()
   */
  const onImageBorderRectDragEnd = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string,
    borderRectFeature?: Feature<Polygon>
  ) => {
    //const debug = true

    if (borderRectFeature) {
      if (debug)
        console.debug(
          `useEditableMapImage.onImageBorderRectDragEnd(): calling adjustFeatureWidthForLatitude()...`
        )

      //calling mapboxDraw.add() with the passed feature creates a duplicate - get a fresh copy and modify that
      //const freshFeature = mapboxDraw.get(featureId) as Feature<Polygon>
      const featureId = borderRectFeature.id ?? borderRectFeature.properties?.id
      if (!featureId) {
        console.error(
          `useEditableMapImage.onImageBorderRectDragEnd(): borderRectFeature has no id, exiting:`,
          borderRectFeature
        )
        return
      }
      const freshFeature = mapboxDraw.get(featureId) as Feature<Polygon>
      if (!freshFeature) {
        console.error(
          `useEditableMapImage.onImageBorderRectDragEnd(): mapboxDraw does not contain feature with id: ${featureId}, exiting.`
        )
        return
      }

      moveImageBorderRectToImage(mapinst, mapboxDraw, freshFeature)
    }
  }

  //stop the user dragging the image reference point outside the image boundary by storing the latest
  //'inside the boundary' and resetting back to this if the user moves outside
  const imageReferencePointLastOkCoords = useRef<LngLatLike>()

  /**
   * The image reference point positions will have already been calculated and set in image properties
   * referencePoint1GlobalCoords and referencePoint2GlobalCoords by addImageReferencePoints().
   * But that does not create Markers - that is done here when the image is selected.
   *
   * Called by addOrRemoveImageReferencePointsOnImageSelected()
   */
  const addImageReferencePointMarkers = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string
  ) => {
    const debug = false

    if (debug)
      console.debug(
        `useEditableMapImage.addImageReferencePointMarkers(): called with imageId ${imageId}:`
      )
    const imageLayerId = getImageLayerId(imageId)
    const imageLayer: LayerSpecification | undefined =
      mapinst.getLayer(imageLayerId)
    if (!imageLayer?.metadata) {
      console.error(
        `useEditableMapImage.addImageReferencePointMarkers(): layer for imageId ${imageId} has no metadata`,
        imageLayer
      )
      return
    }

    if (debug)
      console.debug(
        `useEditableMapImage.addImageReferencePointMarkers(): got layer for imageId ${imageId}:`,
        imageLayer
      )

    const point1Coords = getImageProperty({
      propName: 'referencePoint1GlobalCoords',
      imageId,
      imageLayer,
    })
    const point2Coords = getImageProperty({
      propName: 'referencePoint2GlobalCoords',
      imageId,
      imageLayer,
    })

    if (debug)
      console.debug(
        `useEditableMapImage.addImageReferencePointMarkers(): point1Coords: ${point1Coords}, point2Coords: ${point2Coords}`
      )
    if (!point1Coords || !point2Coords) {
      console.error(
        `useEditableMapImage.addImageReferencePointMarkers(): did not find image reference point global coords in image properties - point1Coords: ${point1Coords}, point2Coords: ${point2Coords}`
      )
      return
    }

    const ar = [point1Coords, point2Coords]
    const imageReferenceMarkers = ar.map((coords, idx) => {
      if (debug)
        console.debug(
          `useEditableMapImage.addImageReferencePointMarkers(): adding point for image '${imageId}' at coords: ${coords}`
        )
      // create a Mapbox Marker but set its HTML to be an empty div. The EditableMapImageComponent jsx
      // will use a react portal to inject a styled icon into it.
      const div = document.createElement('div')
      const marker = new Marker(div, {
        draggable: true,
        anchor: 'bottom',
        offset: [0, -2], // allow for padding at the bottom of the svg image
      })
        .setLngLat(coords)
        .addTo(mapinst)

      if (debug)
        console.debug(
          `useEditableMapImage.addImageReferencePointMarkers(): registering onDragEnd callback for point ${idx} of image '${imageId}'`
        )

      // marker.on('dragstart', o =>

      // as the marker is being dragged around stop it being moved outside the image border
      marker.on('drag', (o: any) => {
        const borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
        if (borderRectFeature) {
          const markerNewCoords = o.target.getLngLat()

          if (
            !booleanPointInPolygon(markerNewCoords.toArray(), borderRectFeature)
          ) {
            if (imageReferencePointLastOkCoords.current) {
              marker.setLngLat(imageReferencePointLastOkCoords.current)
            }
          } else {
            imageReferencePointLastOkCoords.current = markerNewCoords
          }
        }
      })

      marker.on('dragend', o => {
        console.debug(`ImageReferencePointMarker.onDragEnd: marker: `, o)
        onImageReferenceMarkerDragEnd(mapinst, mapboxDraw, imageId, marker, idx)

        imageReferencePointLastOkCoords.current = undefined
      })

      const markerDiv = marker.getElement()

      // when the user drags one of the image markers that are on top of the image the image border is ALSO moved as well as the marker.
      // there doesn't seem to be a nice way to stop the mouse click/drag event propagating up to the MapboxDraw feature (the border rect)
      // so the workaround here is to change MapboxDraw's current mode to 'static' (so the border rect is frozen/not movable) whilst the pointer
      // is over an image marker.
      // Also set a bold coloured border to the image.
      markerDiv.addEventListener('mouseenter', e => {
        if (debug)
          console.debug(`ImageReferencePointMarker.div.onMouseEnter: e: `, e)
        if (e.buttons) {
          return
        }
        if (mapboxDraw) {
          const existingMode = mapboxDraw.getMode()

          if (debug)
            console.debug(
              `ImageReferencePointMarker.div.onMouseEnter: existingMode: `,
              existingMode
            )
          if (
            existingMode === 'tx_poly' ||
            existingMode === 'simple_select' ||
            existingMode === 'direct_select'
          ) {
            mapboxDraw.changeMode('static')

            const imageBorderRectFeature = getImageBorderRectFeature(
              mapboxDraw,
              imageId
            )
            if (imageBorderRectFeature) {
              if (debug)
                console.debug(
                  `ImageReferencePointMarker.div.onMouseEnter: imageBorderRectFeature:`,
                  imageBorderRectFeature
                )

              frozenImageRectFeatureIdRef.current = `${imageBorderRectFeature.id}`
            }
          }

          setImageBorderRectColor(
            mapinst,
            mapboxDraw,
            imageId,
            referencePointColours[idx]
          )
        }
      })

      // add a callback for mouse pointer moving away from an image reference marker:
      //   clear the border colour set above
      //   un-freeze the border rect frozen above
      markerDiv.addEventListener('mouseleave', e => {
        if (debug)
          console.debug(`ImageReferencePointMarker.div.onMouseLeave: e: `, e)
        if (e.buttons & 1) {
          //left mouse button is being held down, pointer has 'slipped' off the marker due to lag, do nothing
          return
        }

        setImageBorderRectColor(mapinst, mapboxDraw, imageId, undefined)

        const existingMode = mapboxDraw.getMode()
        if (debug)
          console.debug(
            `ImageReferencePointMarker.div.onMouseLeave: existingMode:`,
            existingMode
          )
        if (existingMode === 'static' && frozenImageRectFeatureIdRef.current) {
          mapboxDraw.changeMode('tx_poly', {
            ...TXRECT_MODE_OPTS,
            featureId: frozenImageRectFeatureIdRef.current,
          })

          frozenImageRectFeatureIdRef.current = undefined
          if (debug)
            console.debug(
              `ImageReferencePointMarker.div.onMouseLeave(): cleared frozenImageRectFeatureIdRef.`
            )
        } else {
          if (existingMode === 'static') {
            if (debug)
              console.debug(
                `ImageReferencePointMarker.div.onMouseLeave: existingMode is 'static' but frozenImageRectFeatureIdRef.current is not set, leaving the mode as is`
              )
          }
        }
      })

      return marker
    })
    return imageReferenceMarkers
  } // end addImageReferencePointMarkers

  /**
   * User has stopped dragging around an image reference marker. Update its location in image properties.
   *
   * added as a on('dragend') event to the Marker created when the user selects the image border
   */
  function onImageReferenceMarkerDragEnd(
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string,
    marker: Marker,
    index: number
  ) {
    const debug = false
    const lngLat = marker.getLngLat()
    if (debug)
      console.debug(
        `useEditableMapImage.onImageReferenceMarkerDragEnd(): called with marker idx ${index} in imageId ${imageId}:`,
        marker
      )

    if (!imageId) {
      console.error(
        `useEditableMapImage.onImageReferenceMarkerDragEnd(): called with null imageId ${imageId} has no metadata`
      )
      return
    }

    const coords = lngLat.toArray()

    updateImageProperty({
      propName: `referencePoint${index + 1}GlobalCoords`,
      propValue: coords,
      imageId,
      mapinst,
    })

    const lengths = getSideLengthsInMetresFromFeature(
      mapinst,
      imageId,
      mapboxDraw
    )
    if (!lengths) {
      console.error(
        `useEditableMapImage.onImageReferenceMarkerDragEnd(): getSideLengthsInMetresFromFeature(imageId ${imageId}) returned null`
      )
      return
    }
    const [corner0toCorner1LengthMeters, corner0toCorner3LengthMeters] = lengths

    // recalc the percentage of the point within the image, used when image moved/resized/rotated
    const res = calcPercentageWithinImageLayer(
      mapinst,
      imageId,
      coords,
      corner0toCorner1LengthMeters,
      corner0toCorner3LengthMeters
    )
    if (!res) {
      console.error(
        `useEditableMapImage.onImageReferenceMarkerDragEnd(): calcPercentageWithinImageLayer() returned null`
      )
      return
    }
    const [pct01, pct03] = res

    updateImageProperty({
      propName: `referencePoint${index + 1}Percentage0to1`,
      propValue: pct01,
      imageId,
      mapinst,
    })
    updateImageProperty({
      propName: `referencePoint${index + 1}Percentage0to3`,
      propValue: pct03,
      imageId,
      mapinst,
    })
  }

  /**
   * If image border has been selected:
   *   if local state selectedImageReferenceMarkers is not set:
   *      call addImageReferencePointMarkers() to create Mapbox Markers the user can drag around the image area
   *      set local state selectedImageReferenceMarkers with the markers
   * else image border deselected:
   *   if local state selectedImageReferenceMarkers is set:
   *      remove each Mapbox Marker
   */
  const addOrRemoveImageReferencePointsOnImageSelected = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    selected: boolean,
    imageId: string
  ) => {
    if (selected) {
      // console.debug(
      //   `useMapEdit.addOrRemoveImageReferencePointsOnImageSelected(): calling addImageReferencePointMarkers() with imageId: ${imageId}...`
      // )

      //this fn is called from outside the react context so local state cannot just be referenced, call the setter to get current value
      setSelectedImageReferenceMarkers(existingValue => {
        if (!existingValue) {
          const markers = addImageReferencePointMarkers(
            mapinst,
            mapboxDraw,
            imageId
          )
          // console.debug(
          //   `useMapEdit.addOrRemoveImageReferencePointsOnImageSelected(): called setSelectedImageReferenceMarkers() with markers:`,
          //   markers
          // )
          return markers
        } else {
          // console.debug(
          //   `useMapEdit.addOrRemoveImageReferencePointsOnImageSelected(): selectedImageReferenceMarkers already set, not adding markers again`,
          //   existingValue
          // )
          return existingValue
        }
      })
    } else {
      setSelectedImageReferenceMarkers(existingValue => {
        if (existingValue) {
          console.debug(
            `useEditableMapImage.addOrRemoveImageReferencePointsOnImageSelected(): called with selected '${selected}' and imageId: ${imageId}. selectedImageReferenceMarkers already:`,
            existingValue
          )
          existingValue.forEach(marker => {
            // console.debug(
            //   `useEditableMapImage.addOrRemoveImageReferencePointsOnImageSelected(): removing marker:`,
            //   marker
            // )

            marker.remove()
          })
        } else {
          // console.debug(
          //   `useEditableMapImage.addOrRemoveImageReferencePointsOnImageSelected(): called with selected '${selected}', selectedImageReferenceMarkers not set`,
          //   existingValue
          // )
        }

        return undefined
      })
    }
  }

  /**
   * Adds image metadata properties describing the positions of the two image reference points
   * - defaulting to 10% inside the top-left and bottom-right corners
   *
   */
  const configureInitialImageReferencePoints = (
    mapinst: Map,
    imageId: string,
    rotatedImageBorderCoords: RectCoordinates,
    corner0toCorner1LengthMetres: number,
    corner0toCorner3LengthMetres: number
  ) => {
    const debug = false

    const point1BoundingRectPercentage0to1 = 0.1
    const point1BoundingRectPercentage0to3 = 0.1

    const point2BoundingRectPercentage0to1 = 0.9
    const point2BoundingRectPercentage0to3 = 0.9

    const imageLayerId = getImageLayerId(imageId)
    const imageLayer: LayerSpecification | undefined =
      mapinst.getLayer(imageLayerId)

    if (!imageLayer) {
      console.error(
        `configureInitialImageReferencePoints(): could not find layer with id '${imageLayerId}'`
      )
      return
    }

    updateImageProperties({
      properties: {
        referencePoint1Percentage0to1: point1BoundingRectPercentage0to1,
        referencePoint1Percentage0to3: point1BoundingRectPercentage0to3,
        referencePoint2Percentage0to1: point2BoundingRectPercentage0to1,
        referencePoint2Percentage0to3: point2BoundingRectPercentage0to3,
      },
      imageId,
      imageLayer,
      mapinst,
    })

    updateImageReferencePointPosition(
      imageLayer,
      rotatedImageBorderCoords,
      0,
      corner0toCorner1LengthMetres,
      corner0toCorner3LengthMetres,
      undefined // don't have markers yet
    )
    updateImageReferencePointPosition(
      imageLayer,
      rotatedImageBorderCoords,
      1,
      corner0toCorner1LengthMetres,
      corner0toCorner3LengthMetres,
      undefined
    )

    if (debug)
      console.debug(
        `useEditableMapImage.configureInitialImageReferencePoints(): set layer's metadata for imageId ${imageId}:`,
        imageLayer
      )
  } // end addImageReferencePoints

  /**
   * Removes global Mapbox Markers from display. Positions are still remembered in image properties.
   *
   * Called when an image is deselected by mapinst.on('draw.selectionchange')
   */
  const hideGlobeReferencePoints = () => {
    if (globeReferenceMarkersForSelectedImageRef.current) {
      if (debug)
        console.debug(
          `hideGlobeReferencePoints(): globeReferencePointsRef.current set, removing them all...`,
          globeReferenceMarkersForSelectedImageRef.current
        )
      globeReferenceMarkersForSelectedImageRef.current.forEach(marker => {
        if (debug)
          console.debug(
            `hideGlobeReferencePoints():   removing marker with popup ${marker.getPopup()}...`,
            globeReferenceMarkersForSelectedImageRef.current
          )
        marker.remove()
      })
      globeReferenceMarkersForSelectedImageRef.current = undefined
    } else {
      if (debug)
        console.debug(
          `hideGlobeReferencePoints(): globeReferencePointsRef.current not set.`
        )
    }
  }

  /**
   * Creates two Mapbox Markers the user can drag around to help align the image to the globe.
   * If there are no marker positions stored as image properties they will be created at the defaults
   * of 10% outside the top-left and bottom-right corners of the image.
   *
   * Called when an image is clicked/selected by mapinst.on('draw.selectionchange')
   *
   */
  const addGlobeReferencePointMarkers = (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string
  ) => {
    const borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)

    if (!borderRectFeature) {
      console.error(
        `addGlobeReferencePointMarkers(): could not getImageBorderRectFeature for imageId '${imageId}'`
      )
      return
    }
    let point1loc = getImageProperty({
      propName: 'globalReferencePoint1',
      imageId,
      mapinst,
    })
    let point2loc = getImageProperty({
      propName: 'globalReferencePoint2',
      imageId,
      mapinst,
    })
    if (!point1loc || !point2loc) {
      console.debug(
        `addGlobeReferencePointMarkers: at least 1 point not set, generating default locations 10% outside the image corners`
      )
      const imageBorderRectCoordinates =
        borderRectFeature.geometry.coordinates[0]

      const imageLength0to1degrees =
        imageBorderRectCoordinates[1][0] - imageBorderRectCoordinates[0][0]
      const imageLength0to3degrees =
        imageBorderRectCoordinates[0][1] - imageBorderRectCoordinates[3][1]

      if (!point1loc) {
        const point1BoundingRectPercentage0to1 = 0.1
        const point1BoundingRectPercentage0to3 = -0.1
        const point1OffsetX =
          imageLength0to1degrees * point1BoundingRectPercentage0to1
        const point1OffsetY =
          imageLength0to3degrees * point1BoundingRectPercentage0to3
        point1loc = [
          imageBorderRectCoordinates[0][0] - point1OffsetX,
          imageBorderRectCoordinates[0][1] - point1OffsetY,
        ]
        updateImageProperty({
          propName: 'globalReferencePoint1',
          propValue: point1loc,
          imageId,
          mapinst,
        })
      }
      if (!point2loc) {
        const point2BoundingRectPercentage0to1 = 0.1
        const point2BoundingRectPercentage0to3 = -0.1

        const point2OffsetX =
          imageLength0to1degrees * point2BoundingRectPercentage0to1
        const point2OffsetY =
          imageLength0to3degrees * point2BoundingRectPercentage0to3

        point2loc = [
          imageBorderRectCoordinates[2][0] + point2OffsetX,
          imageBorderRectCoordinates[2][1] + point2OffsetY,
        ]
        updateImageProperty({
          propName: 'globalReferencePoint2',
          propValue: point2loc,
          imageId,
          mapinst,
        })
      }
    }

    /**
     * onDragEnd callback: stores the coordinates of the moved global marker in image properties
     *
     */
    function onGlobeMarkerDragEnd(marker: Marker, index: number) {
      const lngLat = marker.getLngLat()

      updateImageProperty({
        propName: `globalReferencePoint${index + 1}`,
        propValue: lngLat.toArray(),
        imageId,
        mapboxDraw,
        mapinst,
      })
    }

    const globeReferenceMarker1 = new Marker({
      draggable: true,
      color: referencePointColours[0],
    })
      .setLngLat(point1loc)
      .addTo(mapinst)
      .setPopup(new Popup().setText(uuidv4()))

    globeReferenceMarker1.on('dragend', () =>
      onGlobeMarkerDragEnd(globeReferenceMarker1, 0)
    )

    const onMouseEnterGlobalMarker = (e: Event) => {
      if (mapboxDraw) {
        const existingMode = mapboxDraw.getMode()

        if (debug)
          console.debug(
            `GlobalReferencePointMarker.div.onMouseEnter: existingMode: `,
            existingMode
          )
        if (
          existingMode === 'tx_poly' ||
          existingMode === 'simple_select' ||
          existingMode === 'direct_select'
        ) {
          mapboxDraw.changeMode('static')

          const imageBorderRectFeature = getImageBorderRectFeature(
            mapboxDraw,
            imageId
          )
          if (imageBorderRectFeature) {
            if (debug)
              console.debug(
                `GlobalReferencePointMarker.div.onMouseEnter: imageBorderRectFeature:`,
                imageBorderRectFeature
              )

            frozenImageRectFeatureIdRef.current = `${imageBorderRectFeature.id}`
          }
        }
      }
    }

    const onMouseLeaveGlobalMarker = (e: Event) => {
      const existingMode = mapboxDraw.getMode()
      if (debug)
        console.debug(
          `GlobalReferencePointMarker.div.onMouseLeave: existingMode:`,
          existingMode
        )
      if (existingMode === 'static' && frozenImageRectFeatureIdRef.current) {
        mapboxDraw.changeMode('tx_poly', {
          ...TXRECT_MODE_OPTS,
          featureId: frozenImageRectFeatureIdRef.current,
        })

        frozenImageRectFeatureIdRef.current = undefined
      }
    }
    const marker1Div = globeReferenceMarker1.getElement()
    marker1Div.addEventListener('mouseenter', onMouseEnterGlobalMarker)
    marker1Div.addEventListener('mouseleave', onMouseLeaveGlobalMarker)

    const globeReferenceMarker2 = new Marker({
      draggable: true,
      //color: referencePointColorDds.stops[0][2],
      color: referencePointColours[1],
    })
      .setLngLat(point2loc)
      .addTo(mapinst)

    globeReferenceMarker2.on('dragend', () =>
      onGlobeMarkerDragEnd(globeReferenceMarker2, 1)
    )

    const marker2Div = globeReferenceMarker2.getElement()
    marker2Div.addEventListener('mouseenter', onMouseEnterGlobalMarker)
    marker2Div.addEventListener('mouseleave', onMouseLeaveGlobalMarker)

    // store references to the Markers as Mapbox's API does not have a method to return them
    // and they are needed when the image is deselected and by the 'Snap!' button
    globeReferenceMarkersForSelectedImageRef.current = [
      globeReferenceMarker1,
      globeReferenceMarker2,
    ]
  }

  const addEditableImageToGlobe = async (
    mapinst: Map,
    mapboxDraw: MapboxDraw,
    imageId: string,
    url: string,
    imageWidthPx: number,
    imageHeightPx: number,
    flyTo: boolean, // only works if image already georeferenced, otherwise image appears centered in current view
    rotatedImageBorderCoords: FourCoordinates | null, // getRotatedImageWithLevelBoundaryBoxCoords calculates
    addReferencePoints = true,
    addBorderMapboxDrawRect = true,
    onImageSelected: CallableFunction
  ) => {
    const debug = false

    if (debug)
      console.debug(
        `addEditableImageToGlobe(): called with url: '${url}', addReferencePoints: ${addReferencePoints}, rotatedImageBorderCoords:`,
        rotatedImageBorderCoords
      )

    if (!url) {
      console.error(`addEditableImageToGlobe(): called with null url`)
      return
    }
    if (!imageId) {
      console.error(`addEditableImageToGlobe(): called with null imageId`)
      return
    }

    let imageAlreadyOnMap = false
    const sourceId = getImageSourceId(imageId)
    try {
      const existingSource = mapinst.getSource(
        sourceId
      ) as ImageSourceSpecification
      if (existingSource) {
        console.debug(
          `useEditableMapImage.addEditableImageToGlobe(): already have source with id '${sourceId}', not adding again.`
        )
        imageAlreadyOnMap = true
        if (existingSource.coordinates) {
          rotatedImageBorderCoords = existingSource.coordinates
        } else {
          console.error(
            `useEditableMapImage.addEditableImageToGlobe(): already existing source with id '${sourceId}' does not have coordinates:`,
            existingSource
          )
        }
        //return
      }
    } catch (error) {
      // dont care
    }

    let metersPerPixel = 0
    let centerCoords = [0, 0]
    let corner0toCorner1LengthMeters = 0
    let corner0toCorner3LengthMeters = 0

    if (!rotatedImageBorderCoords) {
      // image was not previously georeferenced
      // position image in center of current map,
      // stretching to 60% of the view whilst taking
      // into account image ratio, view ratio,
      // and degree ratio variance by latitude

      if (debug)
        console.debug(
          `addEditableImageToGlobe(): called with no rotatedImageBorderCoords, centering image on current viewport...`
        )

      const screenCoords = mapinst.getBounds()

      if (!screenCoords) {
        console.error(
          `Cannot position image because mapinst.getBounds() returned null`
        )
        return
      }

      const calcBorderResponse = calcNewImageBorderCoords(
        imageWidthPx,
        imageHeightPx,
        screenCoords
      )

      if (!calcBorderResponse) {
        console.error(
          `addEditableImageToGlobe(): calcNewImageBorderCoords() returned null, exiting.`
        )
        return
      }
      rotatedImageBorderCoords = calcBorderResponse[0]
      metersPerPixel = calcBorderResponse[1]
      centerCoords = calcBorderResponse[2]

      corner0toCorner1LengthMeters = metersPerPixel * imageWidthPx
      corner0toCorner3LengthMeters = metersPerPixel * imageHeightPx

      flyTo = false
    } else {
      //rotatedImageBorderCoords is passed so image has been previously georeferenced
      //or is already on screen
      //in this case we know the corner positions in degrees - calculate the metersPerPixel
      //and centerCoords for use if the user moves/rotates/resizes the image

      corner0toCorner1LengthMeters = rhumbDistance(
        rotatedImageBorderCoords[0],
        rotatedImageBorderCoords[1],
        { units: 'meters' }
      )
      corner0toCorner3LengthMeters = rhumbDistance(
        rotatedImageBorderCoords[0],
        rotatedImageBorderCoords[3],
        { units: 'meters' }
      )
      metersPerPixel = corner0toCorner3LengthMeters / imageHeightPx

      centerCoords = getCenterCoords(rotatedImageBorderCoords)
    }

    if (debug)
      console.debug(
        `addEditableImageToGlobe(): calling mapImageHook.addImageToGlobe()...`
      )
    let imageSourceId
    if (!imageAlreadyOnMap) {
      const addImageResponse = mapImageHookResponse.addImageToGlobe(
        mapinst,
        imageId,
        url,
        rotatedImageBorderCoords
      )
      imageSourceId = addImageResponse[0]
    } else {
      imageSourceId = getImageSourceId(imageId)
    }
    // if (debug)
    //   console.debug(
    //     `addEditableImageToGlobe(): mapImageHook.addImageToGlobe() returned, imageSourceId: ${imageSourceId}.`
    //   )

    if (USE_REFERENCE_POINTS && addReferencePoints) {
      // Adds image properties describing the positions of the two image reference points
      configureInitialImageReferencePoints(
        mapinst,
        imageId,
        rotatedImageBorderCoords,
        corner0toCorner1LengthMeters,
        corner0toCorner3LengthMeters
      )
    } // end if USE_REFERENCE_POINTS

    if (flyTo) {
      // fitbounds takes an area whereas flyto takes a point and a zoom level
      mapinst.fitBounds(
        [
          rotatedImageBorderCoords[3] as LngLatLike,
          rotatedImageBorderCoords[1] as LngLatLike,
        ],
        {
          padding: {
            top: 30,
            bottom: 30,
            left: 30,
            right: 30,
          },
          //maxZoom: 13,
          //animate: false,
        }
      )
    }

    //mousewheel over image causes it to fade in/out or scale larger/smaller
    //these event handlers are registered whilst the border rect is selected
    const imageSelectedOnMouseWheelEvent = (e: MapWheelEvent) => {
      if (debug)
        console.debug(
          'addEditableImageToGlobe().imageSelectedOnMouseWheelEvent(): e:',
          e
        )

      //e.stopPropagation()

      if (e.originalEvent.altKey) {
        // change the transparency of the image
        e.preventDefault()

        const imageLayerId = getImageLayerIdAtMapViewportPixels(e.target, [
          e.originalEvent.offsetX,
          e.originalEvent.offsetY,
        ])

        if (!imageLayerId) {
          return
        }
        const existingValue =
          (mapinst.getPaintProperty(
            imageLayerId,
            'raster-opacity'
          ) as number) ?? 50

        const delta =
          e.originalEvent.deltaY &&
          e.originalEvent.deltaY !== 0 &&
          e.originalEvent.deltaY !== -0
            ? e.originalEvent.deltaY
            : e.originalEvent.deltaX
        const deltaDivided = delta / 1000
        const newValue = Math.max(Math.min(existingValue + deltaDivided, 1), 0)

        if (debug)
          console.debug(
            `addEditableImageToGlobe().imageSelectedOnMouseWheelEvent(): 'alt' held down, deltaY: ${e.originalEvent.deltaY}, delta: ${delta}, changing opacity from ${existingValue} to ${newValue}`,
            e
          )

        mapinst.setPaintProperty(imageLayerId, 'raster-opacity', newValue)
      } else if (e.originalEvent.shiftKey) {
        // resize the image keeping ratio
        e.preventDefault()

        //const heightDeltaDegrees = e.originalEvent.wheelDeltaY / 50000

        // TODO rework shift-mousewheel image sizing to take rotation into account
        // const widthDeltaDegrees =
        //   (heightDeltaDegrees * degreesRatioAtLatitude * imageWidthPx) /
        //   imageHeightPx
        // console.debug(
        //   `addImageToGlobe().onMouseWheelEvent(): resizing image by ${e.originalEvent.wheelDeltaY} - heightDeltaDegrees: ${heightDeltaDegrees}, widthDeltaDegrees: ${widthDeltaDegrees}`
        // )
        let diagonalRatio = 1
        if (e.originalEvent.deltaY > 0) {
          diagonalRatio = 1.05
        } else {
          diagonalRatio = 0.95
        }

        if (debug)
          console.debug(
            `addEditableImageToGlobe().onMouseWheelEvent(): shift held, resizing image; deltaY: ${e.originalEvent.deltaY} - diagonalRatio: ${diagonalRatio}`
          )

        //TODO use mouse position as fixedPoint instead of center of image
        const fixedPoint = getImageProperty({
          propName: 'centerCoords',
          imageId,
          mapinst,
        })
        if (debug)
          console.debug(
            `addEditableImageToGlobe().onMouseWheelEvent(): using fixedPoint: ${fixedPoint}`
          )
        scaleImageAndContents(
          mapinst,
          mapboxDraw,
          imageId,
          diagonalRatio,
          true,
          fixedPoint,
          selectedImageReferenceMarkers
        )
      }
    }

    if (addBorderMapboxDrawRect && mapboxDraw) {
      // add a MapboxDraw rectangle (polygon) matching the outline of the image

      const imageBorderRectangleId = getImageBorderRectangleId(imageId)

      addRectangleToMapboxDraw(
        mapboxDraw,
        imageBorderRectangleId,
        rotatedImageBorderCoords,
        imageSourceId,
        imageWidthPx,
        imageHeightPx,
        metersPerPixel,
        centerCoords,
        90
      )

      //TODO remove turfjs center() sanity check in addEditableImageToGlobe()
      const borderRectFeature = mapboxDraw.get(imageBorderRectangleId)
      if (borderRectFeature) {
        const reversedCenterCoords = center(borderRectFeature)
        const xDiff =
          reversedCenterCoords.geometry.coordinates[0] - centerCoords[0]
        const yDiff =
          reversedCenterCoords.geometry.coordinates[1] - centerCoords[1]

        if (
          yDiff > 0.0001 ||
          yDiff < -0.0001 ||
          xDiff > 0.0001 ||
          xDiff < -0.0001
        ) {
          console.error(
            `addEditableImageToGlobe(): SANITY CHECK: turf.js' center() returns different value: input: ${centerCoords}, center() response: ${reversedCenterCoords.geometry.coordinates}`
          )
        }
      }

      // very temporary state so we can tell if an image is newly selected or was clicked a second time
      let borderRectIsSelected: boolean

      //when an image (border rectangle) is selected/deselected:
      // - add/remove event handler to the map to handle mousewheel scrolls (imageSelectedOnMouseWheelEvent)
      // - show/hide the globe reference Markers
      // - show/hide the image reference Markers
      // - add/remove event handler to the map to handle mouse clicks on MapboxDraw layers (onMouseDownWhenImageRectSelected):
      //    - do nothing if mouse is over an image reference marker
      //    - optionally add event handler to the map to handle mousemove events (onMouseMoveWhenDownOnSelectedImage)
      //    - add event handler to the map to handle the next mouseUp event (onMouseUpAfterDownOnSelectedImage)
      // - if onImageSelected fn was passed to addEditableImageToGlobe(), call it
      //
      // The 'Snap!' button is hidden in the JSX depending on selectedImageIdRef
      mapinst.on('draw.selectionchange', function (e) {
        if (debug)
          console.debug(
            `EditableMapImage.addEditableImageToGlobe().onDrawSelectionChange(): e:`,
            e
          )
        let borderRectSelected = false

        let firstFeature: Feature<Geometry>
        if (e.features && e.features.length === 1) {
          firstFeature = e.features[0]
          if (firstFeature.id === imageBorderRectangleId) {
            borderRectSelected = true
          }
        }

        // don't do anything if same rect is selected again when already selected
        if (borderRectSelected && borderRectIsSelected) {
          return
        } else if (!borderRectSelected && !borderRectIsSelected) {
          return
        }

        borderRectIsSelected = borderRectSelected

        if (borderRectSelected) {
          mapinst.on('wheel', imageSelectedOnMouseWheelEvent)
          if (USE_REFERENCE_POINTS && addReferencePoints) {
            addGlobeReferencePointMarkers(mapinst, mapboxDraw, imageId)
          }

          selectedImageIdRef.current = imageId
        } else {
          mapinst.off('wheel', imageSelectedOnMouseWheelEvent)

          if (USE_REFERENCE_POINTS && addReferencePoints) {
            hideGlobeReferencePoints()
          }

          selectedImageIdRef.current = undefined
        }

        addOrRemoveImageReferencePointsOnImageSelected(
          mapinst,
          mapboxDraw,
          borderRectSelected,
          imageId
        )

        // only called during dragging if WATCH_FOR_IMAGE_RECT_DRAGGING_USING_ON_MOUSE_MOVE is true
        const onMouseMoveWhenDownOnSelectedImage = (e: any) => {
          let borderRectFeature = e.featureTarget
          if (
            borderRectFeature &&
            borderRectFeature.geometry?.coordinates?.length > 0
          ) {
          } else {
            borderRectFeature = mapboxDraw.get(imageBorderRectangleId)
          }
          if (debug)
            console.debug(
              `onMouseMoveWhenDownOnSelectedImage(): calling onImageBorderRectMoved()... APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_IMAGE_WHILST_DRAGGING: ${APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_IMAGE_WHILST_DRAGGING}, borderRectFeature:`,
              borderRectFeature
            )
          onImageBorderRectMoved(
            mapinst,
            mapboxDraw,
            imageId,
            borderRectFeature,
            true, // enableAdjustFeatureWidthForLatitude
            APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_IMAGE_WHILST_DRAGGING,
            true, //isDragging
            false, //isResizing
            false //isRotating
          )
          // console.debug(
          //   `onMouseMoveWhenDownOnSelectedImage(): onImageBorderRectMoved() done.`,
          //   e
          // )
        }

        const onMouseUpAfterDownOnSelectedImage = (e: any) => {
          if (debug)
            console.debug(
              `onMouseUpAfterDownOnSelectedImage(): clearing 'mousemove' event listener for all layers - e:`,
              e
            )

          isMovingRef.current = false
          mapinst.off(
            'mousemove',
            // this works a bit then stops firing whilst the item is still being dragged   rectangleDrawLayerId,
            onMouseMoveWhenDownOnSelectedImage
          )

          //update the border rect to match the image coords
          onImageBorderRectDragEnd(
            mapinst,
            mapboxDraw,
            imageId,
            e.featureTarget
          )

          // //EXPERIMENTAL!!
          // //this should still be registered but stops working randomly, so add it again
          // console.debug(
          //   `onMouseUpAfterDownOnSelectedImage(): EXPERIMENTAL re-registering 'mousedown' event listener for BORDER_RECT_DRAW_LAYER_ID '${BORDER_RECT_DRAW_LAYER_IDs[0]}', will call onMouseDownWhenImageRectSelected()...`
          // )
          // console.debug(
          //   `onMouseUpAfterDownOnSelectedImage(): listing map layers:`
          // )

          // mapinst.off(
          //   'mousedown',
          //   BORDER_RECT_DRAW_LAYER_IDs[0],
          //   onMouseDownWhenImageRectSelected
          // )
          // mapinst.on(
          //   'mousedown',
          //   BORDER_RECT_DRAW_LAYER_IDs[0],
          //   onMouseDownWhenImageRectSelected
          // )
        }

        /**
         * Callback registered with Mapbox, called on a mouse click on a MAPBOX_DRAW_CLICKABLE_LAYER_IDS layer when an image is selected:
         *   if mouseOverImageReferenceMarkerRef.current is set this does nothing as the user is trying to drag an image reference marker not interact with the image
         *   if the selected feature(s) includes this image's border feature:
         *      register onMouseUpAfterDownOnSelectedImage() as a callback for when the user releases the button
         *      if WATCH_FOR_IMAGE_RECT_DRAGGING_USING_ON_MOUSE_MOVE is true:
         *           register onMouseMoveWhenDownOnSelectedImage() as a callback to mouse move events
         *
         * This is not called when the user clicks on one of the border rect's icons such as resize or rotate.
         */
        const onMouseDownWhenImageRectSelected = (e: any) => {
          //const debug = true
          if (debug)
            console.debug(
              `useEditableMapImage.onMouseDownWhenImageRectSelected(): called with defaultPrevented: ${e.defaultPrevented}, mouseOverImageReferenceMarkerRef.current: ${mouseOverImageReferenceMarkerRef.current}, event`,
              e
            )

          if (mouseOverImageReferenceMarkerRef.current) {
            // mouse pointer is over a reference marker so user wants to drag that and not the image - stop the event passig any further.
            if (debug)
              console.debug(
                `useEditableMapImage.onMouseDownWhenImageRectSelected(): mouseOverImageReferenceMarkerRef.current: ${mouseOverImageReferenceMarkerRef.current}, returning. event`,
                e
              )
            //e.stopPropagation()
            e.preventDefault()
            return
          }

          if (e.featureTarget?.properties?.id === imageBorderRectangleId) {
            e.preventDefault()

            if (WATCH_FOR_IMAGE_RECT_DRAGGING_USING_ON_MOUSE_MOVE) {
              if (debug)
                console.debug(
                  `EditableMapImage.onMouseDownWhenImageRectSelected(): registering 'mousemove' callback (not layer restricted)...` //for rectangleDrawLayerId '${BORDER_RECT_DRAW_LAYER_ID}'...`
                )
              isMovingRef.current = true
              mapinst.on(
                'mousemove',
                //rectangleDrawLayerId,
                onMouseMoveWhenDownOnSelectedImage
              )
            }

            mapinst.once(
              'mouseup',
              //rectangleDrawLayerId,
              onMouseUpAfterDownOnSelectedImage
            )
          }
        } //end onMouseDownWhenImageRectSelected

        const MAPBOX_DRAW_CLICKABLE_LAYER_IDS = [
          'gl-draw-overlay-polygon-fill-active.cold',
          'gl-draw-overlay-polygon-fill-active.hot',
        ]
        if (borderRectSelected) {
          if (debug)
            console.debug(
              `EditableMapImage.onDrawSelectionChange(): borderRectSelected is ${borderRectSelected}, registering 'mousedown' callback for BORDER_RECT_DRAW_LAYER_ID '${MAPBOX_DRAW_CLICKABLE_LAYER_IDS}'...`
            )
          MAPBOX_DRAW_CLICKABLE_LAYER_IDS.forEach(id => {
            mapinst.on('mousedown', id, onMouseDownWhenImageRectSelected)
          })
        } else {
          if (debug)
            console.debug(
              `EditableMapImage.onDrawSelectionChange(): borderRectSelected is ${borderRectSelected}, clearing 'mousedown' callback for BORDER_RECT_DRAW_LAYER_ID '${MAPBOX_DRAW_CLICKABLE_LAYER_IDS}', will not call onMouseDownWhenImageRectSelected()...`
            )

          MAPBOX_DRAW_CLICKABLE_LAYER_IDS.forEach(id => {
            mapinst.off('mousedown', id, onMouseDownWhenImageRectSelected)
          })
        }

        //onImageSelected is an parameter to addEditableImageToGlobe()
        if (onImageSelected) {
          onImageSelected(borderRectSelected, imageId)
        }
      }) // end draw.selectionchange

      // all Draw features get resizing and rotating support from TxRectMode
      // which is initialised in MapEdit.setupDraw()

      // on('mousedown') is fired when the user clicks on an image border icon - e.g. resize/rotate - whereas onMouseDownWhenImageRectSelected() is not.
      // Register a mouseDown event which just registers an onMouseup callback for when the user releases the mouse button
      // The onMouseup callback checks to see if isResizingRef is set - this will have been done by onData() below
      //    if so, call onImageBorderRectMoved() to
      mapinst.on('mousedown', e => {
        if (debug)
          console.debug(
            `addEditableImageToGlobe.onMouseDown(): ONMOUSEDOWN!! defaultPrevented: ${e.defaultPrevented}; e:`,
            e
          )
        if (e.defaultPrevented) {
          return
        }

        isMouseDownRef.current = true
        mapinst.once('mouseup', e => {
          if (debug)
            console.debug(
              `ONMOUSEUP!! isMovingRef.current: ${isMovingRef.current}, isResizingRef.current: ${isResizingRef.current}, isRotatingRef.current: ${isRotatingRef.current}`
            )

          isMouseDownRef.current = false
          if (isResizingRef.current) {
            // have to refresh feature from mapboxDraw else mapboxDraw.add() adds a duplicate
            const borderRectFeature = mapboxDraw.get(
              isResizingRef.current
            ) as Feature<Polygon>

            isResizingRef.current = undefined
            if (borderRectFeature) {
              if (debug)
                console.debug(
                  'ONMOUSEUP!!  calling onImageBorderRectMoved for borderRectFeature (taken from mapboxDraw.get()):',
                  borderRectFeature
                )

              onImageBorderRectMoved(
                mapinst,
                mapboxDraw,
                imageId,
                borderRectFeature,
                true, //enableAdjustForDegreeRatioAtLatitude,
                false, // applyWidthChangeDueToLatitudeToImage
                false, //isDragging
                true, //isResizing
                false, //isRotating
                true //applyAdjustmentToBorderRectFeature
              ) // uses APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_BOUNDARY_RECT_WHILST_MOVING
            }
          }
          if (isRotatingRef.current) {
            isRotatingRef.current = undefined
          }
        })
      })

      //TODO why does moving a random mapbox draw shape - nothing to do with image - pass all the IFs below?
      if (WATCH_FOR_IMAGE_RECT_MOVEMENT_USING_ON_DATA) {
        // Add event handler to the map to call onImageBorderRectMoved() as the image is resized or rotated.
        // onImageBorderRectMoved():
        //   - updates image property centerCoords based on dragged rect's new center
        //   - re-plots and lays out image based on new centerCoords due to potential latitude change
        //   - re-plots image reference point positions and moves the Markers
        //
        //
        // If currently dragging/moving/translating an image, do nothing as the border is moved by MapboxDraw and
        // onMouseDownWhenImageRectSelected() will have registered 'mousemove' listener onMouseMoveWhenDownOnSelectedImage
        // which calls onImageBorderRectMoved().
        //
        // The checks below were copied from TxRectModeDemo:
        //  - mouse button is down
        //  - the Mapbox event has a MapboxDraw Source
        //  - the source has data
        //  - the source data has at least 1 feature
        //  - the first feature is this image's border rect
        //  - TxRectModeDemo has state set, to 1 (scale) or 2 (rotate)
        // if all pass then onImageBorderRectMoved() is called with isResizing/isRotating set as appropriate.
        //
        mapinst.on('data', (e: any) => {
          //console.debug(`addImageToGlobe.onData(): called with event`, e)
          if (isMovingRef.current) {
            return
          }
          if (isMouseDownRef.current) {
            if (e.sourceId && e.sourceId.startsWith('mapbox-gl-draw-')) {
              // console.debug(
              //   `TxRectModeDemo._onData(): called with event with e.sourceId '${e.sourceId}'`,
              //   e
              // )
              if (
                e.type === 'data' &&
                e.source &&
                e.source.data &&
                // && e.sourceDataType && e.sourceDataType == 'content'
                e.sourceDataType === undefined
                // && e.isSourceLoaded
              ) {
                const geojson = e.source.data
                if (
                  geojson.features &&
                  geojson.features.length > 0 &&
                  geojson.features[0].properties &&
                  //geojson.features[0].properties.user_overlaySourceId &&
                  geojson.features[0].properties.id === imageBorderRectangleId
                ) {
                  if ('state' in TxRectModeDemo) {
                    const txStateMode = (TxRectModeDemo as any).state.txMode
                    if (txStateMode === 1 || txStateMode === 2) {
                      // 1: scale, 2: rotate
                      if (debug)
                        console.debug(
                          `addImageToGlobe.onData(): called with event with sourceId 'mapbox-gl-draw-*' where the first source feature is '${imageBorderRectangleId}' and .state.txMode is: ${txStateMode} ( 1: scale, 2: rotate), isDraggingRef.current: ${isMovingRef.current}`,
                          e
                        )

                      if (txStateMode === 1) {
                        isResizingRef.current = imageBorderRectangleId
                      } else {
                        isRotatingRef.current = imageBorderRectangleId
                      }

                      onImageBorderRectMoved(
                        mapinst,
                        mapboxDraw,
                        imageId,
                        geojson.features[0],
                        true, //enableAdjustForDegreeRatioAtLatitude,
                        true, // applyWidthChangeDueToLatitudeToImage
                        isMovingRef.current, //isDragging
                        txStateMode === 1, //isResizing
                        txStateMode === 2 //isRotating
                      ) // uses APPLY_ADJUSTED_WIDTH_FOR_LATITUDE_TO_BOUNDARY_RECT_WHILST_MOVING
                    }
                  }
                  // } else {
                  //   console.debug(
                  //     `addImageToGlobe.onData(): source's first feature is not imageBoundingRectangleId - geojson.features.length: ${geojson.features.length}`,
                  //     e
                  //   )
                }
                // } else {
                //   console.debug(
                //     `addImageToGlobe.onData(): event source starts with 'mapbox-gl-draw-' but not inspecting its data`,
                //     e
                //   )
              }
              // } else {
              //   console.debug(
              //     `addImageToGlobe.onData(): event source does not start with 'mapbox-gl-draw-'`,
              //     e
              //   )
            }
          }
          //}
        }) // end on data
      }
    } // end addBorderMapboxDrawRect and mapboxdraw

    const mapLayerImageInfo: MapLayerImageInfo = {
      id: imageId,
      width: imageWidthPx,
      height: imageHeightPx,
      fileName: url,
      coordinates: rotatedImageBorderCoords,
    }

    return mapLayerImageInfo
  } // end of addEditableImageToGlobe()

  const SnapButtonJsx = ({ mapinst, mapboxDraw }: SnapButtonProps) => {
    // console.debug(
    //   `EditableMapImage.snapButton: called with mapinst: ${mapinst}`
    // )
    // console.debug(
    //   `EditableMapImage.snapButton: called with mapboxDraw: ${mapboxDraw}`
    // )
    // console.debug(
    //   `EditableMapImage.snapButton: called with imageId: ${imageId}`
    // )

    const handleImageOpacityChange = (
      event: Event,
      newValue: number | number[]
    ) => {
      // console.debug(
      //   `handleImageOpacityChange(): called with newValue: ${newValue}`
      // )

      if (typeof newValue === 'number') {
        if (selectedImageIdRef.current) {
          const imageLayerId = getImageLayerId(selectedImageIdRef.current)

          if (imageLayerId) {
            mapinst.setPaintProperty(
              imageLayerId,
              'raster-opacity',
              newValue / 100
            )
          }
        }
      }
    }

    const getImageOpacity = () => {
      if (selectedImageIdRef.current) {
        const imageLayerId = getImageLayerId(selectedImageIdRef.current)

        if (imageLayerId) {
          const value = mapinst.getPaintProperty(imageLayerId, 'raster-opacity')
          if (typeof value === 'number') {
            return value * 100
          }
        }
      }
    }

    return (
      <>
        <Slide
          in={!!selectedImageIdRef.current}
          direction="down"
          unmountOnExit
          mountOnEnter
        >
          <Box>
            <Box>
              {/*  @ts-expect-error some props are not mandatory */}
              <Button
                onClick={async (): Promise<void> => {
                  if (selectedImageIdRef.current) {
                    const globeRefs =
                      globeReferenceMarkersForSelectedImageRef.current?.map(
                        marker => marker.getLngLat().toArray()
                      )
                    if (!globeRefs) {
                      return
                    }
                    await snapReferencePointsTogether(
                      mapinst,
                      mapboxDraw,
                      selectedImageIdRef.current,
                      globeRefs,
                      selectedImageReferenceMarkers
                    )
                  }
                }}
                permissionAction={ACTION_ALL_ACCESS}
              >
                Snap image to reference points
              </Button>
            </Box>
            <Box mt={1}>
              <Slider
                aria-label="Volume"
                //value={imageOpacity}
                onChange={handleImageOpacityChange}
                // @ts-expect-error can't find a way to add custom theme colours to MUI's types
                color="backgroundGrey"
                defaultValue={getImageOpacity()}
              />
            </Box>
          </Box>
          {/* )} */}
        </Slide>
      </>
    )
  }

  if (debug)
    console.debug(
      `EditableMapImage.useEditableMapImage: rendered, returning...`
    )

  // EditableMapImageHookResponse
  return {
    addEditableImageToGlobe: addEditableImageToGlobe,
    SnapButton: SnapButtonJsx,
    onShapeDeleted: onShapeDeleted,
    selectedImageReferenceMarkers: selectedImageReferenceMarkers,
    addImagesFromCurrentMap: addImagesFromCurrentMap,
  }
} // end of useEditableMapImage hook
