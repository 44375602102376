import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { usePublicTreeSettings } from '../../app'
import { generatePublicLinkForObject } from '../../app/links'
import { selectTree } from 'src/modules/public/tree/treeSlice'
import PublicHomePage from './PublicHomePage'

const PublicHomePageSwitch = () => {
  const history = useHistory()
  const tree = useSelector(selectTree)
  const { homeInstanceId, homeInstanceModel } = usePublicTreeSettings()

  useEffect(() => {
    if (homeInstanceId && homeInstanceModel) {
      const url = generatePublicLinkForObject(
        tree?.slug,
        homeInstanceModel,
        homeInstanceId
      )
      history.push(url)
    }
  }, [homeInstanceModel, homeInstanceId, history, tree?.slug])

  if (homeInstanceId && homeInstanceModel) {
    return null
  } else {
    return (
      <>
        <PublicHomePage />
      </>
    )
  }
}

export default PublicHomePageSwitch
