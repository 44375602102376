import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, styled } from '@mui/material'

import { LoadingIndicator, Link } from 'src/modules/ui'
import EmptyFeed from 'src/modules/ui/EmptyFeed'
import SummaryCard from 'src/modules/page/SummaryCard'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { generateLinkForObject } from 'src/modules/app/links'

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(-1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  color: 'inherit',
  textDecoration: 'inherit',
}))

// mounted in HomePageList, LinkedPageList, and PublicPageList
const BasePageList = ({
  actions,
  dispatchFetchPage,
  generateCardLink,
  next,
  handleFetchMore,
  pages,
  results,
  type,
  map,
  showMap,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  return (
    <Box>
      {
        // actions prop passed from HomePageList or LinkedPageList is an element not a function otherwise it keeps getting re-mounted
      }
      {actions ? actions : null}
      {showMap && map}
      {dispatchFetchPage.status === 'loading' &&
      pages.page === 0 &&
      results.length === 0 ? (
        <LoadingIndicator />
      ) : results.length === 0 && dispatchFetchPage.status !== 'loading' ? (
        <EmptyFeed type={type} />
      ) : (
        <StyledInfiniteScroll
          dataLength={results.length}
          next={handleFetchMore}
          hasMore={next}
        >
          {results.map(page => (
            <StyledLink
              to={
                generateCardLink
                  ? generateCardLink(type, page.id)
                  : generateLinkForObject(treeSlug, type, page.id)
              }
              key={page.id}
            >
              <SummaryCard type={type} {...page} />
            </StyledLink>
          ))}
        </StyledInfiniteScroll>
      )}
      {dispatchFetchPage.status === 'loading' && pages.page > 0 && (
        <LoadingIndicator />
      )}
    </Box>
  )
}

export default BasePageList
